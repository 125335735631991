import React, { useContext } from 'react';
import '../styles/LanguageToggle.css'; // Ensure you have proper styling
import LanguageContext from './LanguageContext'; // Import the LanguageContext
import { getCurrentColor } from '../utils/getCurrentColor';

const LanguageToggle = ({ currentIndex }) => {
  const { selectedLanguage, setSelectedLanguage } = useContext(LanguageContext); // Use useContext to access global state

  const handleLanguageChange = () => {
    const newLanguage = selectedLanguage === 'en' ? 'de' : 'en';
    setSelectedLanguage(newLanguage); // Directly update the language in context
  };


  return (
    <div className="language-toggle-container">
      <div
        className={`language-toggle-wrapper ${selectedLanguage}`}
        style={{
          '--gradient': getCurrentColor(currentIndex).gradient, // Add this line
          backgroundColor: getCurrentColor(currentIndex).lightBackground, // Use currentIndex to fetch the correct background color
        }}
      >
        <button
          className={`language-toggle-card ${selectedLanguage === 'en' ? 'active' : ''}`}
          onClick={handleLanguageChange}
          aria-label="Switch to English"
          disabled={selectedLanguage === 'en'}  // Disable if already selected
          style={{
              background: selectedLanguage === 'en' ? getCurrentColor(currentIndex).gradient : '', // Dynamic gradient background for active card
          }}
        >
          🇬🇧
        </button>
        <button
          className={`language-toggle-card ${selectedLanguage === 'de' ? 'active' : ''}`}
          onClick={handleLanguageChange}
          aria-label="Switch to German"
          disabled={selectedLanguage === 'de'}  // Disable if already selected
          style={{
              background: selectedLanguage === 'de' ? getCurrentColor(currentIndex).gradient : '', // Dynamic gradient background for active card
          }}
        >
          🇩🇪
        </button>
      </div>
    </div>
  );
};


export default LanguageToggle;
