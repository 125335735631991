import React from 'react';
import '../styles/RangeSlider.css';
import { getCurrentColor } from '../utils/getCurrentColor';

function RangeSlider({ label, value = 0, onChange, getMessage, currentIndex }) {
  const currentColor = getCurrentColor(currentIndex); // Get the current colors dynamically

  return (
    <div className="range-slider">
      <label
        style={{
          background: currentColor.gradient,  // Apply the dynamic gradient as background
          WebkitBackgroundClip: 'text',       // Ensure the background clips to the text
          WebkitTextFillColor: 'transparent', // Make the text transparent so the gradient shows
        }}
      >
        {label}: <span className="slider-value">{value}%</span>
      </label>
      <input
        type="range"
        min="0"
        max="100"
        value={value}  // Always controlled, fallback to 0 if undefined
        onChange={onChange}
        style={{
          '--track-color': currentColor.gradient,
          '--glow-color': currentColor.glowColor,
          '--thumb-border-color': currentColor.glowColor,
        }}  // Set dynamic colors using CSS variables
      />
      {getMessage && <div className="slider-message">{getMessage(value)}</div>}
    </div>
  );
}

export default RangeSlider;
