import React, { useState, useRef, useContext, useEffect } from 'react';
import { ReactComponent as Face1 } from '../img/faces/face1.svg';
import { ReactComponent as Face2 } from '../img/faces/face2.svg';
import { ReactComponent as Face3 } from '../img/faces/face3.svg';
import { ReactComponent as Face4 } from '../img/faces/face4.svg';
import { ReactComponent as Face5 } from '../img/faces/face5.svg';
import '../styles/CardCarousel.css';
import '../styles/LanguageToggle.css';
import cardsData from '../data/cardsData'; // Updated import to cardsData
import GradingTool from './GradingTool';
import LanguageToggle from './LanguageToggle';
import LanguageContext from './LanguageContext'; // Import Language Context
import { goToPrevious, goToNext } from '../utils/navigationHandlers'; // Correct import of navigationHandlers
import { getCurrentColor } from '../utils/getCurrentColor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faChevronDown, faInfoCircle, faPenToSquare, faListCheck, faScaleBalanced } from '@fortawesome/free-solid-svg-icons';
import { handleDownloadPdfUtil } from '../utils/handleDownloadPdfUtil';
import translations from '../data/translations'; // Import translations

function CardCarousel() {
  const [isSwiping, setIsSwiping] = useState(false);
  const [swipeDistance, setSwipeDistance] = useState(0);
  // Initialize the state for explanation visibility
const [explanationVisibility, setExplanationVisibility] = useState({});
const [checklistSelections, setChecklistSelections] = useState({});
const [selectedMessage, setSelectedMessage] = useState({});
  const { selectedLanguage } = useContext(LanguageContext); // Use LanguageContext for global state
  const t = translations[selectedLanguage] || translations['en']; // Access fixed translations
  const [currentIndex, setCurrentIndex] = useState(0);
  const printRef = useRef(); // Define the printRef here
  const [displayContent, setDisplayContent] = useState('information');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [gradeDisplayType, setGradeDisplayType] = useState('810'); // Default grading system
  const [authorName, setAuthorName] = useState('');
  const [reviewerName, setReviewerName] = useState('');

  
const toggleExplanationVisibility = (checklistIndex) => {
  setExplanationVisibility((prevVisibility) => ({
    ...prevVisibility,
    [currentIndex]: {
      ...prevVisibility[currentIndex],
      [checklistIndex]: !prevVisibility[currentIndex]?.[checklistIndex],
    },
  }));
};
  



// Initialization function
const initializeDefaultSliderValues = (cards) => {
  return cards.map((card) => {
    const sliderValuesForCard = {};
    card.sliderCategories.forEach((category) => {
      category.sliders.forEach((slider) => {
        const compositeId = slider.id; // Now using the composite ID
        sliderValuesForCard[compositeId] = slider.defaultValue || 60;
      });
    });
    return sliderValuesForCard;
  });
};

const [sliderValues, setSliderValues] = useState(initializeDefaultSliderValues(cardsData));


// Handle slider change
const handleSliderChange = (id) => (e) => {
  const value = parseInt(e.target.value, 10);
  setSliderValues((prevValues) => {
    const updatedValues = [...prevValues];
    updatedValues[currentIndex] = {
      ...updatedValues[currentIndex],
      [id]: value,
    };
    return updatedValues;
  });
};

  // Use the correct imported handlers
const handlePrevious = () => {
  setSwipeDistance(0);
  goToPrevious(currentIndex, setCurrentIndex, cardsData.length);
};
const handleNext = () => {
  setSwipeDistance(0);
  goToNext(currentIndex, setCurrentIndex, cardsData.length);
};

  // Get content for the current frontmost card
  const currentCardContent = cardsData[currentIndex];

  // Handle displaying information, grading, or checklist tool
  const handleToggleClick = (content) => {
    setDisplayContent(content);
  };

  // Open Modal for a specific box detail
  const openCategoryModal = (category) => {
    setSelectedCategory(category);
    setIsModalOpen(true);
  };

  // Close Modal
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedCategory(null);
  };

  



  
  useEffect(() => {
  // Function to update messages when language or selections change
  const updateMessages = () => {
    const currentSelections = checklistSelections[currentIndex] || {};
    const updatedMessagesForCurrentCard = {};

    Object.keys(currentSelections).forEach((checklistIndex) => {
      const emojiIndex = currentSelections[checklistIndex];
      const item = currentCardContent.checklist[selectedLanguage][checklistIndex];
      const message = item.messages[emojiIndex];

      updatedMessagesForCurrentCard[checklistIndex] = message;
    });

    setSelectedMessage((prevMessages) => ({
      ...prevMessages,
      [currentIndex]: updatedMessagesForCurrentCard,
    }));
  };

  updateMessages();
  }, [selectedLanguage, checklistSelections, currentIndex]);
  

const handleEmojiClick = (checklistIndex, emojiIndex) => {
  setChecklistSelections((prevSelections) => ({
    ...prevSelections,
    [currentIndex]: {
      ...prevSelections[currentIndex],
      [checklistIndex]: emojiIndex,
    },
  }));
};

  const calculateAverage = () => {
    const values = Object.values(sliderValues[currentIndex]); // Get the current card's slider values
    const total = values.reduce((sum, value) => sum + value, 0); // Sum all values
    return total / values.length; // Divide by the number of values to get the average
  };

  const handleGradingValueChange = (value, type) => {
    if (type === 'author') {
      setAuthorName(value);
    } else if (type === 'reviewer') {
      setReviewerName(value);
    } else {
      setGradeDisplayType(value);
    }
  };



  const handleDownloadPdf = () => handleDownloadPdfUtil(printRef.current);

  // Use useRef to store touch positions
  const touchStartXRef = useRef(null);
  const touchEndXRef = useRef(null);

const handleTouchStart = (e) => {
  touchStartXRef.current = e.targetTouches[0].clientX;
  setIsSwiping(true);
};

const handleTouchMove = (e) => {
  if (!touchStartXRef.current) {
    return;
  }
  touchEndXRef.current = e.targetTouches[0].clientX;
  const distance = touchEndXRef.current - touchStartXRef.current;
  setSwipeDistance(distance);

  e.preventDefault(); // Prevent default to avoid page scrolling
};

const handleTouchEnd = () => {
  setIsSwiping(false);

  const touchStartX = touchStartXRef.current;
  const touchEndX = touchEndXRef.current;

  if (!touchStartX || !touchEndX) {
    setSwipeDistance(0);
    return;
  }
  const distance = touchEndX - touchStartX;
  const SWIPE_THRESHOLD = 50; // Adjust as needed
  const isLeftSwipe = distance < -SWIPE_THRESHOLD;
  const isRightSwipe = distance > SWIPE_THRESHOLD;

  if (isLeftSwipe) {
    handleNext();
  } else if (isRightSwipe) {
    handlePrevious();
  } else {
    // If swipe is less than threshold, reset position
    setSwipeDistance(0);
  }

  // Reset touch positions
  touchStartXRef.current = null;
  touchEndXRef.current = null;
};

  return (
    <div
      className="dynamic-background"  
  style={{
    background: `${getCurrentColor(currentIndex).subComplementaryGradient}, white`,  // Set dynamic gradient with a fallback color
    backgroundSize: "180% 180%",
    animation: "gradient-animation 6s ease-in-out infinite",
      }}>
      <div className="carousel-container">
      <LanguageToggle currentIndex={currentIndex} selectedLanguage={selectedLanguage} /> {/* Removed setSelectedLanguage */}
        <div
          className="carousel"
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          <button onClick={handlePrevious} className="nav-button">
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
          <div
  className="cards-wrapper"
  style={{
    transform: `translateX(${isSwiping ? swipeDistance : 0}px)`,
    transition: isSwiping ? 'none' : 'transform 0.3s ease-in-out',
  }}
>
            {/* Cards at the top */}
            <div
              className={`card card-previous-far color-${(currentIndex - 2 + cardsData.length) % cardsData.length}`}
              onClick={handlePrevious}
            >
              <h3>{cardsData[(currentIndex - 2 + cardsData.length) % cardsData.length].title[selectedLanguage]}</h3>
              <p>{cardsData[(currentIndex - 2 + cardsData.length) % cardsData.length].content[selectedLanguage]}</p>
            </div>
            <div
              className={`card card-previous color-${(currentIndex - 1 + cardsData.length) % cardsData.length}`}
              onClick={handlePrevious}
            >
              <h3>{cardsData[(currentIndex - 1 + cardsData.length) % cardsData.length].title[selectedLanguage]}</h3>
              <p>{cardsData[(currentIndex - 1 + cardsData.length) % cardsData.length].content[selectedLanguage]}</p>
            </div>
<div className={`card card-current color-${currentIndex}`}
  style={{
    background: getCurrentColor(currentIndex).gradient,  // Set dynamic gradient
    boxShadow: `0 0 20px ${getCurrentColor(currentIndex).glowColor}`,  // Set dynamic glow color
    borderColor: getCurrentColor(currentIndex).glowColor,  // Set dynamic border color
  }}
>
  <h3>{cardsData[currentIndex].title[selectedLanguage]}</h3>
  <p>{cardsData[currentIndex].content[selectedLanguage]}</p>
</div>
            <div
              className={`card card-next color-${(currentIndex + 1) % cardsData.length}`}
              onClick={handleNext}
            >
              <h3>{cardsData[(currentIndex + 1) % cardsData.length].title[selectedLanguage]}</h3>
              <p>{cardsData[(currentIndex + 1) % cardsData.length].content[selectedLanguage]}</p>
            </div>
            <div
              className={`card card-next-far color-${(currentIndex + 2 + cardsData.length) % cardsData.length}`}
              onClick={handleNext}
            >
              <h3>{cardsData[(currentIndex + 2 + cardsData.length) % cardsData.length].title[selectedLanguage]}</h3>
              <p>{cardsData[(currentIndex + 2 + cardsData.length) % cardsData.length].content[selectedLanguage]}</p>
            </div>
          </div>
          <button onClick={handleNext} className="nav-button">
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </div>



        <div className="toggle-card-container">
<div
  className={`toggle-card-wrapper ${displayContent} dynamic-background`}
  style={{
    '--glow-color': getCurrentColor(currentIndex).glowColor,
    '--gradient': getCurrentColor(currentIndex).gradient, // Add this line
    background: getCurrentColor(currentIndex).lightBackground,
    transition: 'background 4s ease-in-out', // Smooth background transition
  }}
>
    <div
      className={`toggle-card ${displayContent === 'information' ? 'active' : ''}`}
      onClick={() => handleToggleClick('information')}
      style={{
        background: displayContent === 'information' ? getCurrentColor(currentIndex).gradient : '', // Dynamic gradient background for active card
        boxShadow: displayContent === 'information' ? `0 0 15px 3px ${getCurrentColor(currentIndex).glowColor}` : '', // Dynamic glow for active card
      }}
    >
      <FontAwesomeIcon icon={faPenToSquare} /> <span className="toggle-text">{t.information}</span>
    </div>
    <div
      className={`toggle-card ${displayContent === 'checklist' ? 'active' : ''}`}
      onClick={() => handleToggleClick('checklist')}
      style={{
        background: displayContent === 'checklist' ? getCurrentColor(currentIndex).gradient : '', // Dynamic gradient background for active card
      }}
    >
      <FontAwesomeIcon icon={faListCheck} /> <span className="toggle-text">{t.checklist}</span>
    </div>
    <div
      className={`toggle-card ${displayContent === 'grading' ? 'active' : ''}`}
      onClick={() => handleToggleClick('grading')}
      style={{
        background: displayContent === 'grading' ? getCurrentColor(currentIndex).gradient : '', // Dynamic gradient background for active card
      }}
    >
      <FontAwesomeIcon icon={faScaleBalanced} /> <span className="toggle-text">{t.gradingFeedback}</span>
      </div>
    </div>
  </div>

      </div>
      
      <div className="maincontentbackground dynamic-background"
        style={{
          background: getCurrentColor(currentIndex).lightBackground,
              transition: 'background 0.3s ease-in-out', // Smooth background transition
      }}>


<div className="dynamic-content">
{displayContent === 'information' && (
  <>
              <div className="large-icon-container"
              style={{
    background: getCurrentColor(currentIndex).gradient,  // Set dynamic gradient
    boxShadow: `0 0 20px ${getCurrentColor(currentIndex).glowColor}`,  // Set dynamic glow color
                borderColor: getCurrentColor(currentIndex).glowColor,  // Set dynamic border color
                  transition: 'background 1s ease-in-out', // Smooth background transition
  }}>
      <FontAwesomeIcon icon={faPenToSquare} className="large-icon" />
    </div>
    <div className="categories-container">
      {currentCardContent.categories && currentCardContent.categories.map((category, index) => (
        <div key={index} className="category-card" onClick={() => openCategoryModal(category)}>
          <div className="category-title">{category.title[selectedLanguage]}</div>
          <div className="category-description">{category.description[selectedLanguage]}</div>
        </div>
      ))}
    </div>
  </>
)}


{displayContent === 'checklist' && (
  <>
               <div className="large-icon-container"
              style={{
    background: getCurrentColor(currentIndex).gradient,  // Set dynamic gradient
    boxShadow: `0 0 20px ${getCurrentColor(currentIndex).glowColor}`,  // Set dynamic glow color
    borderColor: getCurrentColor(currentIndex).glowColor,  // Set dynamic border color
  }}>
    <FontAwesomeIcon icon={faListCheck} className="large-icon" />
  </div>
<div className="checklist-tool">
  <h2>{t.checklist}</h2>
  {currentCardContent.checklist[selectedLanguage].map((item, checklistIndex) => {
    const isExplanationVisible = explanationVisibility[currentIndex]?.[checklistIndex];
    const selectedEmojiIndex = checklistSelections[currentIndex]?.[checklistIndex];
    const message = selectedMessage[currentIndex]?.[checklistIndex];

    return (
      <li key={`${currentIndex}-${checklistIndex}`}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div>{item.question}</div>
          <FontAwesomeIcon
            icon={faChevronRight} // Default arrow icon
            className={`info-icon ${isExplanationVisible ? 'open' : ''}`} // Conditionally apply the open class
            onClick={() => toggleExplanationVisibility(checklistIndex)}
            style={{
              marginLeft: '8px',
              cursor: 'pointer',
              color: getCurrentColor(currentIndex).glowColor, // Dynamic color
            }}
          />
        </div>
        {/* Explanation is visible based on state */}
        {isExplanationVisible && <div className="explanation" style={{
          background: getCurrentColor(currentIndex).lightBackground,
        }}>{item.explanation}</div>}

        {/* Emoji rating always visible */}
        <div className="emoji-column" style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
          <div className="emoji-rating" style={{ whiteSpace: 'nowrap' }}>
            {[<Face1 className="icon" style={{ width: '25px', height: '25px' }} />, <Face2 className="icon" style={{ width: '25px', height: '25px' }} />, <Face3 className="icon" style={{ width: '25px', height: '25px' }} />, <Face4 className="icon" style={{ width: '25px', height: '25px' }} />, <Face5 className="icon" style={{ width: '25px', height: '25px' }} />].map((emoji, emojiIndex) => (
              <span
                key={emojiIndex}
                role="img"
                aria-label={`rating-${emojiIndex}`}
                className={`emoji ${selectedEmojiIndex === emojiIndex ? 'selected' : ''}`}
                onClick={() => handleEmojiClick(checklistIndex, emojiIndex)}
              >
                {emoji}
              </span>
            ))}
          </div>
          {message && <div style={{ marginLeft: '10px', fontStyle: 'italic', color: '#666' }}>{message}</div>}
        </div>
      </li>
    );
  })}
</div>


  </>
)}
          
         


{displayContent === 'grading' && (
<GradingTool
  gradingValue={gradeDisplayType}
  onGradingValueChange={handleGradingValueChange}
  gradeDisplayType={gradeDisplayType}
  authorName={authorName}
  setAuthorName={setAuthorName}   // Pass down setAuthorName
  reviewerName={reviewerName}
  setReviewerName={setReviewerName}  // Pass down setReviewerName
  sliderValues={sliderValues[currentIndex]} // Pass the current card's slider values
  handleSliderChange={handleSliderChange}   // Function to handle slider changes
  currentCardContent={currentCardContent}   // Current card content
  calculateAverage={calculateAverage}       // Function to calculate the average grade
  handleDownloadPdf={handleDownloadPdf}     // PDF download handler
  printRef={printRef}                       // Reference to the print element
  currentIndex={currentIndex}               // Pass currentIndex as a prop
/>
)}
        </div>


{isModalOpen && selectedCategory && (
  <div className="modal-overlay" onClick={closeModal}>
    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
      {/* Close Button */}
      <button className="close-icon" onClick={closeModal}>
        &times;
      </button>

              <div>
                <div className="modal-header">{cardsData[currentIndex].title[selectedLanguage]}</div>
        <h3>{selectedCategory.title[selectedLanguage]}</h3>
        <div>
          {selectedCategory.details[selectedLanguage].map((detail, index) => (
            <div key={index}>{detail}</div>
          ))}
        </div>
      </div>
    </div>
  </div>
)}
      </div>
    </div>
  );
}

export default CardCarousel;