export const calculateGermanGrade810 = (average) => {
    const avg = parseFloat(average);

    if (avg >= 95) return '1';
    if (avg >= 90) return '1-';
    if (avg >= 85) return '1-2';
    if (avg >= 80) return '2+';
    if (avg >= 75) return '2';
    if (avg >= 70) return '2-';
    if (avg >= 65) return '2-3';
    if (avg >= 60) return '3+';
    if (avg >= 55) return '3';
    if (avg >= 50) return '3-';
    if (avg >= 45) return '3-4';
    if (avg >= 40) return '4+';
    if (avg >= 35) return '4';
    if (avg >= 30) return '4-';
    if (avg >= 25) return '4-5';
    if (avg >= 20) return '5+';
    if (avg >= 15) return '5';
    if (avg >= 10) return '5-';
    if (avg >= 5) return '5-6';
    return '6';
  };

export const calculateGermanKursstufe = (average) => {
    const avg = parseFloat(average);

    if (avg >= 95) return 15;
    if (avg >= 90) return 14;
    if (avg >= 85) return 13;
    if (avg >= 80) return 12;
    if (avg >= 75) return 11;
    if (avg >= 70) return 10;
    if (avg >= 65) return 9;
    if (avg >= 60) return 8;
    if (avg >= 55) return 7;
    if (avg >= 50) return 6;
    if (avg >= 45) return 5;
    if (avg >= 40) return 4;
    if (avg >= 33) return 3;
    if (avg >= 27) return 2;
    if (avg >= 20) return 1;
    return 0;
  };