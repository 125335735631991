import React from "react";
import { LanguageProvider } from './components/LanguageContext';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './styles/App.css';
import CardCarousel from "./components/CardCarousel";
import GradingTool from "./components/GradingTool"; // Import the grading tool



function App() {

  return (
    <LanguageProvider>
    <Router>
      <Routes>
        {/* Route for the main page with the card carousel */}
        <Route path="/" element={<CardCarousel />} />

        {/* Route for the grading tool page */}
        <Route path="/grading" element={<GradingTool />} />
      </Routes>
      </Router>
      </LanguageProvider>
  );
}

export default App;
