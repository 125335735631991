export const getCurrentColor = (currentIndex) => {
  // Get the root element (which holds the CSS variables)
  const root = document.documentElement;

  // Get the computed styles for the root element
  const computedStyles = getComputedStyle(root);

  // Map the current index to the corresponding CSS variable for gradients and glow colors
  const gradient = (() => {
    switch (currentIndex % 5) {
      case 0:
        return computedStyles.getPropertyValue('--gradient0').trim();
      case 1:
        return computedStyles.getPropertyValue('--gradient1').trim();
      case 2:
        return computedStyles.getPropertyValue('--gradient2').trim();
      case 3:
        return computedStyles.getPropertyValue('--gradient3').trim();
      case 4:
        return computedStyles.getPropertyValue('--gradient4').trim();
      default:
        return computedStyles.getPropertyValue('--gradient0').trim(); // fallback to the first gradient
    }
  })();

  const complementaryGradient = (() => {
    switch (currentIndex % 5) {
      case 0:
        return computedStyles.getPropertyValue('--complement-gradient0').trim();
      case 1:
        return computedStyles.getPropertyValue('--complement-gradient1').trim();
      case 2:
        return computedStyles.getPropertyValue('--complement-gradient2').trim();
      case 3:
        return computedStyles.getPropertyValue('--complement-gradient3').trim();
      case 4:
        return computedStyles.getPropertyValue('--complement-gradient4').trim();
      default:
        return computedStyles.getPropertyValue('--complement-gradient0').trim();
    }
  }
  )();

    const subComplementaryGradient = (() => {
    switch (currentIndex % 5) {
      case 0:
        return computedStyles.getPropertyValue('--contrast-complement-subgradient0').trim();
      case 1:
        return computedStyles.getPropertyValue('--contrast-complement-subgradient1').trim();
      case 2:
        return computedStyles.getPropertyValue('--contrast-complement-subgradient2').trim();
      case 3:
        return computedStyles.getPropertyValue('--contrast-complement-subgradient3').trim();
      case 4:
        return computedStyles.getPropertyValue('--contrast-complement-subgradient4').trim();
      default:
        return computedStyles.getPropertyValue('--contrast-complement-subgradient5').trim();
    }
  }
  )();

  const glowColor = (() => {
    switch (currentIndex % 5) {
      case 0:
        return computedStyles.getPropertyValue('--glow-color0').trim();
      case 1:
        return computedStyles.getPropertyValue('--glow-color1').trim();
      case 2:
        return computedStyles.getPropertyValue('--glow-color2').trim();
      case 3:
        return computedStyles.getPropertyValue('--glow-color3').trim();
      case 4:
        return computedStyles.getPropertyValue('--glow-color4').trim();
      default:
        return computedStyles.getPropertyValue('--glow-color0').trim(); // fallback to the first glow color
    }
  })();

  const lightBackground = (() => {
    switch (currentIndex % 5) {
      case 0:
        return computedStyles.getPropertyValue('--light-background0').trim();
      case 1:
        return computedStyles.getPropertyValue('--light-background1').trim();
      case 2:
        return computedStyles.getPropertyValue('--light-background2').trim();
      case 3:
        return computedStyles.getPropertyValue('--light-background3').trim();
      case 4:
        return computedStyles.getPropertyValue('--light-background4').trim();
      default:
        return computedStyles.getPropertyValue('--light-background0').trim(); // fallback to the first glow color
    }
  })();

  // Return both the gradient and the corresponding glow color
  return {
    gradient,
    complementaryGradient,
    subComplementaryGradient,
    glowColor,
    lightBackground
  };
};
