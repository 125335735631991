const translations = {
  en: {
    gradingToolTitle: "Grading Tool",
    feedbackSheet: "Feedback Sheet",
    chooseGradingSystem: "Choose Your Grading System:",
    germanUnderMidLevel: "German Under- and Midlevel",
    germanKursstufe: "German Course Level",
    showOnlyPercentages: "Show only Percentages",
    feedbackTitle: "Feedback",
    averageGrade: "Average Grade",
    averageWord: "Average Percentage",
    criteria: "Criteria",
    score: "Score",
    details: "Details",
    author: "Author",
    reviewer: "Reviewer",
    texttype: "Text type",
    grade: "Grade",
    of: "of",
    signature: "Signature of your parents",
    required: "(if required)",
    date: "Date",
    downloadPdf: "Download",
    openPdf: "Open",
    feedbackSharing: "Share your feedback!",
        printPdf: "Print",
        information: "Write",
        checklist: "Review",
        gradingFeedback: "Grade",
    close: "Close",
  },
  de: {
    gradingToolTitle: "Bewertungstool",
    feedbackSheet: "Feedbackbogen",
    chooseGradingSystem: "Wähle Dein Bewertungssystem:",
    germanUnderMidLevel: "Deutsche Unter- und Mittelstufe",
    germanKursstufe: "Deutsche Kursstufe",
    showOnlyPercentages: "Nur Prozentsätze anzeigen",
    feedbackTitle: "Feedback",
    averageGrade: "Durchschnittliche Note",
    averageWord: "Durchschnittlicher Prozentwert",
    criteria: "Kriterien",
    score: "Ergebnis",
    details: "Details",
    author: "Autor/in",
    reviewer: "Prüfer/in",
    texttype: "Textart",
    grade: "Note",
    of: "von",
    signature: "Unterschrift Deiner Eltern",
    required: "(falls verlangt)",
    date: "Datum",
    downloadPdf: "Herunterladen",
    openPdf: "Öffnen",
    feedbackSharing: "Teile Dein Feedback!",
      printPdf: "Drucken",
      information: "Schreiben",
        checklist: "Prüfen",
      gradingFeedback: "Bewerten",
    close: "Schließen"
  },
};

export default translations;
