// ../utils/handleDownloadPdfUtil.js
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

export const handleDownloadPdfUtil = async (element) => {
  if (!element) {
    console.error("Element not found or undefined");
    return;
  }

  try {
    // Capture the element as a canvas
    const canvas = await html2canvas(element, { useCORS: true });
    console.log("Canvas created successfully");

    // Convert the canvas to a data URL in PNG format
    const data = canvas.toDataURL('image/png');
    console.log("Data URL generated:", data);

    // Check if the data URL is valid
    if (!data.startsWith("data:image/")) {
      console.error("Invalid Data URL");
      return;
    }

    // Create a new instance of jsPDF
    const pdf = new jsPDF();

    // Set dimensions for the PDF based on the canvas image properties
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

    // Add the image to the PDF, explicitly specifying the type as 'PNG'
    pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);

    // Save the PDF
    pdf.save('feedback.pdf');
  } catch (error) {
    console.error("Error generating PDF:", error);
  }
};
