import React, { useContext } from "react";
import { ReactComponent as PrintSVG } from '../img/printer.svg';
import { ReactComponent as OpenSVG } from '../img/pdf.svg';
import { ReactComponent as DownloadSVG } from '../img/download-pdf.svg';
import { ReactComponent as ShareSVG } from '../img/share-2.svg';
import RangeSlider from './RangeSlider'; // Ensure path is correct
import LanguageContext from './LanguageContext'; // Import the Language context
import { calculateGermanGrade810, calculateGermanKursstufe } from '../utils/gradingScales';
import translations from '../data/translations'; // Import translations
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faScaleBalanced } from '@fortawesome/free-solid-svg-icons';
import { getCurrentColor } from '../utils/getCurrentColor';
import '../styles/GradingTool.css';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs; // Set up the virtual file system for fonts

function GradingTool({
  onGradingValueChange,
  gradeDisplayType,
  authorName,
  setAuthorName,   // Accept setAuthorName as a prop
  reviewerName,
  setReviewerName,  // Accept setReviewerName as a prop
  sliderValues,
  handleSliderChange,
  currentCardContent,
  calculateAverage,
  printRef,
  style,
  currentIndex
}) {
  const { selectedLanguage } = useContext(LanguageContext);
  const t = translations[selectedLanguage] || translations['en'];

  const average = calculateAverage().toFixed(2);
  const grade810 = calculateGermanGrade810(average);
  const kursstufeGrade = calculateGermanKursstufe(average);

  const displayGrade = () => {
    if (gradeDisplayType === 'percentages') {
      return `${average}%`;
    } else if (gradeDisplayType === '810') {
      return `${t.grade}: ${grade810}`;
    } else if (gradeDisplayType === 'kursstufe') {
      return `${kursstufeGrade} NP`;
    }
    return '';
  };

  const getSliderMessage = (slider, value) => {
    if (slider.messages && slider.messages[selectedLanguage]) {
      return slider.messages[selectedLanguage](value);
    }
    if (slider.messages && slider.messages['en']) {
      return slider.messages['en'](value);
    }
    return "No message available.";
  };

const generatePdf = (action) => {
    const message = 'No comments available.';
    const currentDate = new Date().toLocaleDateString();
    const currentYear = new Date().getFullYear();

    const docDefinition = {
        content: [
            {
                columns: [
                    { 
                        text: t.feedbackSheet, 
                        style: 'header', 
                        alignment: 'left'
                    },
                    {
                        stack: [
                            {
                                columns: [
                                    {
                                                    table: {
                widths: ['auto', 'auto'],
                body: [
                    [
                        { text: t.texttype, style: 'tableLabel', fillColor: '#F0F0F0' },
                        { text: currentCardContent.shorttitle[selectedLanguage] || 'N/A', style: 'tableContent', fillColor: '#F0F0F0' }
                    ],
                    ...(authorName 
                        ? [
                            [
                                { text: t.author, style: 'tableLabel', fillColor: '#F0F0F0' },
                                { text: authorName, style: 'tableContent', fillColor: '#F0F0F0' }
                            ]
                          ]
                        : []
                    )
                ]
            
                                        },
                                        layout: {
                                            paddingLeft: () => 6,
                                            paddingRight: () => 6,
                                            paddingTop: () => 4,
                                            paddingBottom: () => 4,
                                            hLineWidth: (i, node) => (i === 0 || i === node.table.body.length ? 1 : 0),  // Only top and bottom
                                            vLineWidth: (i, node) => (i === 0 || i === node.table.widths.length ? 1 : 0),  // Only left and right
                                            hLineColor: () => 'black',  // Outer border color
                                            vLineColor: () => 'black'   // Outer border color
                                        },
                                        alignment: 'right'
                                    }
                                ],
                                alignment: 'right',
                                margin: [0, 0, 0, 0]
                            }
                        ],
                        width: 'auto',
                        alignment: 'right'
                    }
                ]
            },
        
   
            // Display table by categories
            ...currentCardContent.sliderCategories.flatMap(category => [
                { text: category.categoryTitle[selectedLanguage], style: 'categoryTitle', margin: [0, 10, 0, 5] },
                {
                    table: {
                        widths: ['40%', '20%', '40%'],
                        body: [
                            [{ text: t.criteria, style: 'tableHeader' }, { text: t.score, style: 'tableHeader' }, { text: t.details, style: 'tableHeader' }],
                            ...category.sliders.map(slider => [
                                { text: slider.label[selectedLanguage], style: 'sliderLabel' },
                                { text: `${sliderValues[slider.id]}%`, style: 'sliderValue' },
                                { text: getSliderMessage(slider, sliderValues[slider.id]), style: 'sliderMessage', alignment: 'right' }
                            ])
                        ]
                    },
                    layout: 'lightHorizontalLines'
                }
            ]),
            { text: `${t.averageWord}: ${calculateAverage().toFixed(2)}%`, style: 'averageGrade', margin: [0, 20, 0, 10] },

            // Conditionally render grade display based on selected gradeDisplayType
            ...(gradeDisplayType === '810' || gradeDisplayType === 'kursstufe'
                ? [{ 
                    text: displayGrade(), 
                    style: 'footerGrade', 
                    alignment: 'left',
                    margin: [0, 40, 0, 0]
                }]
                : []),

            {
              columns: [
                {
                    stack: [
                        
{
    ...(reviewerName
        ? { 
            stack: [
                { 
                    canvas: [{ 
                        type: 'rect', 
                        x: 0, 
                        y: 0, 
                        w: 240, 
                        h: 80, 
                        lineColor: '#f0f0f0', 
                        margin: [0, 0, 20, 0] 
                    }] 
                },
                { 
                    text: `${t.reviewer}: ${reviewerName}`, 
                    style: 'footerReviewer', 
                    margin: [0, 10, 0, 0] 
                }
            ]
        }
        : null  // Use null instead of an empty array or object
    )
},

                        { text: `${t.date}: ${currentDate}`, style: 'footerDate' }
                    ]
                },
{
    ...(reviewerName 
        ? { 
            stack: [
                { 
                    canvas: [{ 
                        type: 'rect', 
                        x: 0, 
                        y: 0, 
                        w: 240, 
                        h: 80, 
                        lineColor: '#f0f0f0', 
                        margin: [0, 0, 20, 0] 
                    }] 
                },
                { 
                    text: `${t.signature}`, 
                    style: 'footerReviewer', 
                    margin: [0, 10, 0, 0] 
                },
                { 
                    text: t.required, 
                    style: 'footerDate' 
                }
            ],
            alignment: 'right'
        }
        : null // Use null instead of an empty object
    )
}


              ],
              margin: [0, 20, 0, 0]
            }
        ],
        footer: {
            columns: [
                {
                    text: `© Alexander Stritt | herrstritt.guru | ${currentYear}`,
                    alignment: 'center',
                    style: 'footerText'
                }
            ],
            margin: [0, 10, 0, 0]
        },
        styles: {
            header: { fontSize: 20, bold: true, margin: [0, 0, 0, 20] },
            tableLabel: { fontSize: 11, bold: true, color: '#333' },
            tableContent: { fontSize: 11 },
            sectionHeader: { fontSize: 16, bold: true, decoration: 'underline' },
            categoryTitle: { fontSize: 14, bold: true, color: '#333', margin: [0, 10, 0, 0] },
            tableHeader: { fontSize: 11, bold: true, fillColor: '#F0F0F0' },
            sliderLabel: { fontSize: 11 },
            sliderMessage: { fontSize: 11, italics: true, color: '#666' },
            sliderValue: { fontSize: 11 },
            averageGrade: { fontSize: 14, bold: true },
            comments: { fontSize: 11, italics: true, color: '#666' },
            footerGrade: { fontSize: 18, bold: true },
            footerReviewer: { fontSize: 11, italics: true },
            footerDate: { fontSize: 10, color: '#666' },
            footerText: { fontSize: 9, italics: true, color: '#555' }
        }
    };

       // Perform the action based on the parameter
    if (action === 'open') {
        pdfMake.createPdf(docDefinition).open();
    } else if (action === 'download') {
        pdfMake.createPdf(docDefinition).download('FeedbackSheet.pdf'); // You can specify the filename here
    } else if (action === 'print') {
        pdfMake.createPdf(docDefinition).print();
    }
};
  



  return (
    <>
      <div className="large-icon-container"
        style={{
          background: getCurrentColor(currentIndex).gradient,  // Set dynamic gradient
          boxShadow: `0 0 20px ${getCurrentColor(currentIndex).glowColor}`,  // Set dynamic glow color
          borderColor: getCurrentColor(currentIndex).glowColor,  // Set dynamic border color
        }}>
        <FontAwesomeIcon icon={faScaleBalanced} className="large-icon" />
      </div>
      
      <div className="grading-tool-container" style={style}>
        <h2>{t.gradingToolTitle}</h2>

        <div>
          <label>{t.author}</label>
          <input
            className="input-field"
            type="text"
            value={authorName}
            onChange={(e) => setAuthorName(e.target.value)}  // Use setAuthorName to update state
    style={{
      '--glow-color': getCurrentColor(currentIndex).glowColor, // Set dynamic glow color based on currentIndex
      '--light-background': getCurrentColor(currentIndex).lightBackground,
      '--gradient': getCurrentColor(currentIndex).gradient,
    }}
          />

          <label>{t.reviewer}</label>
          <input
            className="input-field"
            type="text"
            value={reviewerName}
            onChange={(e) => setReviewerName(e.target.value)}  // Use setReviewerName to update state
                style={{
                  '--glow-color': getCurrentColor(currentIndex).glowColor, // Set dynamic glow color based on currentIndex
                  '--light-background': getCurrentColor(currentIndex).lightBackground,
                  '--gradient': getCurrentColor(currentIndex).gradient,
    }}
          />
        </div>

        <div>
          <h4><i className="icon-grade"></i> {t.chooseGradingSystem}</h4>
          <div className="radio-group">
            <label className="radio-item">
              <input
                type="radio"
                value="810"
                checked={gradeDisplayType === '810'}
                onChange={(e) => onGradingValueChange(e.target.value)}
              />
              {t.germanUnderMidLevel}
            </label>
            <label className="radio-item">
              <input
                type="radio"
                value="kursstufe"
                checked={gradeDisplayType === 'kursstufe'}
                onChange={(e) => onGradingValueChange(e.target.value)}
              />
              {t.germanKursstufe}
            </label>
            <label className="radio-item">
              <input
                type="radio"
                value="percentages"
                checked={gradeDisplayType === 'percentages'}
                onChange={(e) => onGradingValueChange(e.target.value)}
              />
              {t.showOnlyPercentages}
            </label>
          </div>
        </div>

        {currentCardContent.sliderCategories.map((category) => (
          <div key={category.id}>
            <h3>{category.categoryTitle[selectedLanguage]}</h3>
            {category.sliders.map((slider) => {
              const id = slider.id; // Use the composite ID directly
              return (
                <RangeSlider
                  key={id}
                  label={slider.label[selectedLanguage]}
                  value={sliderValues[id]} // Access the value using the composite ID
                  onChange={handleSliderChange(id)} // Pass the composite ID
                  getMessage={(value) => getSliderMessage(slider, value)}
                  currentIndex={currentIndex}  // Pass currentIndex to apply dynamic colors
                />
              );
            })}
          </div>
        ))}

        <div className="feedback-sheet" ref={printRef}>
          <div className="header-section">
            <div className="column">
      <h3>
                {t.feedbackTitle}
              </h3>

                <div><strong>{ t.texttype}: {currentCardContent.shorttitle[selectedLanguage]}</strong></div>    
{authorName && (
  <div>
    <strong>{t.author}: {authorName}</strong>
  </div>
)}
      <div>
        <em>
          {reviewerName ? `${t.reviewer}: ${reviewerName}` : ''}
        </em>
      </div>
            </div>
              <div className="grade-display">{displayGrade()}</div>
          </div>
          <div>

          </div>
                      <div></div>
              


            
  <div className="content-section">

          {currentCardContent.sliderCategories.map((category, catIndex) => (
            <div key={catIndex}>
              <h3
                style={{
                            '--light-background': getCurrentColor(currentIndex).lightBackground,
              }}>{category.categoryTitle[selectedLanguage]}</h3>
              {category.sliders.map((slider, index) => (
  <div key={index}>
    <strong>{slider.label[selectedLanguage]}:</strong> {sliderValues[slider.id]}% <br />
                  <em>{getSliderMessage(slider, sliderValues[slider.id])}</em>
                  
                </div>

                
                
              ))}
              
            </div>
          ))}

          </div>
                      <div class="grading-overview">
            <h3>{t.averageWord}: {average}%</h3>
              </div>
        </div>


       

                  
        <fieldset>
  <legend>
    <div className="feedback-legend">
      <ShareSVG className="toolbar-icon-share" />
              <span>{ t.feedbackSharing}</span>
    </div>
  </legend>
<div className="feedback-toolbar">
    <button type="button" className="toolbar-btn" onClick={() => generatePdf('download')}
        style={{
          background: getCurrentColor(currentIndex).gradient,
          '--glow-color': getCurrentColor(currentIndex).glowColor,
        }}>
        <DownloadSVG className="toolbar-icon" />
        <span className="button-label">{t.downloadPdf}</span>
    </button>

          <button type="button" className="toolbar-btn" onClick={() => generatePdf('open')}
                      style={{
                        background: getCurrentColor(currentIndex).gradient,
                         '--glow-color': getCurrentColor(currentIndex).glowColor,
          }}>
        <OpenSVG className="toolbar-icon" />
        <span className="button-label">{t.openPdf}</span>
    </button>

          <button type="button" className="toolbar-btn" onClick={() => generatePdf('print')}
                      style={{
                        background: getCurrentColor(currentIndex).gradient,
                         '--glow-color': getCurrentColor(currentIndex).glowColor,
          }}>
        <PrintSVG className="toolbar-icon" />
        <span className="button-label">{t.printPdf}</span>
    </button>
          </div>
          </fieldset>
      </div>
    </>
  );
}

export default GradingTool;
