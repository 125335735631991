import LineGraph from '../img/line-chart.svg';
import BarChart from '../img/bar-chart-2.svg';
import PieChart from '../img/pie-chart.svg';
import Table from '../img/table.svg';
import '../styles/cardsData.css';
import CharacterIllustration from '../img/fiction/swordsman.svg';
import PlotIllustration from '../img/fiction/plot.svg';
import SettingIllustration from '../img/fiction/mountain.svg';
import ThemeIllustration from '../img/fiction/color-swatch-theme.svg';

const cardsData = [
  {
    id: 1,
    title: {
      en: 'Writing an outline',
      de: 'Eine Outline schreiben',
    },
    shorttitle: {
      en: 'Outline',
      de: 'Outline',
    },
    content: {
      en: 'Reproduction and Text Comprehension',
      de: 'Reproduktion und Textverständnis',
    },
    categories: [
      {
        title: {
          en: 'The Basics',
          de: 'Die Grundlagen',
        },
        description: {
          en: 'Fundamental information on how to start.',
          de: 'Grundlegende Informationen zum Einstieg.',
        },
        details: {
          en: [
            <>
              <blockquote>"To outline: giving a structured, general account of something, referring to the relevant elements and omitting details."</blockquote>
              <p>
                In contrast to a summary, in which we usually give the main points of a full text, an outline <strong>focuses on maybe one, two aspects</strong> of a text. It means that we only <strong>focus on information that is asked for</strong>, information that is <strong>relevant to the task</strong>.
              </p>
              <p>
                Hence, when you are asked to outline a text, you are usually given criteria on the basis of which you need to outline the text.
              </p>
              <p>
                <em>Example: Outline the inner development of the story's protagonist.</em>
              </p>
              <p><strong>Be careful!</strong> In English, outlining often represents preparing a text by writing a hierarchical structure of some sort, either numbered or using bullet points. In this outline, however, you are asked to write full, coherent sentences.</p>
            </>
          ],
          de: [
            <>
              <blockquote>"Eine Outline schreiben (zu Deutsch: <em>Skizzieren</em>): eine strukturierte, allgemeine Darstellung von etwas geben, sich auf die relevanten Elemente beziehen und Details weglassen."</blockquote>
              <p>
                Im Gegensatz zu einer Zusammenfassung, bei der wir normalerweise die wichtigsten Punkte eines vollständigen Textes wiedergeben, <strong>konzentriert sich eine Outline bzw. Skizze vielleicht auf ein oder zwei Aspekte</strong> eines Textes. Das bedeutet, dass wir uns nur auf die <strong>Informationen konzentrieren, die gefragt sind</strong>, also auf Informationen, die <strong>für die Aufgabe relevant sind</strong>.
              </p>
              <p>
                Daher erhält man in der Regel Kriterien, wenn man aufgefordert wird, einen Text zu skizzieren, nach denen man den Text skizzieren muss.
              </p>
              <p>
                <em>Beispiel: Skizziere die innere Entwicklung der Hauptfigur der Geschichte.</em>
              </p>
              <p><strong>Vorsicht!</strong> Im Englischen bedeutet "outlining" oft, einen Text in Form einer hierarchischen Struktur zu erstellen, entweder nummeriert oder mit Aufzählungspunkten. In dieser Skizze jedoch wirst du gebeten, vollständige, zusammenhängende Sätze zu schreiben.</p>
            </>
          ],

        },
      },
      {
        title: {
          en: 'Preparation',
          de: 'Vorbereitung',
        },
        description: {
          en: 'Necessary steps before outlining.',
          de: 'Notwendige Schritte vor dem Schreiben.',
        },
        details: {
          en: [
            <>
              <ol>
                <li><strong>Anticipate:</strong> Take a look at any features of the text - headline, author, genre etc. - so that you know which kind of text to expect.</li>
                <li><strong>Focus on the task:</strong> Try to understand what is required by you, i.e. which aspects of the text in question you are supposed to outline.</li>
                <li><strong>Focus on the text:</strong> Read the text well. Mark any information that is relevant for the task.</li>
                <li><strong>Extract information:</strong> Bring the elements of the text, which are necessary for your outline, in keywords to paper. Gather elements that are linked.</li>
                <li><strong>Write your outline:</strong> <strong>In your own words,</strong> bring your findings in full sentences to paper.</li>
              </ol>
            </>
          ],
          de: [
            <>
              <ol>
                <li><strong>Vorwegnehmen:</strong> Schau dir alle Merkmale des Textes an – Überschrift, Autor, Genre usw. – damit du weißt, welche Art von Text dich erwartet.</li>
                <li><strong>Fokus auf die Aufgabe:</strong> Versuche zu verstehen, was von dir verlangt wird, d.h. welche Aspekte des Textes du skizzieren sollst.</li>
                <li><strong>Fokus auf den Text:</strong> Lies den Text sorgfältig. Markiere alle Informationen, die für die Aufgabe relevant sind.</li>
                <li><strong>Informationen extrahieren:</strong> Bringe die Elemente des Textes, die für deine Skizze notwendig sind, in Stichworten zu Papier. Sammle Elemente, die miteinander verknüpft sind.</li>
                <li><strong>Schreibe deine Skizze:</strong> <strong>In deinen eigenen Worten,</strong> bringe deine Erkenntnisse in ganzen Sätzen zu Papier.</li>
              </ol>
            </>
          ],
        },
      },
      {
        title: {
          en: 'Structure',
          de: 'Struktur',
        },
        description: {
          en: 'How to lay out your text.',
          de: 'Wie Du Deinen Text gliederst.',
        },
        details: {
          en: [
            <>
              <p>
                An outline typically is structured with the help of{' '}
                <em>two individual paragraphs</em>.
              </p>
              <p>
                <strong>Paragraph One</strong> contains an overview about the text
                itself.
              </p>
              <p><em>The Introductory Sentence:</em> In one or two sentences we state the text's author, title, publication date, text type, and
                any other features you may discover. Here, it is also good practise
                to provide in one sentence what the text is "about" as well as linking the text to the task.</p>
              <p>
                <strong>Paragraph Two</strong> is our main part. Here, you lay out the essential information you are asked for in the given task.
              </p>
            </>
          ],
          de: [
            <>
              <p>
                Eine Skizze ist in der Regel mit Hilfe von <em>zwei einzelnen Absätzen</em> strukturiert.
              </p>
              <p>
                <strong>Absatz Eins</strong> enthält einen Überblick über den Text selbst.
              </p>
              <p><em>Der Einleitungssatz:</em> In ein oder zwei Sätzen nennen wir den Autor, den Titel, das Veröffentlichungsdatum, die Textsorte und alle anderen Merkmale, die du entdeckst. Hier ist es auch gute Praxis, in einem Satz zu sagen, worum es im Text geht, sowie den Text mit der Aufgabe zu verknüpfen.</p>
              <p>
                <strong>Absatz Zwei</strong> ist unser Hauptteil. Hier legst du die wesentlichen Informationen dar, nach denen in der Aufgabe gefragt wird.
              </p>
            </>
          ],
        },
      },
      {
        title: {
          en: 'Language and Style',
          de: 'Sprache und Stil',
        },
        description: {
          en: 'Our linguistic tools to write an outline.',
          de: 'Unsere Werkzeuge um eine Outline zu schreiben.',
        },
        details: {
          en: [
            <>
              
              <p>In this specific form of an outline, use full, coherent and structured sentences. Study the following example task:</p>

<em>Outline the inner development of the wife in Ernest Hemingway's "Cat in the Rain".</em>
<blockquote><strong>Paragraph one:</strong> In "Cat in the rain ", American author Ernest Hemingway presents a very surface-level narrative of an American couple on their holidays in Italy. The short story was first published by Boni & Liveright in 1925 in the short story collection "In Our Time".</blockquote>
<p>An outline is typically written in the present tense, also called <em>literary present.</em> (Unless flashbacks or foreshadowings make it necessary to use a past or future tense.)</p>

<blockquote><strong>Paragraph two:</strong> As the American wife watches the rain, she notices a cat huddled beneath one of the dripping green tables. Feeling sorry for the cat, which is trying to make herself as small as possible to avoid getting dripped on, the wife decides to rescue the kitty. ...</blockquote>
              <p>When you outline, the most important thing to do is to use your own words. It is a common pitfall to copy parts from the text in question, as it does not really count as an achievement to just puzzle pieces of a text together.</p>
              <p><strong>Paraphrasing</strong> is thus a necessary skill when writing outlines. It means that we distill the original text into a compact and concise form using similar ideas, expressed as <strong>synonyms.</strong></p>
              <p>Other points to remember:</p>
              <ul>
                <li><strong>Change direct to indirect speech.</strong> When we display what an author says, we tell the reader explicitly that it is their opinion.<br />Ex.: "The author <em>suggests / believes / holds the opinion</em> that..."</li>
                <li><strong>Find ways in order to shorten content.</strong> Enumerations can be turned into umbrella terms (e.g. "Writing instruments" instead of "pens, pencils, and markers,"). Participle constructions are an elegant way to rephrase and outline at the same time: "Having said that, he goes on to...".</li>
              </ul>
              <p>The aim of an outline is to display information in a more concise way. So make sure that your text is not longer than one third of the original text!</p>
            </>
          ],
          de: [
            <>
              <p>In einer Skizze ist das Wichtigste, die eigenen Worte zu verwenden. Es ist ein häufiger Fehler, Teile des betreffenden Textes zu kopieren, da es keine wirkliche Leistung darstellt, nur Textbausteine zusammenzusetzen.</p>
              <p><strong>Paraphrasieren</strong> ist daher eine notwendige Fähigkeit beim Schreiben von Skizzen. Es bedeutet, dass wir den Originaltext in eine kompakte und prägnante Form bringen, indem wir ähnliche Ideen mit <strong>Synonymen</strong> ausdrücken.</p>
              <p>Weitere wichtige Punkte:</p>
              <ul>
                <li><strong>Benutze das literarische Präsens.</strong> Wenn wir ein literarisches Werk umformulieren, verwenden wir normalerweise das Präsens. (Es sei denn, Rückblenden oder Vorausdeutungen machen es notwendig, die Vergangenheits- oder Zukunftsform zu benutzen.)<br />Beispiel: "In *Wer die Nachtigall stört* <em>erforscht</em> Harper Lee Themen wie Rassengerechtigkeit und moralisches Wachstum durch die Figur von Scout Finch. Im Verlauf des Romans <em>lernt</em> Scout wichtige Lektionen über Empathie und Verständnis durch die Beobachtung der Handlungen ihres Vaters, Atticus Finch."</li>
                <li><strong>Ändere direkte in indirekte Rede.</strong> Wenn wir wiedergeben, was ein Autor sagt, machen wir dem Leser explizit klar, dass es sich um seine Meinung handelt.<br />Beispiel: "Der Autor <em>schlägt vor / glaubt / ist der Meinung</em>, dass..."</li>
                <li><strong>Finde Möglichkeiten, Inhalte zu verkürzen.</strong> Aufzählungen können in Oberbegriffe umgewandelt werden (z.B. "Schreibutensilien" statt "Stifte, Bleistifte und Marker"). Partizipialkonstruktionen sind eine elegante Möglichkeit, umzuformulieren und gleichzeitig zu skizzieren: "Nachdem er das gesagt hat, fährt er fort mit..."</li>
              </ul>
              <p>Das Ziel einer Skizze ist es, Informationen prägnanter darzustellen. Achte also darauf, dass dein Text nicht länger als ein Drittel des Originaltextes ist!</p>
            </>
          ],
        },
        },
        { 
        title: {
          en: 'Vocabulary',
          de: 'Wortschatz',
        },
        description: {
          en: 'Helpful words and phrases.',
          de: 'Hilfreiche Wörter und Formulierungen.',
        },
        details: {
          en: [
            <>
            <h3>Introduction:</h3>
            <ul>
              <li><strong>The text, written by</strong> [author's name] <strong>and published in</strong> [year], <strong>deals with</strong> [main topic].</li>
              <li><strong>In the article/story titled</strong> [title], <strong>the author discusses</strong> [theme/subject].</li>
              <li><strong>The text addresses</strong> [key issue] <strong>by focusing on</strong> [aspect].</li>
              <li><strong>The text belongs to the genre of</strong> [genre] <strong>and focuses on</strong> [relevant aspect].</li>
            </ul>

            <h3>Paraphrasing/Describing content:</h3>
            <ul>
              <li><strong>The author emphasizes</strong> that...</li>
              <li><strong>The text explains</strong> that...</li>
              <li><strong>The protagonist is portrayed as</strong> [character traits].</li>
              <li><strong>The author explores themes of</strong> [theme], <strong>using</strong> [character, setting, plot] <strong>to illustrate</strong> [idea].</li>
            </ul>

            <h3>Literary present:</h3>
            <ul>
              <li><strong>Throughout the text, the author presents</strong>...</li>
              <li><strong>As the narrative unfolds, the protagonist learns</strong>...</li>
              <li><strong>The text shows</strong> how...</li>
            </ul>

            <h3>Summarizing key points:</h3>
            <ul>
              <li><strong>In essence, the text outlines</strong> [main point].</li>
              <li><strong>The author suggests</strong> that...</li>
              <li><strong>The text highlights</strong> the importance of...</li>
            </ul>

            <h3>Concluding:</h3>
            <ul>
              <li><strong>To conclude, the author demonstrates</strong>...</li>
              <li><strong>Overall, the text reflects</strong> [the main message or theme].</li>
              <li><strong>The text serves as an example of</strong> [literary technique, theme, etc.].</li>
            </ul>

            <h3>Shortening/Generalizing:</h3>
            <ul>
              <li><strong>Various examples of</strong> [umbrella term, e.g., "household gadgets"] <strong>are used throughout the text.</strong></li>
              <li><strong>The author references multiple instances of</strong> [general term] <strong>such as</strong> [e.g., “social issues” instead of “poverty, inequality, and discrimination”].</li>
            </ul>
            </>
          ],
          de: [
            <>
  <h3>Einleitung:</h3>
  <ul>
    <li><strong>The text, written by</strong> [author's name] <strong>and published in</strong> [year], <strong>deals with</strong> [main topic].</li>
    <li><strong>In the article/story titled</strong> [title], <strong>the author discusses</strong> [theme/subject].</li>
    <li><strong>The text addresses</strong> [key issue] <strong>by focusing on</strong> [aspect].</li>
    <li><strong>The text belongs to the genre of</strong> [genre] <strong>and focuses on</strong> [relevant aspect].</li>
  </ul>

  <h3>Paraphrasieren/Beschreibung des Inhalts:</h3>
  <ul>
    <li><strong>The author emphasizes</strong> that...</li>
    <li><strong>The text explains</strong> that...</li>
    <li><strong>The protagonist is portrayed as</strong> [character traits].</li>
    <li><strong>The author explores themes of</strong> [theme], <strong>using</strong> [character, setting, plot] <strong>to illustrate</strong> [idea].</li>
  </ul>

  <h3>Literarisches Präsens:</h3>
  <ul>
    <li><strong>Throughout the text, the author presents</strong>...</li>
    <li><strong>As the narrative unfolds, the protagonist learns</strong>...</li>
    <li><strong>The text shows</strong> how...</li>
  </ul>

  <h3>Zusammenfassung der Hauptpunkte:</h3>
  <ul>
    <li><strong>In essence, the text outlines</strong> [main point].</li>
    <li><strong>The author suggests</strong> that...</li>
    <li><strong>The text highlights</strong> the importance of...</li>
  </ul>

  <h3>Fazit:</h3>
  <ul>
    <li><strong>To conclude, the author demonstrates</strong>...</li>
    <li><strong>Overall, the text reflects</strong> [the main message or theme].</li>
    <li><strong>The text serves as an example of</strong> [literary technique, theme, etc.].</li>
  </ul>

  <h3>Verkürzen/Verallgemeinern:</h3>
  <ul>
    <li><strong>Various examples of</strong> [umbrella term, e.g., "household gadgets"] <strong>are used throughout the text.</strong></li>
    <li><strong>The author references multiple instances of</strong> [general term] <strong>such as</strong> [e.g., “social issues” instead of “poverty, inequality, and discrimination”].</li>
  </ul>


            </>
          ],
        },
      },
      { 
        title: {
          en: 'Example solution',
          de: 'Musterlösung',
        },
        description: {
          en: 'A model for your orientation.',
          de: 'Ein Beispiel zu Deiner Orientierung.',
        },
        details: {
          en: [
            <>
              <h3>The task:</h3>
              <blockquote>Outline the American wife’s emotional development throughout the story.</blockquote>
            <h3>The text:</h3>
            <p>From Ernest Hemingway: The Sun Also Rises & Other Writings 1918–1926 (Library of America, 2020), pages 227–30.
First published in In Our Time (1925).</p>
            <h2>Cat in the Rain</h2>
              <h4>ERNEST HEMINGWAY</h4>
              <p>THERE WERE only two Americans stopping at the hotel. They did not know any of the people they passed on the stairs on their way to and from their room. Their room was on the second floor facing the sea. It also faced the public garden and the war monument. There were big palms and green benches in the public garden. In the good weather there was always an artist with his easel. Artists liked the way the palms grew and the bright colors of the hotels facing the gardens and the sea. Italians came from a long way off to look up at the war monument. It was made of bronze and glistened in the rain. It was raining. The rain dripped from the palm trees. Water stood in pools on the gravel paths. The sea broke in a long line in the rain and slipped back down the beach to come up and break again in a long line in the rain. The motor cars were gone from the square by the war monument. Across the square in the doorway of the café a waiter stood looking out at the empty square.</p>

<p>The American wife stood at the window looking out. Outside right under their window a cat was crouched under one of the dripping green tables. The cat was trying to make herself so compact that she would not be dripped on.</p>
<p>“I’m going down and get that kitty,” the American wife said.</p>
<p>“I’ll do it,” her husband offered from the bed.</p>
<p>“No, I’ll get it. The poor kitty out trying to keep dry under a table.”</p>
<p>The husband went on reading, lying propped up with the two pillows at the foot of the bed.</p>
<p>“Don’t get wet,” he said.</p>
<p>The wife went downstairs and the hotel owner stood up and bowed to her as she passed the office. His desk was at the far end of the office. He was an old man and very tall.</p>
<p>“Il piove,” the wife said. She liked the hotel-keeper.</p>
<p>“Si, si, Signora, brutto tempo. It is very bad weather.”</p>
              <p>He stood behind his desk in the far end of the dim room. The wife liked him. She liked the deadly serious way he received any complaints. She liked his dignity. She liked the way he wanted to serve her. She liked the way he felt about being a hotel-keeper. She liked his old, heavy face and big hands.</p>

<p>Liking him she opened the door and looked out. It was raining harder. A man in a rubber cape was crossing the empty square to the café. The cat would be around to the right. Perhaps she could go along under the eaves. As she stood in the doorway an umbrella opened behind her. It was the maid who looked after their room.</p>

<p>“You must not get wet,” she smiled, speaking Italian. Of course, the hotel-keeper had sent her.</p>

<p>With the maid holding the umbrella over her, she walked along the gravel path until she was under their window. The table was there, washed bright green in the rain, but the cat was gone. She was suddenly disappointed. The maid looked up at her.</p>

<p>“Ha perduto qualque cosa, Signora?”</p>

<p>“There was a cat,” said the American girl.</p>

<p>“A cat?”</p>

<p>“Si, il gatto.”</p>

<p>“A cat?” the maid laughed. “A cat in the rain?”</p>

<p>“Yes,” she said, “under the table.” Then, “Oh, I wanted it so much. I wanted a kitty.”</p>

<p>When she talked English the maid’s face tightened.</p>

<p>“Come, Signora,” she said. “We must get back inside. You will be wet.”</p>

<p>“I suppose so,” said the American girl.</p>

<p>They went back along the gravel path and passed in the door. The maid stayed outside to close the umbrella. As the American girl passed the office, the padrone bowed from his desk. Something felt very small and tight inside the girl. The padrone made her feel very small and at the same time really important. She had a momentary feeling of being of supreme importance. She went on up the stairs. She opened the door of the room. George was on the bed, reading.</p>

<p>“Did you get the cat?” he asked, putting the book down.</p>

<p>“It was gone.”</p>

              <p>“Wonder where it went to,” he said, resting his eyes from reading.</p>
              
              <p>She sat down on the bed.</p>

<p>“I wanted it so much,” she said. “I don’t know why I wanted it so much. I wanted that poor kitty. It isn’t any fun to be a poor kitty out in the rain.”</p>

<p>George was reading again.</p>

<p>She went over and sat in front of the mirror of the dressing table looking at herself with the hand glass. She studied her profile, first one side and then the other. Then she studied the back of her head and her neck.</p>

<p>“Don’t you think it would be a good idea if I let my hair grow out?” she asked, looking at her profile again.</p>

<p>George looked up and saw the back of her neck, clipped close like a boy’s.</p>

<p>“I like it the way it is.”</p>

<p>“I get so tired of it,” she said. “I get so tired of looking like a boy.”</p>

<p>George shifted his position in the bed. He hadn’t looked away from her since she started to speak.</p>

<p>“You look pretty darn nice,” he said.</p>

<p>She laid the mirror down on the dresser and went over to the window and looked out. It was getting dark.</p>

<p>“I want to pull my hair back tight and smooth and make a big knot at the back that I can feel,” she said. “I want to have a kitty to sit on my lap and purr when I stroke her.”</p>

<p>“Yeah?” George said from the bed.</p>

<p>“And I want to eat at a table with my own silver and I want candles. And I want it to be spring and I want to brush my hair out in front of a mirror and I want a kitty and I want some new clothes.”</p>

<p>“Oh, shut up and get something to read,” George said. He was reading again.</p>

<p>His wife was looking out of the window. It was quite dark now and still raining in the palm trees.</p>

<p>“Anyway, I want a cat,” she said, “I want a cat. I want a cat now. If I can’t have long hair or any fun, I can have a cat.”</p>

<p>George was not listening. He was reading his book. His wife looked out of the window where the light had come on in the square.</p>

<p>Someone knocked at the door.</p>

<p>“Avanti,” George said. He looked up from his book.</p>

<p>In the doorway stood the maid. She held a big tortoise-shell cat pressed tight against her and swung down against her body.</p>

<p>“Excuse me,” she said, “the padrone asked me to bring this for the Signora.”</p>

              <h3>Model Outline</h3>

<h4>Paragraph One:</h4>
<p>In <i>“Cat in the Rain,”</i> American author Ernest Hemingway presents a brief narrative about an American couple staying at a hotel in Italy. The short story was first published in 1925 in the collection <i>“In Our Time.”</i> The story focuses on the American wife’s growing emotional frustration during a rainy day as she becomes fixated on rescuing a cat. The narrative subtly explores her deeper desires for affection, comfort, and self-identity.</p>

<h4>Paragraph Two:</h4>
<p>Throughout the story, the American wife’s emotional development centers on her unfulfilled longings, which become more apparent as the narrative progresses. At first, her desire to rescue the cat seems like a simple act of compassion, yet it symbolizes her need for care and attention—something she feels is missing in her own life. This desire for the cat quickly becomes more significant, representing her craving for emotional warmth and connection, especially from her husband, who appears indifferent to her needs. Her repeated focus on the cat can be seen as a way to externalize her deeper dissatisfaction with her relationship. The cat, huddled in the rain, is vulnerable and exposed, much like the wife feels emotionally.</p>

<p>As she observes herself in the mirror, she starts to express other desires, such as wanting longer hair, which reflects her longing to embrace a more traditional feminine identity, contrasting with the boyish appearance she has grown tired of. She mentions wanting personal items like her own silverware and candles—symbols of a stable, more domestic life, highlighting her yearning for comfort and normalcy. These desires go unmet, leaving her frustrated, and her repeated mention of wanting a cat intensifies as the story goes on. It becomes clear that the cat is a substitute for the affection and security she lacks in her marriage. Ultimately, the cat represents both her emotional needs and her inability to fulfill them through her disengaged husband, who continues reading and dismisses her emotional appeals. Her growing dissatisfaction is further emphasized by her final insistence, stating that if she can't have long hair or other pleasures, she at least wants a cat. This repeated fixation underscores the depth of her inner conflict and the emotional distance between her and her husband.</p>

<h3>Key Elements in the Outline:</h3>
<ul>
  <li><strong>Author and Context:</strong> Hemingway and the story's publication details.</li>
  <li><strong>Main Topic:</strong> The American wife’s emotional journey.</li>
  <li><strong>Introduction to Task:</strong> Tying the text’s content to the task (outlining emotional development).</li>
  <li><strong>Focus:</strong> The task highlights her emotional needs and frustration as reflected through her interaction with the cat and desires for other comforts.</li>
  <li><strong>Structure:</strong> Two paragraphs—overview and main part, detailing her emotional progression in the story.</li>
</ul>

              </>
          ],
          de: [
            <>
                          <h3>Die Aufgabe:</h3>
              <blockquote>Outline the American wife’s emotional development throughout the story.</blockquote>
            <h3>Der Text:</h3>
            <p>Aus Ernest Hemingway: The Sun Also Rises & Other Writings 1918–1926 (Library of America, 2020), pages 227–30.
Zuerst veröffentlicht in In Our Time (1925).</p>
            <h2>Cat in the Rain</h2>
              <h4>ERNEST HEMINGWAY</h4>
              <p>THERE WERE only two Americans stopping at the hotel. They did not know any of the people they passed on the stairs on their way to and from their room. Their room was on the second floor facing the sea. It also faced the public garden and the war monument. There were big palms and green benches in the public garden. In the good weather there was always an artist with his easel. Artists liked the way the palms grew and the bright colors of the hotels facing the gardens and the sea. Italians came from a long way off to look up at the war monument. It was made of bronze and glistened in the rain. It was raining. The rain dripped from the palm trees. Water stood in pools on the gravel paths. The sea broke in a long line in the rain and slipped back down the beach to come up and break again in a long line in the rain. The motor cars were gone from the square by the war monument. Across the square in the doorway of the café a waiter stood looking out at the empty square.</p>

<p>The American wife stood at the window looking out. Outside right under their window a cat was crouched under one of the dripping green tables. The cat was trying to make herself so compact that she would not be dripped on.</p>
<p>“I’m going down and get that kitty,” the American wife said.</p>
<p>“I’ll do it,” her husband offered from the bed.</p>
<p>“No, I’ll get it. The poor kitty out trying to keep dry under a table.”</p>
<p>The husband went on reading, lying propped up with the two pillows at the foot of the bed.</p>
<p>“Don’t get wet,” he said.</p>
<p>The wife went downstairs and the hotel owner stood up and bowed to her as she passed the office. His desk was at the far end of the office. He was an old man and very tall.</p>
<p>“Il piove,” the wife said. She liked the hotel-keeper.</p>
<p>“Si, si, Signora, brutto tempo. It is very bad weather.”</p>
              <p>He stood behind his desk in the far end of the dim room. The wife liked him. She liked the deadly serious way he received any complaints. She liked his dignity. She liked the way he wanted to serve her. She liked the way he felt about being a hotel-keeper. She liked his old, heavy face and big hands.</p>

<p>Liking him she opened the door and looked out. It was raining harder. A man in a rubber cape was crossing the empty square to the café. The cat would be around to the right. Perhaps she could go along under the eaves. As she stood in the doorway an umbrella opened behind her. It was the maid who looked after their room.</p>

<p>“You must not get wet,” she smiled, speaking Italian. Of course, the hotel-keeper had sent her.</p>

<p>With the maid holding the umbrella over her, she walked along the gravel path until she was under their window. The table was there, washed bright green in the rain, but the cat was gone. She was suddenly disappointed. The maid looked up at her.</p>

<p>“Ha perduto qualque cosa, Signora?”</p>

<p>“There was a cat,” said the American girl.</p>

<p>“A cat?”</p>

<p>“Si, il gatto.”</p>

<p>“A cat?” the maid laughed. “A cat in the rain?”</p>

<p>“Yes,” she said, “under the table.” Then, “Oh, I wanted it so much. I wanted a kitty.”</p>

<p>When she talked English the maid’s face tightened.</p>

<p>“Come, Signora,” she said. “We must get back inside. You will be wet.”</p>

<p>“I suppose so,” said the American girl.</p>

<p>They went back along the gravel path and passed in the door. The maid stayed outside to close the umbrella. As the American girl passed the office, the padrone bowed from his desk. Something felt very small and tight inside the girl. The padrone made her feel very small and at the same time really important. She had a momentary feeling of being of supreme importance. She went on up the stairs. She opened the door of the room. George was on the bed, reading.</p>

<p>“Did you get the cat?” he asked, putting the book down.</p>

<p>“It was gone.”</p>

              <p>“Wonder where it went to,” he said, resting his eyes from reading.</p>
              
              <p>She sat down on the bed.</p>

<p>“I wanted it so much,” she said. “I don’t know why I wanted it so much. I wanted that poor kitty. It isn’t any fun to be a poor kitty out in the rain.”</p>

<p>George was reading again.</p>

<p>She went over and sat in front of the mirror of the dressing table looking at herself with the hand glass. She studied her profile, first one side and then the other. Then she studied the back of her head and her neck.</p>

<p>“Don’t you think it would be a good idea if I let my hair grow out?” she asked, looking at her profile again.</p>

<p>George looked up and saw the back of her neck, clipped close like a boy’s.</p>

<p>“I like it the way it is.”</p>

<p>“I get so tired of it,” she said. “I get so tired of looking like a boy.”</p>

<p>George shifted his position in the bed. He hadn’t looked away from her since she started to speak.</p>

<p>“You look pretty darn nice,” he said.</p>

<p>She laid the mirror down on the dresser and went over to the window and looked out. It was getting dark.</p>

<p>“I want to pull my hair back tight and smooth and make a big knot at the back that I can feel,” she said. “I want to have a kitty to sit on my lap and purr when I stroke her.”</p>

<p>“Yeah?” George said from the bed.</p>

<p>“And I want to eat at a table with my own silver and I want candles. And I want it to be spring and I want to brush my hair out in front of a mirror and I want a kitty and I want some new clothes.”</p>

<p>“Oh, shut up and get something to read,” George said. He was reading again.</p>

<p>His wife was looking out of the window. It was quite dark now and still raining in the palm trees.</p>

<p>“Anyway, I want a cat,” she said, “I want a cat. I want a cat now. If I can’t have long hair or any fun, I can have a cat.”</p>

<p>George was not listening. He was reading his book. His wife looked out of the window where the light had come on in the square.</p>

<p>Someone knocked at the door.</p>

<p>“Avanti,” George said. He looked up from his book.</p>

<p>In the doorway stood the maid. She held a big tortoise-shell cat pressed tight against her and swung down against her body.</p>

<p>“Excuse me,” she said, “the padrone asked me to bring this for the Signora.”</p>

              <h3>Model Outline</h3>

<h4>Absatz Eins:</h4>
<p>In <i>“Cat in the Rain,”</i> American author Ernest Hemingway presents a brief narrative about an American couple staying at a hotel in Italy. The short story was first published in 1925 in the collection <i>“In Our Time.”</i> The story focuses on the American wife’s growing emotional frustration during a rainy day as she becomes fixated on rescuing a cat. The narrative subtly explores her deeper desires for affection, comfort, and self-identity.</p>

<h4>Absatz Zwei:</h4>
<p>Throughout the story, the American wife’s emotional development centers on her unfulfilled longings, which become more apparent as the narrative progresses. At first, her desire to rescue the cat seems like a simple act of compassion, yet it symbolizes her need for care and attention—something she feels is missing in her own life. This desire for the cat quickly becomes more significant, representing her craving for emotional warmth and connection, especially from her husband, who appears indifferent to her needs. Her repeated focus on the cat can be seen as a way to externalize her deeper dissatisfaction with her relationship. The cat, huddled in the rain, is vulnerable and exposed, much like the wife feels emotionally.</p>

<p>As she observes herself in the mirror, she starts to express other desires, such as wanting longer hair, which reflects her longing to embrace a more traditional feminine identity, contrasting with the boyish appearance she has grown tired of. She mentions wanting personal items like her own silverware and candles—symbols of a stable, more domestic life, highlighting her yearning for comfort and normalcy. These desires go unmet, leaving her frustrated, and her repeated mention of wanting a cat intensifies as the story goes on. It becomes clear that the cat is a substitute for the affection and security she lacks in her marriage. Ultimately, the cat represents both her emotional needs and her inability to fulfill them through her disengaged husband, who continues reading and dismisses her emotional appeals. Her growing dissatisfaction is further emphasized by her final insistence, stating that if she can't have long hair or other pleasures, she at least wants a cat. This repeated fixation underscores the depth of her inner conflict and the emotional distance between her and her husband.</p>

<h3>Wichtige Elemente der Gliederung:</h3>
<ul>
  <li><strong>Autor und Kontext:</strong> Hemingway und die Veröffentlichungsdaten der Geschichte.</li>
  <li><strong>Hauptthema:</strong> Die emotionale Reise der amerikanischen Ehefrau.</li>
  <li><strong>Einführung in die Aufgabe:</strong> Verknüpfung des Inhalts des Textes mit der Aufgabe (Gliederung der emotionalen Entwicklung).</li>
  <li><strong>Fokus:</strong> Die Aufgabe hebt ihre emotionalen Bedürfnisse und Frustrationen hervor, die durch ihre Interaktion mit der Katze und ihre Sehnsüchte nach anderen Annehmlichkeiten deutlich werden.</li>
  <li><strong>Struktur:</strong> Zwei Absätze – Überblick und Hauptteil, die ihre emotionale Entwicklung in der Geschichte beschreiben.</li>
</ul>
              </>

          ],
        }

      }
    ],
checklist: {
  en: [
    {
      question: 'Did I understand the task and identify the correct focus?',
      explanation: 'Ensure you have clearly understood the prompt and the specific elements of the text you need to focus on for the outline.',
      messages: [
        "Significant improvement needed in understanding the task.",  
        "Task understanding is below expectations.",                  
        "Satisfactory understanding of the task.",                    
        "Good understanding of the task and focus!",                  
        "Excellent grasp of the task and focus!"                      
      ]
    },
    {
      question: 'Did I introduce the text properly (author, title, text type, etc.)?',
      explanation: 'In the first paragraph, make sure you introduce the text by stating the author, title, text type, and any other key information.',
      messages: [
        "Introduction of the text needs major improvement.",          
        "Introduction is missing important details.",                 
        "Introduction is satisfactory but can be improved.",          
        "Good job introducing the text!",                             
        "Excellent introduction of the text!"                         
      ]
    },
    {
      question: 'Did I present the relevant information clearly in the second paragraph?',
      explanation: 'In the second paragraph, outline the main points related to the task, ensuring clarity and relevance of the details.',
      messages: [
        "The main points are unclear or incomplete.",                 
        "More clarity needed in presenting the relevant information.", 
        "Relevant information presented satisfactorily.",             
        "Good clarity in presenting the relevant information!",       
        "Excellent clarity in presenting the main points!"            
      ]
    },
    {
      question: 'Did I use my own words and paraphrase effectively?',
      explanation: 'Ensure you paraphrase the content instead of copying directly from the text. Your outline should use your own words.',
      messages: [
        "Heavy reliance on copying from the text.",                   
        "Needs improvement in paraphrasing.",                        
        "Paraphrasing is satisfactory but could be better.",          
        "Good paraphrasing with your own words!",                     
        "Excellent paraphrasing, all in your own words!"              
      ]
    },
    {
      question: 'Did I follow the correct structure (two paragraphs)?',
      explanation: 'Check whether your outline is structured with two paragraphs: one for the introduction of the text, and the second for addressing the task.',
      messages: [
        "The structure is incorrect, needs major improvement.",       
        "The structure is unclear or incomplete.",                    
        "Structure is satisfactory but could be improved.",           
        "Good structure with two clear paragraphs!",                  
        "Perfect structure with well-separated paragraphs!"           
      ]
    },
    {
      question: 'Did I address the task specifically in Paragraph Two?',
      explanation: 'In the second paragraph, ensure that your content specifically answers the task at hand. Every sentence should relate back to the task’s requirements.',
      messages: [
        "Paragraph Two does not address the task clearly.",             
        "Task is addressed but could be more focused.",                 
        "Task is satisfactorily addressed in Paragraph Two.",           
        "Good focus on the task in Paragraph Two!",                     
        "Excellent, every sentence in Paragraph Two relates to the task!" 
      ]
    },
    {
      question: 'Did I provide an in-depth analysis in Paragraph Two?',
      explanation: 'In Paragraph Two, ensure that you delve deeper into the task’s requirements, providing analysis rather than just description.',
      messages: [
        "Paragraph Two lacks in-depth analysis.",                        
        "More analysis needed in Paragraph Two.",                         
        "Satisfactory level of analysis in Paragraph Two.",               
        "Good depth of analysis in Paragraph Two!",                       
        "Excellent, well-developed analysis in Paragraph Two!"            
      ]
    },
    {
      question: 'Did I link the second paragraph back to the task and introduction?',
      explanation: 'At the end of the second paragraph, make sure you tie your points back to the task and the introduction, summarizing how they connect.',
      messages: [
        "No clear link between Paragraph Two and the task.",              
        "More effort needed to connect Paragraph Two with the task.",     
        "Paragraph Two satisfactorily links back to the task.",           
        "Good connection between Paragraph Two and the task!",            
        "Excellent link between Paragraph Two, the task, and the introduction!" 
      ]
    }
  ],
  de: [
    {
      question: 'Habe ich die Aufgabe verstanden und den richtigen Fokus identifiziert?',
      explanation: 'Stelle sicher, dass Du die Aufgabenstellung und die spezifischen Elemente des Textes, auf die Du Dich konzentrieren musst, klar verstanden hast.',
      messages: [
        "Erhebliche Verbesserung des Aufgabenverständnisses erforderlich.", 
        "Das Verständnis der Aufgabe ist unter den Erwartungen.",           
        "Aufgabenverständnis ist zufriedenstellend.",                       
        "Gutes Verständnis der Aufgabe und des Fokus!",                     
        "Ausgezeichnetes Verständnis der Aufgabe und des Fokus!"            
      ]
    },
    {
      question: 'Habe ich den Text korrekt eingeführt (Autor, Titel, Textart usw.)?',
      explanation: 'Im ersten Absatz solltest Du sicherstellen, dass Du den Text durch Angabe von Autor, Titel, Textart und weiteren wichtigen Informationen einführst.',
      messages: [
        "Die Einführung des Textes muss stark verbessert werden.",          
        "Einführung fehlt wichtige Details.",                               
        "Einführung ist zufriedenstellend, könnte aber verbessert werden.", 
        "Gute Einführung des Textes!",                                      
        "Ausgezeichnete Einführung des Textes!"                             
      ]
    },
    {
      question: 'Habe ich die relevanten Informationen klar im zweiten Absatz dargestellt?',
      explanation: 'Im zweiten Absatz skizziere die Hauptpunkte im Zusammenhang mit der Aufgabe und achte darauf, dass sie klar und relevant sind.',
      messages: [
        "Die Hauptpunkte sind unklar oder unvollständig.",                 
        "Mehr Klarheit bei der Darstellung der relevanten Informationen nötig.", 
        "Relevante Informationen zufriedenstellend dargestellt.",          
        "Gute Klarheit bei der Darstellung der relevanten Informationen!", 
        "Ausgezeichnete Klarheit bei der Darstellung der Hauptpunkte!"     
      ]
    },
    {
      question: 'Habe ich eigene Worte verwendet und effektiv umformuliert?',
      explanation: 'Stelle sicher, dass Du den Inhalt umformulierst, anstatt direkt aus dem Text zu kopieren. Deine Skizze sollte Deine eigenen Worte verwenden.',
      messages: [
        "Starke Abhängigkeit vom Kopieren aus dem Text.",                  
        "Mehr Umformulierung erforderlich.",                               
        "Umformulierung ist zufriedenstellend, könnte aber besser sein.",  
        "Gute Umformulierung mit Deinen eigenen Worten!",                  
        "Ausgezeichnete Umformulierung, alles in Deinen eigenen Worten!"   
      ]
    },
    {
      question: 'Habe ich die richtige Struktur (zwei Absätze) befolgt?',
      explanation: 'Überprüfe, ob Deine Skizze in zwei Absätze unterteilt ist: einen für die Einführung des Textes und einen für die Bearbeitung der Aufgabe.',
      messages: [
        "Die Struktur ist falsch, erhebliche Verbesserung nötig.",          
        "Die Struktur ist unklar oder unvollständig.",                      
        "Struktur ist zufriedenstellend, könnte aber verbessert werden.",   
        "Gute Struktur mit zwei klaren Absätzen!",                          
        "Perfekte Struktur mit gut getrennten Absätzen!"                    
      ]
    },
    {
      question: 'Habe ich die Aufgabe im zweiten Absatz konkret angesprochen?',
      explanation: 'Stelle sicher, dass Dein Inhalt im zweiten Absatz spezifisch die Aufgabe beantwortet. Jeder Satz sollte sich auf die Anforderungen der Aufgabe beziehen.',
      messages: [
        "Der zweite Absatz bezieht sich nicht klar auf die Aufgabe.",        
        "Mehr Fokus auf die Aufgabe im zweiten Absatz nötig.",               
        "Die Aufgabe wird im zweiten Absatz zufriedenstellend behandelt.",  
        "Guter Fokus auf die Aufgabe im zweiten Absatz!",                    
        "Ausgezeichnet, jeder Satz im zweiten Absatz bezieht sich auf die Aufgabe!" 
      ]
    },
    {
      question: 'Habe ich eine tiefgehende Analyse im zweiten Absatz geliefert?',
      explanation: 'Stelle sicher, dass Du im zweiten Absatz tiefer auf die Anforderungen der Aufgabe eingehst und eine Analyse statt nur Beschreibung bietest.',
      messages: [
        "Im zweiten Absatz fehlt eine tiefgehende Analyse.",                
        "Mehr Analyse im zweiten Absatz erforderlich.",                     
        "Zufriedenstellende Analyse im zweiten Absatz.",                    
        "Gute Tiefe der Analyse im zweiten Absatz!",                        
        "Ausgezeichnet, gut entwickelte Analyse im zweiten Absatz!"         
      ]
    },
    {
      question: 'Habe ich den zweiten Absatz mit der Aufgabe und der Einführung verknüpft?',
      explanation: 'Stelle sicher, dass Du am Ende des zweiten Absatzes Deine Punkte auf die Aufgabe und die Einführung zurückführst und zusammenfasst, wie sie zusammenhängen.',
      messages: [
        "Keine klare Verbindung zwischen dem zweiten Absatz und der Aufgabe.", 
        "Mehr Anstrengung nötig, um den zweiten Absatz mit der Aufgabe zu verknüpfen.", 
        "Zufriedenstellende Verbindung zwischen dem zweiten Absatz und der Aufgabe.", 
        "Gute Verbindung zwischen dem zweiten Absatz und der Aufgabe!",        
        "Ausgezeichnete Verknüpfung zwischen dem zweiten Absatz, der Aufgabe und der Einführung!" 
      ]
    }
  ]
},


    sliderCategories: [
      {
        id: 'content',  // Category ID
        categoryTitle: {
          en: 'Content',
          de: 'Inhalt',
        },
        sliders: [
          {
            id: 'content-task-fulfillment',  // Slider ID
            label: {
              en: 'Task Fulfillment',
              de: 'Aufgabenerfüllung',
            },
            messages: {
              en: (value) => {
                if (value >= 0 && value < 20) return "Insufficient selection of aspects, lack of task relevance.";
                if (value >= 20 && value < 40) return "Hardly considering relevant aspects, hardly appropriate.";
                if (value >= 40 && value < 55) return "Considering less relevant aspects, however, correct to some extent.";
                if (value >= 55 && value < 70) return "Taking into account several relevant aspects, essentially correct.";
                if (value >= 70 && value < 85) return "Considering relevant aspects to a large extent, largely correct.";
                if (value >= 85 && value <= 100) return "Comprehensive and correct.";
              },
              de: (value) => {
                if (value >= 0 && value < 20) return "Unzureichende Auswahl von Aspekten, Aufgabenbezug fehlend.";
                if (value >= 20 && value < 40) return "Kaum relevante Aspekte berücksichtigend, kaum angemessen.";
                if (value >= 40 && value < 55) return "Weniger relevante Aspekte berücksichtigend, ansatzweise korrekt.";
                if (value >= 55 && value < 70) return "Mehrere relevante Aspekte berücksichtigend, im Wesentlichen korrekt.";
                if (value >= 70 && value < 85) return "Relevante Aspekte weitgehend berücksichtigend, weitgehend korrekt.";
                if (value >= 85 && value <= 100) return "Umfassend und korrekt.";
              },
            },
          },
          {
            id: 'content-text-handling',
            label: {
              en: 'Text Handling',
              de: 'Textumgang',
            },
            messages: {
              en: (value) => {
                if (value >= 0 && value < 20) return "Not focussed on relevant aspects; where necessary, not abstracting.";
                if (value >= 20 && value < 40) return "Hardly focussed on relevant aspects; where necessary, hardly abstracting.";
                if (value >= 40 && value < 55) return "To some extent focussed on relevant aspects; where necessary, rudimentarily abstracting.";
                if (value >= 55 && value < 70) return "Essentially focussed on relevant aspects; where necessary, mainly abstracting.";
                if (value >= 70 && value < 85) return "Largely focussed on relevant aspects; where necessary, largely abstracting.";
                if (value >= 85 && value <= 100) return "Consistently focussed on relevant aspects; where necessary, highly abstracting.";                
              },
              de: (value) => {
                if (value >= 0 && value < 20) return "Nicht auf relevante Aspekte fokussiert; ggf. nicht abstrahierend.";
                if (value >= 20 && value < 40) return "Kaum auf relevante Aspekte fokussiert; ggf. kaum abstrahierend.";
                if (value >= 40 && value < 55) return "Ansatzweise auf relevante Aspekte fokussiert; ggf. ansatzweise abstrahierend.";
                if (value >= 55 && value < 70) return "Im Wesentlichen auf relevante Aspekte fokussiert; ggf. im Wesentlichen abstrahierend.";
                if (value >= 70 && value < 85) return "Weitgehend auf relevante Aspekte fokussiert; ggf. weitgehend abstrahierend.";
                if (value >= 85 && value <= 100) return "Durchgängig auf relevante Aspekte fokussiert; ggf. in hohem Maße abstrahierend.";
              },
            },
          },
          {
            id: 'content-content-structure',
            label: {
              en: 'Content Structure',
              de: 'Inhaltliche Struktur',
            },
            messages: {
              en: (value) => {
                if (value >= 0 && value < 20) return "Unstructured, redundant, incomprehensible, incoherent.";
                if (value >= 20 && value < 40) return "Hardly structured, often redundant, hardly comprehensible or coherent.";
                if (value >= 40 && value < 55) return "Structured to some extent, repeatedly redundant, coherent to some extent.";
                if (value >= 55 && value < 70) return "Essentially structured, redundancy-free, and coherent.";
                if (value >= 70 && value < 85) return "Structured, largely redundancy-free, and largely coherent.";
                if (value >= 85 && value <= 100) return "Clearly structured, redundancy-free, highly coherent.";
              },
              de: (value) => {
                if (value >= 0 && value < 20) return "Unstrukturiert, redundant, nicht nachvollziehbar, inkohärent.";
                if (value >= 20 && value < 40) return "Kaum strukturiert, oft redundant, kaum nachvollziehbar.";
                if (value >= 40 && value < 55) return "Ansatzweise strukturiert, wiederholt redundant, ansatzweise kohärent.";
                if (value >= 55 && value < 70) return "Im Wesentlichen strukturiert, redundanzfrei und kohärent.";
                if (value >= 70 && value < 85) return "Strukturiert, weitgehend redundanzfrei und weitgehend kohärent.";
                if (value >= 85 && value <= 100) return "Klar strukturiert, redundanzfrei, in hohem Maße kohärent.";
              },
            },
          },
 
        ],
      },
      {
        id: 'language', // Category ID
        categoryTitle: {
          en: 'Language',
          de: 'Sprache',
        },
        sliders: [
          {
            id: 'language-lexical-range',
            label: {
              en: 'Lexical Range',
              de: 'Lexikalische Bandbreite',
            },
            messages: {
              en: (value) => {
                if (value >= 0 && value < 20) return "Not an appropriate choice of words.";
                if (value >= 20 && value < 40) return "Clearly limited choice of words.";
                if (value >= 40 && value < 55) return "Restricted, still appropriate choice of words.";
                if (value >= 55 && value < 70) return "Essentially precise and variable choice of words.";
                if (value >= 70 && value < 85) return "Largely precise, variable, with some target-language expressions.";
                if (value >= 85 && value <= 100) return "Highly precise and variable choice of words.";
              },
              de: (value) => {
                if (value >= 0 && value < 20) return "Keine angemessene Wortwahl.";
                if (value >= 20 && value < 40) return "Deutlich eingeschränkte Wortwahl.";
                if (value >= 40 && value < 55) return "Eingeschränkte, noch angemessene Wortwahl.";
                if (value >= 55 && value < 70) return "Im Wesentlichen präzise und variable Wortwahl.";
                if (value >= 70 && value < 85) return "Weitgehend präzise und variable Wortwahl.";
                if (value >= 85 && value <= 100) return "In hohem Maße präzise und variable Wortwahl.";
              },
            },
          },
          {
            id: 'language-lexical-accuracy',
            label: {
              en: 'Lexical Accuracy',
              de: 'Lexikalische Korrektheit',
            },
            messages: {
              en: (value) => {
                if (value >= 0 && value < 20) return "Lexically incorrect; no longer comprehensible.";
                if (value >= 20 && value < 40) return "Often lexically incorrect; comprehensibility severely impaired.";
                if (value >= 40 && value < 55) return "Partially lexically incorrect; comprehensibility impaired.";
                if (value >= 55 && value < 70) return "Essentially lexically correct; slightly limited comprehensibility.";
                if (value >= 70 && value < 85) return "Largely lexically correct; comprehensibility not impaired by individual inaccuracies.";
                if (value >= 85 && value <= 100) return "Highly lexically correct; comprehensible throughout.";
              },
              de: (value) => {
                if (value >= 0 && value < 20) return "Lexikalisch unkorrekt; Verständlichkeit nicht gegeben.";
                if (value >= 20 && value < 40) return "Oft lexikalisch unkorrekt; Verständlichkeit stark beeinträchtigt.";
                if (value >= 40 && value < 55) return "Teilweise lexikalisch unkorrekt; Verständlichkeit eingeschränkt.";
                if (value >= 55 && value < 70) return "Im Wesentlichen lexikalisch korrekt; Verständlichkeit geringfügig beeinträchtigt.";
                if (value >= 70 && value < 85) return "Weitgehend lexikalisch korrekt; Verständlichkeit nicht beeinträchtigt.";
                if (value >= 85 && value <= 100) return "In hohem Maße lexikalisch korrekt; durchgängig verständlich.";
              },
            },
          },
          {
            id: 'language-grammatical-range',
            label: {
              en: 'Grammatical Range',
              de: 'Grammatische Bandbreite',
            },
            messages: {
              en: (value) => {
                if (value >= 0 && value < 20) return "Sentence structure and structures inadequate.";
                if (value >= 20 && value < 40) return "Sentence structure and structures barely adequate.";
                if (value >= 40 && value < 55) return "Less variable sentence structure; still appropriate structures.";
                if (value >= 55 && value < 70) return "Essentially variable sentence structure.";
                if (value >= 70 && value < 85) return "Largely variable sentence structure.";
                if (value >= 85 && value <= 100) return "Highly variable sentence structure.";
              },
              de: (value) => {
                if (value >= 0 && value < 20) return "Satzbau unzulänglich.";
                if (value >= 20 && value < 40) return "Satzbau kaum angemessen.";
                if (value >= 40 && value < 55) return "Wenig variabler Satzbau; noch angemessene Strukturen.";
                if (value >= 55 && value < 70) return "Im Wesentlichen variabler Satzbau.";
                if (value >= 70 && value < 85) return "Weitgehend variabler Satzbau.";
                if (value >= 85 && value <= 100) return "In hohem Maße variabler Satzbau.";
              },
            },
          },
        ]

      },
    ],
  },
  {
    id: 2,
    title: {
      en: 'Analysing Charts & Graphs',
      de: 'Statistiken analysieren',
    },
    shorttitle: {
      en: 'Chart & Graph Analysis',
      de: 'Statistikanalyse',
    },
    content: {
      en: 'Analysis',
      de: 'Analyse',
    },
    categories: [
      {
        title: {
          en: 'Basics',
          de: 'Grundlagen',
        },
        description: {
          en: 'Fundamental information on how to start.',
          de: 'Grundlegende Informationen zum Einstieg.',
        },
        details: {
          en: [
            <>
              <p>
                Statistics is a mathematical discipline that collects, organizes, analyses, interprets and presents data. The word itself comes from New Latin “statistica” or “statisticus”, meaning either “of the state” or “statesman”.
              </p>
              <p>
                Statistical data are presented in many different forms. In here, we will focus on four distinct forms:
              </p>
              <ol>
                <li>
                  Line graphs
                  <img src={LineGraph} className="illustrateSVG" alt="Line Graph Illustration" />
                </li>
                <li>
                  Bar charts
                  <img src={BarChart} className="illustrateSVG" alt="Bar Chart Illustration" />
                </li>
                <li>
                  Pie charts
                  <img src={PieChart} className="illustrateSVG" alt="Pie Chart Illustration" />
                </li>
                <li>
                  Tables
                  <img src={Table} className="illustrateSVG" alt="Table Illustration" />
                </li>
              </ol>
              <p>
                Analysing statistics implies that you read the visual data well, have an understanding of the material and the context in which the data is situated.
              </p>
            </>
          ],
          de: [
            <>
              <p>
                Statistik ist eine mathematische Disziplin, die Daten sammelt, organisiert, analysiert, interpretiert und präsentiert. Das Wort "Statistik" kommt aus dem Lateinischen und bedeutet "des Zustandes" oder "Staatsmann".
              </p>
              <p>
                Statistische Daten werden in vielen verschiedenen Formen präsentiert. Hier werden wir uns auf vier Formen konzentrieren:
              </p>
              <ol>
                <li>Liniendiagramme,</li>
                <li>Säulendiagramme,</li>
                <li>Tortendiagramme und</li>
                <li>Tabellen.</li>
              </ol>
            </>
          ],
        },
      },
      {
        title: {
          en: 'Tables',
          de: 'Tabellen',
        },
        description: {
          en: 'Facts in lines and columns.',
          de: 'Fakten in Zeilen und Spalten.',
        },
        details: {
          en: [
            <>
              <h3>Preparation</h3>
              <p>When analysing tables, you can follow these steps:</p>
              <ol>
                <li><strong>Identify the Topic:</strong> Determine what the table is about. This could be about population data, economic indicators, or survey results, for example. The title helps you a lot; mention it as well in your analysis.</li>
                <li><strong>Examine the Presentation Style:</strong> Look at how the data is presented. Check if it's organized by categories, time periods, or regions.</li>
                <li><strong>Check the Source Credibility:</strong> Identify the source of the data. Is it from a reliable organization, such as a government agency or a reputable research institution?</li>
                <li><strong>Note the Publication Date:</strong> Determine when the data was published. This can help assess its relevance and whether it reflects current conditions.</li>
                <li><strong>Identify the Period Covered:</strong> Check the timeframe the data represents. Is it yearly, quarterly, or over several years?</li>
                <li><strong>Look at Percentages or Absolute Numbers:</strong> Assess whether the data is expressed in percentages (for comparison) or in absolute numbers (raw data).</li>
              </ol>
              <p>Summarize all these steps then in a first, coherent paragraph.</p>
              <h3>The Analysis</h3>
              <p>Once the table has been thoroughly analyzed, the next steps in writing an analysis about tables can be structured as follows:</p>
              <ol>
                <ol>
                  <li><strong>Summarize Key Insights:</strong>
                    <ul>
                      <li>Provide a clear and concise summary of the most important data points. Focus on trends, patterns, or significant outliers in the data.</li>
                      <li>Highlight the major findings that stand out. For example, is there a significant increase or decrease in a specific category?</li>
                    </ul>
                  </li>
                  <li><strong>Explain the Data in Context:</strong>
                    <ul>
                      <li>Place the data in the appropriate context. Explain why the information is relevant or significant based on the subject matter.</li>
                      <li>Mention how external factors (e.g., economic conditions, social factors) might influence the data presented.</li>
                    </ul>
                  </li>
                  <li><strong>Compare Data:</strong>
                    <ul>
                      <li>Compare different rows or columns within the table if applicable. Identify relationships or contrasts between categories, time periods, or regions.</li>
                      <li>If available, compare the table’s data with external data sources to provide a broader perspective.</li>
                    </ul>
                  </li>
                  <li><strong>Interpret Patterns or Trends:</strong>
                    <ul>
                      <li>Discuss any patterns or trends observed in the table. For example, if the data shows consistent growth or decline over time, explain the implications.</li>
                      <li>Use the data to make inferences or predictions, if relevant. For example, based on past trends, what might happen in the future?</li>
                    </ul>
                  </li>
                  <li><strong>Draw Conclusions:</strong>
                    <ul>
                      <li>Conclude by stating the overall significance of the data. How does the information help to answer a specific question or provide insight into a topic?</li>
                      <li>Mention any potential limitations of the data. This could involve discussing how up-to-date the data is, or any missing information that would be helpful for a more complete analysis.</li>
                    </ul>
                  </li>
                </ol>
              </ol>
              <p>Remember to always base your analysis on the data mentioned in the table, and to address the source reliability and accuracy if you notice any discrepancies or potential biases.</p>
              <h3>Glossary of Common Terms</h3>
              <p>Understanding the terminology used in tables is essential for accurate analysis. Below, you'll find a glossary of common terms that you are likely to encounter:</p>
              <ul>
                <li><strong>Percentile:</strong> A value below which a given percentage of observations in a group fall. For example, the 90th percentile is the value below which 90% of the data points fall.</li>
                <li><strong>Average (Mean):</strong> The sum of all values divided by the number of values. This gives you a central point for comparison.</li>
                <li><strong>Median:</strong> The middle value when the numbers are arranged in order. It separates the higher half from the lower half of the data.</li>
                <li><strong>Growth Rate:</strong> The rate at which a value increases over time, often expressed as a percentage.</li>
                <li><strong>Outlier:</strong> A data point that is significantly different from the others in a set, often indicating an anomaly or exception.</li>
              </ul>
              <p>Knowing these terms will help you better interpret and analyze tables in English.</p>
              <h3>Helpful phrases</h3>
              <details>
                <summary><strong>1. Introduction and Overview</strong></summary>
                <ul>
                  <li>The table shows/illustrates/presents…</li>
                  <li>This table provides an overview of…</li>
                  <li>According to the data…</li>
                  <li>The figures in the table represent…</li>
                  <li>The table covers the period from [year] to [year] and includes data on…</li>
                  <li>The data is presented in percentages/absolute numbers…</li>
                  <li>This table compares/contrasts…</li>
                  <li>At first glance, the table suggests that…</li>
                </ul>
              </details>

              <details>
                <summary><strong>2. Describing Trends and Patterns</strong></summary>
                <ul>
                  <li>There is a noticeable increase/decrease in…</li>
                  <li>The data shows a steady rise/fall in…</li>
                  <li>Over the period of [years], the trend indicates…</li>
                  <li>A significant change can be observed in…</li>
                  <li>The numbers have remained stable/constant over the years.</li>
                  <li>The trend fluctuates throughout the given period…</li>
                  <li>There is a sharp/spike in [year], where…</li>
                  <li>The data peaks in [year] at…</li>
                  <li>A gradual upward/downward trend is visible…</li>
                </ul>
              </details>

              <details>
                <summary><strong>3. Making Comparisons</strong></summary>
                <ul>
                  <li>Compared to [category 1], [category 2] shows…</li>
                  <li>In contrast to…</li>
                  <li>While [A] experienced a rise, [B] saw a decline.</li>
                  <li>[Country/Region] has the highest/lowest…</li>
                  <li>The figures for [A] are higher/lower than those for [B].</li>
                  <li>In comparison with the previous year…</li>
                  <li>The data for [category] is significantly higher/lower than…</li>
                  <li>A notable difference can be observed between…</li>
                </ul>
              </details>

              <details>
                <summary><strong>4. Drawing Conclusions</strong></summary>
                <ul>
                  <li>In conclusion, the data suggests that…</li>
                  <li>Overall, the table highlights…</li>
                  <li>Based on the data, it can be inferred that…</li>
                  <li>The figures indicate a clear trend of…</li>
                  <li>This leads to the conclusion that…</li>
                  <li>It is evident from the data that…</li>
                  <li>The analysis points to the fact that…</li>
                  <li>As a result, it is clear that…</li>
                  <li>The overall pattern reveals that…</li>
                </ul>
              </details>

              <details>
                <summary><strong>5. Explaining Data Significance</strong></summary>
                <ul>
                  <li>This data highlights the importance of…</li>
                  <li>The figures reflect the impact of…</li>
                  <li>The results emphasize the need for…</li>
                  <li>This pattern suggests that there are underlying factors such as…</li>
                  <li>The significance of this data lies in…</li>
                  <li>These numbers show the potential for…</li>
                  <li>This trend might be attributed to…</li>
                  <li>This could be explained by factors such as…</li>
                  <li>One possible explanation for this is…</li>
                </ul>
              </details>

              <details>
                <summary><strong>6. Addressing Unusual Data or Outliers</strong></summary>
                <ul>
                  <li>A noticeable outlier is…</li>
                  <li>One anomaly in the data is…</li>
                  <li>This unusual figure can be attributed to…</li>
                  <li>The spike/drop in [year] is likely due to…</li>
                  <li>A possible explanation for this outlier could be…</li>
                  <li>This data point deviates significantly from the overall trend.</li>
                </ul>
              </details>

            </>
          ],
          de: [
            <>
              <h3>Vorbereitung</h3>
              <p>Beim Analysieren von Tabellen kannst Du diesen Schritten folgen:</p>
              <ol>
                <li><strong>Thema der Tabelle identifizieren:</strong> Bestimme, worum es in der Tabelle geht. Dies könnte beispielsweise Bevölkerungsdaten, wirtschaftliche Indikatoren oder Umfrageergebnisse umfassen. Der Titel hilft Dir oft weiter; erwähne ihn auch in Deiner Analyse.</li>
                <li><strong>Darstellungsart prüfen:</strong> Schaue, wie die Daten präsentiert werden. Sind sie nach Kategorien, Zeiträumen oder Regionen organisiert?</li>
                <li><strong>Glaubwürdigkeit der Quelle überprüfen:</strong> Identifiziere die Quelle der Daten. Stammt sie von einer zuverlässigen Organisation, wie einer Regierungsbehörde oder einer renommierten Forschungseinrichtung?</li>
                <li><strong>Veröffentlichungsdatum beachten:</strong> Finde heraus, wann die Daten veröffentlicht wurden. Dies kann helfen, ihre Relevanz und Aktualität einzuschätzen.</li>
                <li><strong>Zeitraum identifizieren:</strong> Überprüfe, welchen Zeitraum die Daten abdecken. Handelt es sich um jährliche, vierteljährliche oder über mehrere Jahre hinweg erhobene Daten?</li>
                <li><strong>Prozentzahlen oder absolute Werte betrachten:</strong> Prüfe, ob die Daten in Prozentangaben (zum Vergleich) oder in absoluten Zahlen (Rohdaten) dargestellt sind.</li>
              </ol>
              <p>Fasse alle diese Schritte dann in einem ersten, zusammenhängenden Absatz zusammen.</p>

              <h3>Die Analyse</h3>
              <p>Nachdem die Tabelle gründlich analysiert wurde, kannst Du die nächsten Schritte wie folgt strukturieren:</p>
              <ol>
                <li><strong>Schlüsseleinsichten zusammenfassen:</strong>
                  <ul>
                    <li>Gib eine klare und prägnante Zusammenfassung der wichtigsten Datenpunkte. Konzentriere Dich auf Trends, Muster oder signifikante Ausreißer in den Daten.</li>
                    <li>Hebe die wichtigsten Erkenntnisse hervor. Gibt es beispielsweise einen signifikanten Anstieg oder Rückgang in einer bestimmten Kategorie?</li>
                  </ul>
                </li>
                <li><strong>Die Daten im Kontext erklären:</strong>
                  <ul>
                    <li>Setze die Daten in den entsprechenden Kontext. Erkläre, warum die Informationen relevant oder bedeutend sind, basierend auf dem Thema.</li>
                    <li>Erwähne, wie externe Faktoren (z. B. wirtschaftliche Bedingungen, soziale Faktoren) die präsentierten Daten beeinflussen könnten.</li>
                  </ul>
                </li>
                <li><strong>Daten vergleichen:</strong>
                  <ul>
                    <li>Vergleiche verschiedene Zeilen oder Spalten innerhalb der Tabelle, falls zutreffend. Identifiziere Beziehungen oder Kontraste zwischen Kategorien, Zeiträumen oder Regionen.</li>
                    <li>Wenn möglich, vergleiche die Daten der Tabelle mit externen Datenquellen, um eine breitere Perspektive zu bieten.</li>
                  </ul>
                </li>
                <li><strong>Muster oder Trends interpretieren:</strong>
                  <ul>
                    <li>Diskutiere Muster oder Trends, die in der Tabelle erkennbar sind. Wenn die Daten beispielsweise ein konstantes Wachstum oder einen kontinuierlichen Rückgang über einen Zeitraum zeigen, erkläre die Implikationen.</li>
                    <li>Nutze die Daten, um Schlussfolgerungen oder Vorhersagen zu treffen, wenn relevant. Was könnte beispielsweise auf Grundlage vergangener Trends in der Zukunft passieren?</li>
                  </ul>
                </li>
                <li><strong>Schlussfolgerungen ziehen:</strong>
                  <ul>
                    <li>Fasse zusammen, welche Bedeutung die Daten insgesamt haben. Wie helfen die Informationen, eine spezifische Frage zu beantworten oder Einblicke in ein Thema zu geben?</li>
                    <li>Erwähne eventuelle Einschränkungen der Daten. Das könnte bedeuten, zu diskutieren, wie aktuell die Daten sind oder welche Informationen fehlen, um eine vollständigere Analyse zu ermöglichen.</li>
                  </ul>
                </li>
              </ol>

              <p>Denke daran, dass Du Deine Analyse immer auf die in der Tabelle genannten Daten stützen und die Zuverlässigkeit und Genauigkeit der Quelle ansprechen solltest, falls Du Unstimmigkeiten oder mögliche Vorurteile bemerkst.</p>

              <h3>Glossar der wichtigsten Begriffe</h3>
              <p>Das Verständnis der in Tabellen verwendeten Terminologie ist entscheidend für eine genaue Analyse. Im Folgenden findest Du ein Glossar mit häufig vorkommenden Begriffen:</p>
              <ul>
                <li><strong>Percentile | <em>Perzentil</em>:</strong> Ein Wert, unter dem ein bestimmter Prozentsatz der Beobachtungen einer Gruppe liegt. Zum Beispiel liegt der 90. Perzentilwert unter 90 % der Datenpunkte.</li>
                <li><strong>Average (Mean) | <em>Durchschnitt (Mittelwert)</em>:</strong> Die Summe aller Werte, geteilt durch die Anzahl der Werte. Dies ergibt einen zentralen Vergleichswert.</li>
                <li><strong>Median | <em>Median</em>:</strong> Der mittlere Wert, wenn die Zahlen geordnet sind. Er trennt die obere von der unteren Hälfte der Daten.</li>
                <li><strong>Growth rate | <em>Wachstumsrate:</em></strong> Die Rate, mit der ein Wert im Laufe der Zeit steigt, häufig als Prozentsatz angegeben.</li>
                <li><strong>Outlier | <em>Ausreißer</em>:</strong> Ein Datenpunkt, der sich signifikant von den anderen in einer Menge unterscheidet, was oft auf eine Anomalie oder Ausnahme hinweist.</li>
              </ul>
              <p>Das Verständnis dieser Begriffe wird Dir helfen, Tabellen in Englisch besser zu interpretieren und zu analysieren.</p>

              <h3>Nützliche Formulierungen</h3>
              <details>
                <summary><strong>1. Einführung und Überblick</strong></summary>
                <ul>
                  <li>The table shows/illustrates/presents…</li>
                  <li>This table provides an overview of…</li>
                  <li>According to the data…</li>
                  <li>The figures in the table represent…</li>
                  <li>The table covers the period from [year] to [year] and includes data on…</li>
                  <li>The data is presented in percentages/absolute numbers…</li>
                  <li>This table compares/contrasts…</li>
                  <li>At first glance, the table suggests that…</li>
                </ul>
              </details>

              <details>
                <summary><strong>2. Beschreibung von Trends und Mustern</strong></summary>
                <ul>
                  <li>There is a noticeable increase/decrease in…</li>
                  <li>The data shows a steady rise/fall in…</li>
                  <li>Over the period of [years], the trend indicates…</li>
                  <li>A significant change can be observed in…</li>
                  <li>The numbers have remained stable/constant over the years.</li>
                  <li>The trend fluctuates throughout the given period…</li>
                  <li>There is a sharp/spike in [year], where…</li>
                  <li>The data peaks in [year] at…</li>
                  <li>A gradual upward/downward trend is visible…</li>
                </ul>
              </details>

              <details>
                <summary><strong>3. Vergleiche anstellen</strong></summary>
                <ul>
                  <li>Compared to [category 1], [category 2] shows…</li>
                  <li>In contrast to…</li>
                  <li>While [A] experienced a rise, [B] saw a decline.</li>
                  <li>[Country/Region] has the highest/lowest…</li>
                  <li>The figures for [A] are higher/lower than those for [B].</li>
                  <li>In comparison with the previous year…</li>
                  <li>The data for [category] is significantly higher/lower than…</li>
                  <li>A notable difference can be observed between…</li>
                </ul>
              </details>

              <details>
                <summary><strong>4. Schlussfolgerungen ziehen</strong></summary>
                <ul>
                  <li>In conclusion, the data suggests that…</li>
                  <li>Overall, the table highlights…</li>
                  <li>Based on the data, it can be inferred that…</li>
                  <li>The figures indicate a clear trend of…</li>
                  <li>This leads to the conclusion that…</li>
                  <li>It is evident from the data that…</li>
                  <li>The analysis points to the fact that…</li>
                  <li>As a result, it is clear that…</li>
                  <li>The overall pattern reveals that…</li>
                </ul>
              </details>

              <details>
                <summary><strong>5. Bedeutung der Daten erklären</strong></summary>
                <ul>
                  <li>This data highlights the importance of…</li>
                  <li>The figures reflect the impact of…</li>
                  <li>The results emphasize the need for…</li>
                  <li>This pattern suggests that there are underlying factors such as…</li>
                  <li>The significance of this data lies in…</li>
                  <li>These numbers show the potential for…</li>
                  <li>This trend might be attributed to…</li>
                  <li>This could be explained by factors such as…</li>
                  <li>One possible explanation for this is…</li>
                </ul>
              </details>

              <details>
                <summary><strong>6. Ungewöhnliche Daten oder Ausreißer ansprechen</strong></summary>
                <ul>
                  <li>A noticeable outlier is…</li>
                  <li>One anomaly in the data is…</li>
                  <li>This unusual figure can be attributed to…</li>
                  <li>The spike/drop in [year] is likely due to…</li>
                  <li>A possible explanation for this outlier could be…</li>
                  <li>This data point deviates significantly from the overall trend.</li>
                </ul>
              </details>

            </>
          ]

        },
      },
      {
        title: {
          en: 'Line Graphs',
          de: 'Liniendiagramme',
        },
        description: {
          en: 'Lines connecting individual data points.',
          de: 'Linien verbinden einzelne Datenpunkte.',
        },
        details: {
          en: [
            <>
              <h3>Preparation</h3>
              <p>When analyzing line graphs, you can follow these steps:</p>
              <ol>
                <li><strong>Identify the Topic:</strong> Determine what the line graph is about. It could be tracking changes over time, showing trends in population, sales, or other metrics.</li>
                <li><strong>Examine the Axes:</strong> Look at both the x-axis and y-axis. The x-axis typically represents time, while the y-axis shows the variable being measured.</li>
                <li><strong>Note Key Points:</strong> Identify any significant peaks, troughs, or trends. Where does the line rise or fall sharply?</li>
                <li><strong>Check for Multiple Lines:</strong> If there is more than one line, compare them. How do the trends differ between the variables represented by different lines?</li>
                <li><strong>Look at the Data Range:</strong> Assess the scale of the graph. What is the highest and lowest point on the y-axis? What is the time range covered?</li>
                <li><strong>Consider External Factors:</strong> Think about factors that could explain the changes in the graph. Could specific events, policies, or external conditions affect the data trends?</li>
              </ol>
              <p>Summarize these observations in a coherent first paragraph.</p>

              <h3>The Analysis</h3>
              <p>Once you have thoroughly reviewed the line graph, structure your analysis as follows:</p>
              <ol>
                <li><strong>Summarize Key Insights:</strong>
                  <ul>
                    <li>Provide a concise summary of the key trends, such as sharp increases, steady declines, or periods of stability.</li>
                    <li>Highlight the most significant changes that are easily noticeable in the graph.</li>
                  </ul>
                </li>
                <li><strong>Explain the Trends in Context:</strong>
                  <ul>
                    <li>Relate the trends to the context. Why are they happening? Consider economic, social, or environmental factors that may explain the movements.</li>
                  </ul>
                </li>
                <li><strong>Compare Data:</strong>
                  <ul>
                    <li>Compare different lines if applicable. How do they interact? Is there one line consistently higher than the others?</li>
                  </ul>
                </li>
                <li><strong>Interpret Patterns:</strong>
                  <ul>
                    <li>Identify any cyclical patterns or long-term trends. For instance, does the graph show recurring peaks at the same time each year?</li>
                    <li>Use the data to make predictions based on past trends.</li>
                  </ul>
                </li>
                <li><strong>Draw Conclusions:</strong>
                  <ul>
                    <li>Sum up the overall message of the line graph. What do the trends indicate about the topic being analyzed?</li>
                    <li>Mention any limitations, such as incomplete data or a narrow time range that might affect the interpretation.</li>
                  </ul>
                </li>
              </ol>

              <p>Remember to base your analysis on the visual data presented and consider any external influences that might explain the observed trends.</p>

              <h3>Glossary of Common Terms</h3>
              <p>Understanding the terminology used in line graphs is essential for accurate analysis. Here are some key terms:</p>
              <ul>
                <li><strong>Trend Line:</strong> A line that shows the overall direction of data points across time.</li>
                <li><strong>Peak:</strong> The highest point on the graph, indicating the maximum value within a given time period.</li>
                <li><strong>Trough:</strong> The lowest point on the graph, indicating a minimum value.</li>
                <li><strong>Fluctuation:</strong> A series of ups and downs in the graph, often indicating instability or variability in the data.</li>
                <li><strong>Exponential Growth:</strong> A rapid increase in the data, where the rate of growth itself continues to grow over time.</li>
              </ul>

              <p>Familiarizing yourself with these terms will help you interpret and analyze line graphs more effectively.</p>

              <h3>Helpful phrases</h3>
              <details>
                <summary><strong>1. Introduction and Overview</strong></summary>
                <ul>
                  <li>The graph shows/illustrates/presents…</li>
                  <li>This graph provides an overview of…</li>
                  <li>According to the data…</li>
                  <li>The line represents…</li>
                  <li>The graph covers the period from [year] to [year] and includes data on…</li>
                  <li>The data is presented in percentages/absolute numbers…</li>
                  <li>This line compares/contrasts…</li>
                  <li>At first glance, the graph suggests that…</li>
                </ul>
              </details>

              <details>
                <summary><strong>2. Describing Trends and Patterns</strong></summary>
                <ul>
                  <li>There is a noticeable increase/decrease in…</li>
                  <li>The data shows a steady rise/fall in…</li>
                  <li>Over the period of [years], the trend indicates…</li>
                  <li>A significant change can be observed in…</li>
                  <li>The numbers have remained stable/constant over the years.</li>
                  <li>The trend fluctuates throughout the given period…</li>
                  <li>There is a sharp spike in [year], where…</li>
                  <li>The data peaks in [year] at…</li>
                  <li>A gradual upward/downward trend is visible…</li>
                </ul>
              </details>

              <details>
                <summary><strong>3. Making Comparisons</strong></summary>
                <ul>
                  <li>Compared to [category 1], [category 2] shows…</li>
                  <li>In contrast to…</li>
                  <li>While [A] experienced a rise, [B] saw a decline.</li>
                  <li>[Category/Region] has the highest/lowest…</li>
                  <li>The figures for [A] are higher/lower than those for [B].</li>
                  <li>In comparison with the previous year…</li>
                  <li>The data for [category] is significantly higher/lower than…</li>
                  <li>A notable difference can be observed between…</li>
                </ul>
              </details>

              <details>
                <summary><strong>4. Drawing Conclusions</strong></summary>
                <ul>
                  <li>In conclusion, the data suggests that…</li>
                  <li>Overall, the graph highlights…</li>
                  <li>Based on the data, it can be inferred that…</li>
                  <li>The figures indicate a clear trend of…</li>
                  <li>This leads to the conclusion that…</li>
                  <li>It is evident from the data that…</li>
                  <li>The analysis points to the fact that…</li>
                  <li>As a result, it is clear that…</li>
                  <li>The overall pattern reveals that…</li>
                </ul>
              </details>

              <details>
                <summary><strong>5. Explaining Data Significance</strong></summary>
                <ul>
                  <li>This data highlights the importance of…</li>
                  <li>The figures reflect the impact of…</li>
                  <li>The results emphasize the need for…</li>
                  <li>This pattern suggests that there are underlying factors such as…</li>
                  <li>The significance of this data lies in…</li>
                  <li>These numbers show the potential for…</li>
                  <li>This trend might be attributed to…</li>
                  <li>This could be explained by factors such as…</li>
                  <li>One possible explanation for this is…</li>
                </ul>
              </details>

              <details>
                <summary><strong>6. Addressing Unusual Data or Outliers</strong></summary>
                <ul>
                  <li>A noticeable outlier is…</li>
                  <li>One anomaly in the data is…</li>
                  <li>This unusual figure can be attributed to…</li>
                  <li>The spike/drop in [year] is likely due to…</li>
                  <li>A possible explanation for this outlier could be…</li>
                  <li>This data point deviates significantly from the overall trend.</li>
                </ul>
              </details>

            </>
          ],
          de: [
            <>
              <h3>Vorbereitung</h3>
              <p>Beim Analysieren von Liniendiagrammen kannst Du diesen Schritten folgen:</p>
              <ol>
                <li><strong>Thema des Diagramms identifizieren:</strong> Bestimme, worum es in dem Liniendiagramm geht. Es könnte Veränderungen im Laufe der Zeit verfolgen oder Trends in der Bevölkerung, dem Verkauf oder anderen Metriken darstellen.</li>
                <li><strong>Achsen untersuchen:</strong> Schaue sowohl auf die x-Achse als auch auf die y-Achse. Die x-Achse repräsentiert typischerweise die Zeit, während die y-Achse die gemessene Variable anzeigt.</li>
                <li><strong>Wichtige Punkte beachten:</strong> Identifiziere signifikante Spitzen, Täler oder Trends. Wo steigt oder fällt die Linie stark?</li>
                <li><strong>Mehrere Linien vergleichen:</strong> Wenn es mehr als eine Linie gibt, vergleiche sie. Wie unterscheiden sich die Trends zwischen den verschiedenen Linien?</li>
                <li><strong>Datenbereich betrachten:</strong> Prüfe die Skalierung des Diagramms. Was ist der höchste und der niedrigste Punkt auf der y-Achse? Welcher Zeitraum wird abgedeckt?</li>
                <li><strong>Externe Faktoren berücksichtigen:</strong> Denke über Faktoren nach, die die Veränderungen im Diagramm erklären könnten. Könnten bestimmte Ereignisse, politische Maßnahmen oder externe Bedingungen die Datentrends beeinflussen?</li>
              </ol>
              <p>Fasse diese Beobachtungen in einem zusammenhängenden ersten Absatz zusammen.</p>

              <h3>Die Analyse</h3>
              <p>Nachdem das Liniendiagramm gründlich geprüft wurde, kannst Du Deine Analyse wie folgt strukturieren:</p>
              <ol>
                <li><strong>Wichtige Erkenntnisse zusammenfassen:</strong>
                  <ul>
                    <li>Gib eine prägnante Zusammenfassung der wichtigsten Trends, wie z. B. starke Anstiege, stetige Rückgänge oder Perioden der Stabilität.</li>
                    <li>Hebe die bedeutendsten Veränderungen hervor, die im Diagramm leicht erkennbar sind.</li>
                  </ul>
                </li>
                <li><strong>Trends im Kontext erklären:</strong>
                  <ul>
                    <li>Setze die Trends in den Kontext. Warum passieren sie? Berücksichtige wirtschaftliche, soziale oder umweltbedingte Faktoren, die die Bewegungen erklären könnten.</li>
                  </ul>
                </li>
                <li><strong>Daten vergleichen:</strong>
                  <ul>
                    <li>Vergleiche, wenn zutreffend, verschiedene Linien. Wie interagieren sie? Gibt es eine Linie, die konstant höher liegt als die anderen?</li>
                  </ul>
                </li>
                <li><strong>Muster interpretieren:</strong>
                  <ul>
                    <li>Identifiziere zyklische Muster oder langfristige Trends. Zeigt das Diagramm beispielsweise wiederkehrende Spitzen zur gleichen Zeit jedes Jahr?</li>
                    <li>Nutze die Daten, um auf Grundlage vergangener Trends Vorhersagen zu treffen.</li>
                  </ul>
                </li>
                <li><strong>Schlussfolgerungen ziehen:</strong>
                  <ul>
                    <li>Fasse die allgemeine Aussage des Liniendiagramms zusammen. Was sagen die Trends über das analysierte Thema aus?</li>
                    <li>Erwähne mögliche Einschränkungen, wie z. B. unvollständige Daten oder einen begrenzten Zeitraum, die die Interpretation beeinflussen könnten.</li>
                  </ul>
                </li>
              </ol>

              <p>Denke daran, Deine Analyse auf den visuellen Daten zu stützen und externe Einflüsse zu berücksichtigen, die die beobachteten Trends erklären könnten.</p>

              <h3>Glossar der wichtigsten Begriffe</h3>
              <p>Das Verständnis der in Liniendiagrammen verwendeten Terminologie ist entscheidend für eine genaue Analyse. Hier sind einige wichtige Begriffe:</p>
              <ul>
                <li><strong>Trendlinie:</strong> Eine Linie, die die allgemeine Richtung der Datenpunkte im Laufe der Zeit zeigt.</li>
                <li><strong>Spitze:</strong> Der höchste Punkt im Diagramm, der den maximalen Wert innerhalb eines bestimmten Zeitraums anzeigt.</li>
                <li><strong>Tal:</strong> Der niedrigste Punkt im Diagramm, der einen Mindestwert anzeigt.</li>
                <li><strong>Schwankung:</strong> Eine Reihe von Auf- und Abwärtsbewegungen im Diagramm, die oft auf Instabilität oder Variabilität in den Daten hinweisen.</li>
                <li><strong>Exponentielles Wachstum:</strong> Ein rascher Anstieg der Daten, bei dem die Wachstumsrate selbst im Laufe der Zeit weiter zunimmt.</li>
              </ul>

              <p>Das Vertrautmachen mit diesen Begriffen wird Dir helfen, Liniendiagramme effektiver zu interpretieren und zu analysieren.</p>

              <h3>Nützliche Formulierungen</h3>
              <details>
                <summary><strong>1. Einführung und Überblick</strong></summary>
                <ul>
                  <li>The graph shows/illustrates/presents…</li>
                  <li>This graph provides an overview of…</li>
                  <li>According to the data…</li>
                  <li>The line represents…</li>
                  <li>The graph covers the period from [year] to [year] and includes data on…</li>
                  <li>The data is presented in percentages/absolute numbers…</li>
                  <li>This line compares/contrasts…</li>
                  <li>At first glance, the graph suggests that…</li>
                </ul>
              </details>

              <details>
                <summary><strong>2. Beschreibung von Trends und Mustern</strong></summary>
                <ul>
                  <li>There is a noticeable increase/decrease in…</li>
                  <li>The data shows a steady rise/fall in…</li>
                  <li>Over the period of [years], the trend indicates…</li>
                  <li>A significant change can be observed in…</li>
                  <li>The numbers have remained stable/constant over the years.</li>
                  <li>The trend fluctuates throughout the given period…</li>
                  <li>There is a sharp spike in [year], where…</li>
                  <li>The data peaks in [year] at…</li>
                  <li>A gradual upward/downward trend is visible…</li>
                </ul>
              </details>

              <details>
                <summary><strong>3. Vergleiche anstellen</strong></summary>
                <ul>
                  <li>Compared to [category 1], [category 2] shows…</li>
                  <li>In contrast to…</li>
                  <li>While [A] experienced a rise, [B] saw a decline.</li>
                  <li>[Kategorie/Region] hat den höchsten/niedrigsten…</li>
                  <li>Die Werte für [A] sind höher/niedriger als die für [B].</li>
                  <li>Im Vergleich zum Vorjahr…</li>
                  <li>Die Daten für [Kategorie] sind signifikant höher/niedriger als…</li>
                  <li>Ein bemerkenswerter Unterschied kann zwischen… beobachtet werden.</li>
                </ul>
              </details>

              <details>
                <summary><strong>4. Schlussfolgerungen ziehen</strong></summary>
                <ul>
                  <li>In conclusion, the data suggests that…</li>
                  <li>Overall, the graph highlights…</li>
                  <li>Based on the data, it can be inferred that…</li>
                  <li>The figures indicate a clear trend of…</li>
                  <li>This leads to the conclusion that…</li>
                  <li>It is evident from the data that…</li>
                  <li>The analysis points to the fact that…</li>
                  <li>As a result, it is clear that…</li>
                  <li>The overall pattern reveals that…</li>
                </ul>
              </details>

              <details>
                <summary><strong>5. Bedeutung der Daten erklären</strong></summary>
                <ul>
                  <li>This data highlights the importance of…</li>
                  <li>The figures reflect the impact of…</li>
                  <li>The results emphasize the need for…</li>
                  <li>This pattern suggests that there are underlying factors such as…</li>
                  <li>The significance of this data lies in…</li>
                  <li>These numbers show the potential for…</li>
                  <li>This trend might be attributed to…</li>
                  <li>This could be explained by factors such as…</li>
                  <li>One possible explanation for this is…</li>
                </ul>
              </details>

              <details>
                <summary><strong>6. Ungewöhnliche Daten oder Ausreißer ansprechen</strong></summary>
                <ul>
                  <li>A noticeable outlier is…</li>
                  <li>One anomaly in the data is…</li>
                  <li>This unusual figure can be attributed to…</li>
                  <li>The spike/drop in [year] is likely due to…</li>
                  <li>A possible explanation for this outlier could be…</li>
                  <li>This data point deviates significantly from the overall trend.</li>
                </ul>
              </details>

            </>
          ]
        }
      },
      {
        title: {
          en: 'Bar Charts',
          de: 'Balkendiagramme',
        },
        description: {
          en: 'Rectangular bars representing comparisons.',
          de: 'Rechteckige Balken repräsentieren Vergleiche.',
        },
        details: {
          en: [
            <>
              <h3>Preparation</h3>
              <p>When analyzing bar charts, you can follow these steps:</p>
              <ol>
                <li><strong>Identify the Topic:</strong> Determine what the bar chart is about. It could be comparing categories, groups, or displaying frequencies of occurrences.</li>
                <li><strong>Examine the Axes:</strong> Look at both the x-axis and y-axis. The x-axis typically represents categories, while the y-axis shows the values being measured.</li>
                <li><strong>Identify Significant Bars:</strong> Spot the highest and lowest bars. These indicate the maximum and minimum values for the categories presented.</li>
                <li><strong>Compare the Bars:</strong> Compare the heights of the bars. How do they differ across the categories or groups?</li>
                <li><strong>Check for Grouped or Stacked Bars:</strong> If the chart has grouped or stacked bars, analyze the individual parts of each bar and how they contribute to the whole.</li>
                <li><strong>Note the Data Range:</strong> Assess the scale of the graph and check for any outliers or unusual values that stand out.</li>
              </ol>
              <p>Summarize these observations in a coherent first paragraph.</p>

              <h3>The Analysis</h3>
              <p>Once the bar chart has been thoroughly examined, structure your analysis as follows:</p>
              <ol>
                <li><strong>Summarize Key Insights:</strong>
                  <ul>
                    <li>Provide a concise summary of the key differences between the bars. Highlight which categories or groups have the highest or lowest values.</li>
                    <li>Identify any clear patterns, such as consistently larger bars for certain categories.</li>
                  </ul>
                </li>
                <li><strong>Explain the Data in Context:</strong>
                  <ul>
                    <li>Relate the data to the context. Explain why certain categories might have higher or lower values. Consider any relevant factors that could affect the data.</li>
                  </ul>
                </li>
                <li><strong>Compare Data:</strong>
                  <ul>
                    <li>Compare different categories or groups. How do they differ from each other? Are there any notable trends?</li>
                  </ul>
                </li>
                <li><strong>Interpret Patterns:</strong>
                  <ul>
                    <li>Discuss any patterns observed, such as a certain category consistently being the highest or lowest.</li>
                    <li>Use the data to make inferences or predictions, where applicable.</li>
                  </ul>
                </li>
                <li><strong>Draw Conclusions:</strong>
                  <ul>
                    <li>Conclude with an overall interpretation of the bar chart. What does the data suggest about the categories being compared?</li>
                    <li>Mention any limitations, such as incomplete data or any unusual results that might require further investigation.</li>
                  </ul>
                </li>
              </ol>

              <p>Remember to base your analysis on the data presented in the chart and consider any external influences that could explain the trends observed.</p>

              <h3>Glossary of Common Terms</h3>
              <p>Understanding the terminology used in bar charts is crucial for accurate analysis. Below are some common terms:</p>
              <ul>
                <li><strong>Bar:</strong> A rectangular block that represents the value of a category or group.</li>
                <li><strong>Axis:</strong> The x-axis typically shows the categories, while the y-axis shows the value scale.</li>
                <li><strong>Grouped Bars:</strong> Bars that are grouped together, representing different subcategories for the same category.</li>
                <li><strong>Stacked Bars:</strong> Bars where different segments are stacked on top of each other, showing the breakdown of a total value into subcategories.</li>
                <li><strong>Outlier:</strong> A data point that differs significantly from the others, often appearing as an unusually short or tall bar.</li>
              </ul>

              <p>Being familiar with these terms will help you interpret bar charts effectively.</p>

              <h3>Helpful phrases</h3>
              <details>
                <summary><strong>1. Introduction and Overview</strong></summary>
                <ul>
                  <li>The chart shows/illustrates/presents…</li>
                  <li>This chart provides an overview of…</li>
                  <li>According to the data…</li>
                  <li>The bars represent…</li>
                  <li>The chart covers the period from [year] to [year] and includes data on…</li>
                  <li>The data is presented in percentages/absolute numbers…</li>
                  <li>This chart compares/contrasts…</li>
                  <li>At first glance, the chart suggests that…</li>
                </ul>
              </details>

              <details>
                <summary><strong>2. Describing Trends and Patterns</strong></summary>
                <ul>
                  <li>There is a noticeable increase/decrease in…</li>
                  <li>The data shows a steady rise/fall in…</li>
                  <li>Over the period of [years], the trend indicates…</li>
                  <li>A significant change can be observed in…</li>
                  <li>The bars have remained stable/constant over the years.</li>
                  <li>The trend fluctuates throughout the given period…</li>
                  <li>There is a sharp rise/drop in [year], where…</li>
                  <li>The bars peak in [year] at…</li>
                  <li>A gradual upward/downward trend is visible…</li>
                </ul>
              </details>

              <details>
                <summary><strong>3. Making Comparisons</strong></summary>
                <ul>
                  <li>Compared to [category 1], [category 2] shows…</li>
                  <li>In contrast to…</li>
                  <li>While [A] experienced a rise, [B] saw a decline.</li>
                  <li>[Category/Region] has the highest/lowest…</li>
                  <li>The bars for [A] are higher/lower than those for [B].</li>
                  <li>In comparison with the previous year…</li>
                  <li>The data for [category] is significantly higher/lower than…</li>
                  <li>A notable difference can be observed between…</li>
                </ul>
              </details>

              <details>
                <summary><strong>4. Drawing Conclusions</strong></summary>
                <ul>
                  <li>In conclusion, the data suggests that…</li>
                  <li>Overall, the chart highlights…</li>
                  <li>Based on the data, it can be inferred that…</li>
                  <li>The figures indicate a clear trend of…</li>
                  <li>This leads to the conclusion that…</li>
                  <li>It is evident from the data that…</li>
                  <li>The analysis points to the fact that…</li>
                  <li>As a result, it is clear that…</li>
                  <li>The overall pattern reveals that…</li>
                </ul>
              </details>

              <details>
                <summary><strong>5. Explaining Data Significance</strong></summary>
                <ul>
                  <li>This data highlights the importance of…</li>
                  <li>The figures reflect the impact of…</li>
                  <li>The results emphasize the need for…</li>
                  <li>This pattern suggests that there are underlying factors such as…</li>
                  <li>The significance of this data lies in…</li>
                  <li>These numbers show the potential for…</li>
                  <li>This trend might be attributed to…</li>
                  <li>This could be explained by factors such as…</li>
                  <li>One possible explanation for this is…</li>
                </ul>
              </details>

              <details>
                <summary><strong>6. Addressing Unusual Data or Outliers</strong></summary>
                <ul>
                  <li>A noticeable outlier is…</li>
                  <li>One anomaly in the data is…</li>
                  <li>This unusual figure can be attributed to…</li>
                  <li>The spike/drop in [year] is likely due to…</li>
                  <li>A possible explanation for this outlier could be…</li>
                  <li>This data point deviates significantly from the overall trend.</li>
                </ul>
              </details>
            </>
          ],
          de: [
            <>
              <h3>Vorbereitung</h3>
              <p>Beim Analysieren von Balkendiagrammen kannst Du diesen Schritten folgen:</p>
              <ol>
                <li><strong>Thema des Diagramms identifizieren:</strong> Bestimme, worum es in dem Balkendiagramm geht. Es könnte Kategorien, Gruppen vergleichen oder die Häufigkeit von Vorkommen darstellen.</li>
                <li><strong>Achsen untersuchen:</strong> Schaue sowohl auf die x-Achse als auch auf die y-Achse. Die x-Achse repräsentiert typischerweise Kategorien, während die y-Achse die gemessenen Werte zeigt.</li>
                <li><strong>Wichtige Balken identifizieren:</strong> Suche nach dem höchsten und niedrigsten Balken. Diese zeigen die maximalen und minimalen Werte für die dargestellten Kategorien an.</li>
                <li><strong>Balken vergleichen:</strong> Vergleiche die Höhen der Balken. Wie unterscheiden sie sich zwischen den Kategorien oder Gruppen?</li>
                <li><strong>Überprüfen, ob Balken gruppiert oder gestapelt sind:</strong> Wenn das Diagramm gruppierte oder gestapelte Balken hat, analysiere die einzelnen Teile jedes Balkens und wie sie zum Ganzen beitragen.</li>
                <li><strong>Datenbereich beachten:</strong> Überprüfe die Skalierung des Diagramms und achte auf Ausreißer oder ungewöhnliche Werte, die auffallen.</li>
              </ol>
              <p>Fasse diese Beobachtungen in einem zusammenhängenden ersten Absatz zusammen.</p>

              <h3>Die Analyse</h3>
              <p>Nachdem das Balkendiagramm gründlich geprüft wurde, kannst Du Deine Analyse wie folgt strukturieren:</p>
              <ol>
                <li><strong>Wichtige Erkenntnisse zusammenfassen:</strong>
                  <ul>
                    <li>Gib eine prägnante Zusammenfassung der wichtigsten Unterschiede zwischen den Balken. Hebe hervor, welche Kategorien oder Gruppen die höchsten oder niedrigsten Werte haben.</li>
                    <li>Identifiziere klare Muster, wie z. B. konstant größere Balken für bestimmte Kategorien.</li>
                  </ul>
                </li>
                <li><strong>Daten im Kontext erklären:</strong>
                  <ul>
                    <li>Setze die Daten in den Kontext. Erkläre, warum bestimmte Kategorien möglicherweise höhere oder niedrigere Werte haben. Berücksichtige relevante Faktoren, die die Daten beeinflussen könnten.</li>
                  </ul>
                </li>
                <li><strong>Daten vergleichen:</strong>
                  <ul>
                    <li>Vergleiche verschiedene Kategorien oder Gruppen. Wie unterscheiden sie sich voneinander? Gibt es bemerkenswerte Trends?</li>
                  </ul>
                </li>
                <li><strong>Muster interpretieren:</strong>
                  <ul>
                    <li>Diskutiere beobachtete Muster, wie z. B. eine bestimmte Kategorie, die konstant die höchsten oder niedrigsten Werte aufweist.</li>
                    <li>Nutze die Daten, um Schlussfolgerungen oder Vorhersagen zu treffen, wo dies anwendbar ist.</li>
                  </ul>
                </li>
                <li><strong>Schlussfolgerungen ziehen:</strong>
                  <ul>
                    <li>Fasse mit einer Gesamtinterpretation des Balkendiagramms zusammen. Was deuten die Daten über die verglichenen Kategorien an?</li>
                    <li>Erwähne mögliche Einschränkungen, wie unvollständige Daten oder ungewöhnliche Ergebnisse, die einer weiteren Untersuchung bedürfen könnten.</li>
                  </ul>
                </li>
              </ol>

              <p>Denke daran, Deine Analyse auf den im Diagramm präsentierten Daten zu stützen und externe Einflüsse zu berücksichtigen, die die beobachteten Trends erklären könnten.</p>

              <h3>Glossar der wichtigsten Begriffe</h3>
              <p>Das Verständnis der in Balkendiagrammen verwendeten Terminologie ist entscheidend für eine genaue Analyse. Hier sind einige wichtige Begriffe:</p>
              <ul>
                <li><strong>Balken:</strong> Ein rechteckiger Block, der den Wert einer Kategorie oder Gruppe repräsentiert.</li>
                <li><strong>Achse:</strong> Die x-Achse zeigt typischerweise die Kategorien, während die y-Achse die Werteskala zeigt.</li>
                <li><strong>Gruppierte Balken:</strong> Balken, die zusammen gruppiert sind und verschiedene Unterkategorien für dieselbe Kategorie darstellen.</li>
                <li><strong>Gestapelte Balken:</strong> Balken, bei denen unterschiedliche Segmente aufeinander gestapelt sind und den Gesamtwert in Unterkategorien aufteilen.</li>
                <li><strong>Ausreißer:</strong> Ein Datenpunkt, der sich deutlich von den anderen unterscheidet und oft als ungewöhnlich kurzer oder hoher Balken erscheint.</li>
              </ul>

              <p>Das Vertrautmachen mit diesen Begriffen wird Dir helfen, Balkendiagramme effektiver zu interpretieren.</p>

              <h3>Nützliche Formulierungen</h3>
              <details>
                <summary><strong>1. Einführung und Überblick</strong></summary>
                <ul>
                  <li>The chart shows/illustrates/presents…</li>
                  <li>This chart provides an overview of…</li>
                  <li>According to the data…</li>
                  <li>The bars represent…</li>
                  <li>The chart covers the period from [year] to [year] and includes data on…</li>
                  <li>The data is presented in percentages/absolute numbers…</li>
                  <li>This chart compares/contrasts…</li>
                  <li>At first glance, the chart suggests that…</li>
                </ul>
              </details>

              <details>
                <summary><strong>2. Beschreibung von Trends und Mustern</strong></summary>
                <ul>
                  <li>There is a noticeable increase/decrease in…</li>
                  <li>The data shows a steady rise/fall in…</li>
                  <li>Over the period of [years], the trend indicates…</li>
                  <li>A significant change can be observed in…</li>
                  <li>Die Balken sind über die Jahre hinweg stabil/konstant geblieben.</li>
                  <li>Der Trend schwankt während des betrachteten Zeitraums…</li>
                  <li>Es gibt einen starken Anstieg/Rückgang in [Jahr], wo…</li>
                  <li>Die Balken erreichen ihren Höchststand in [Jahr] bei…</li>
                  <li>Ein allmählicher Aufwärts-/Abwärtstrend ist sichtbar…</li>
                </ul>
              </details>

              <details>
                <summary><strong>3. Vergleiche anstellen</strong></summary>
                <ul>
                  <li>Compared to [category 1], [category 2] shows…</li>
                  <li>Im Gegensatz zu…</li>
                  <li>Während [A] einen Anstieg erlebte, verzeichnete [B] einen Rückgang.</li>
                  <li>[Kategorie/Region] hat den höchsten/niedrigsten…</li>
                  <li>Die Balken für [A] sind höher/niedriger als die für [B].</li>
                  <li>Im Vergleich zum Vorjahr…</li>
                  <li>Die Daten für [Kategorie] sind signifikant höher/niedriger als…</li>
                  <li>Ein bemerkenswerter Unterschied kann zwischen… beobachtet werden.</li>
                </ul>
              </details>

              <details>
                <summary><strong>4. Schlussfolgerungen ziehen</strong></summary>
                <ul>
                  <li>In conclusion, the data suggests that…</li>
                  <li>Insgesamt hebt das Diagramm hervor…</li>
                  <li>Auf Grundlage der Daten kann man schließen, dass…</li>
                  <li>Die Zahlen zeigen einen klaren Trend von…</li>
                  <li>Dies führt zu der Schlussfolgerung, dass…</li>
                  <li>Es ist offensichtlich aus den Daten, dass…</li>
                  <li>Die Analyse zeigt, dass…</li>
                  <li>Infolgedessen ist klar, dass…</li>
                  <li>Das Gesamtmuster zeigt, dass…</li>
                </ul>
              </details>

              <details>
                <summary><strong>5. Bedeutung der Daten erklären</strong></summary>
                <ul>
                  <li>Diese Daten unterstreichen die Bedeutung von…</li>
                  <li>Die Zahlen spiegeln die Auswirkungen von… wider</li>
                  <li>Die Ergebnisse betonen die Notwendigkeit von…</li>
                  <li>Dieses Muster deutet darauf hin, dass es zugrunde liegende Faktoren wie… gibt</li>
                  <li>Die Bedeutung dieser Daten liegt in…</li>
                  <li>Diese Zahlen zeigen das Potenzial für…</li>
                  <li>Dieser Trend könnte auf… zurückzuführen sein</li>
                  <li>Dies könnte durch Faktoren wie… erklärt werden</li>
                  <li>Eine mögliche Erklärung dafür ist…</li>
                </ul>
              </details>

              <details>
                <summary><strong>6. Ungewöhnliche Daten oder Ausreißer ansprechen</strong></summary>
                <ul>
                  <li>Ein bemerkenswerter Ausreißer ist…</li>
                  <li>Eine Anomalie in den Daten ist…</li>
                  <li>Diese ungewöhnliche Zahl kann auf… zurückgeführt werden</li>
                  <li>Der Anstieg/Abfall in [Jahr] ist wahrscheinlich auf… zurückzuführen</li>
                  <li>Eine mögliche Erklärung für diesen Ausreißer könnte sein…</li>
                  <li>Dieser Datenpunkt weicht signifikant vom allgemeinen Trend ab.</li>
                </ul>
              </details>
            </>
          ]
        }
      },
      {
        title: {
          en: 'Pie Charts',
          de: 'Kreisdiagramme',
        },
        description: {
          en: 'Proportions of a whole.',
          de: 'Anteile eines Ganzen.',
        },
        details: {
          en: [
            <>
              <h3>Preparation</h3>
              <p>When analyzing pie charts, you can follow these steps:</p>
              <ol>
                <li><strong>Identify the Topic:</strong> Determine what the pie chart is about. It could represent proportions of categories, such as market share or survey results.</li>
                <li><strong>Look at the Sectors:</strong> Examine the size of the sectors. Larger sectors represent a larger proportion of the whole.</li>
                <li><strong>Check the Labels:</strong> Review the labels or legends to see what each sector represents. Identify the categories and the corresponding values.</li>
                <li><strong>Compare the Sectors:</strong> Compare the sizes of different sectors to see which category dominates and which has the smallest share.</li>
                <li><strong>Note Percentages:</strong> Many pie charts show the percentage each sector represents. Check whether these percentages add up to 100%.</li>
                <li><strong>Check for Multiple Charts:</strong> If there are multiple pie charts, compare the proportions across them to analyze trends or changes over time.</li>
              </ol>
              <p>Summarize these observations in a coherent first paragraph.</p>

              <h3>The Analysis</h3>
              <p>Once the pie chart has been thoroughly examined, structure your analysis as follows:</p>
              <ol>
                <li><strong>Summarize Key Insights:</strong>
                  <ul>
                    <li>Provide a concise summary of the largest and smallest sectors. Highlight which categories have the biggest share.</li>
                    <li>Identify any major trends, such as one category significantly dominating others.</li>
                  </ul>
                </li>
                <li><strong>Explain the Data in Context:</strong>
                  <ul>
                    <li>Relate the data to the context. Explain why certain categories might have larger or smaller proportions.</li>
                  </ul>
                </li>
                <li><strong>Compare Data:</strong>
                  <ul>
                    <li>Compare the proportions across different categories or charts, if applicable. What changes or patterns are visible?</li>
                  </ul>
                </li>
                <li><strong>Interpret Patterns:</strong>
                  <ul>
                    <li>Discuss any patterns, such as recurring dominance by one category, or proportional shifts over time.</li>
                    <li>Use the data to make inferences or predictions, where relevant.</li>
                  </ul>
                </li>
                <li><strong>Draw Conclusions:</strong>
                  <ul>
                    <li>Conclude with an overall interpretation of the pie chart. What do the proportions suggest about the categories being analyzed?</li>
                    <li>Mention any limitations, such as missing data or the absence of clear labels, that might affect the interpretation.</li>
                  </ul>
                </li>
              </ol>

              <p>Remember to base your analysis on the visual data presented and consider any external influences that might explain the observed proportions.</p>

              <h3>Glossary of Common Terms</h3>
              <p>Understanding the terminology used in pie charts is essential for accurate analysis. Here are some key terms:</p>
              <ul>
                <li><strong>Sector | <em>Sektor</em>:</strong> A segment of the pie chart representing a specific category or value.</li>
                <li><strong>Legend | <em>Legende</em>:</strong> A key that explains what each sector represents.</li>
                <li><strong>Proportion | <em>Anteil</em>:</strong> The percentage or fraction of the whole that each sector represents.</li>
                <li><strong>Slice | <em>Abschnitt</em>:</strong> Another term for a sector, representing a "slice" of the pie chart.</li>
                <li><strong>Exploded Pie Chart | <em>Explodiertes Kreisdiagramm</em>:</strong> A pie chart with one or more sectors separated from the rest to highlight certain data.</li>
              </ul>

              <p>Knowing these terms will help you interpret and analyze pie charts more effectively.</p>

              <h3>Helpful phrases</h3>
              <details>
                <summary><strong>1. Introduction and Overview</strong></summary>
                <ul>
                  <li>The chart shows/illustrates/presents…</li>
                  <li>This chart provides an overview of…</li>
                  <li>According to the data…</li>
                  <li>The sectors represent…</li>
                  <li>The chart covers data on…</li>
                  <li>The data is presented in percentages/absolute numbers…</li>
                  <li>This pie chart compares/contrasts…</li>
                  <li>At first glance, the chart suggests that…</li>
                </ul>
              </details>

              <details>
                <summary><strong>2. Describing Trends and Patterns</strong></summary>
                <ul>
                  <li>There is a noticeable increase/decrease in…</li>
                  <li>The data shows a steady rise/fall in…</li>
                  <li>Over the period of [years], the trend indicates…</li>
                  <li>A significant change can be observed in…</li>
                  <li>The sectors have remained stable/constant over the years.</li>
                  <li>The trend fluctuates throughout the given period…</li>
                  <li>There is a large/small sector representing…</li>
                  <li>The chart peaks at…</li>
                  <li>A gradual upward/downward trend is visible…</li>
                </ul>
              </details>

              <details>
                <summary><strong>3. Making Comparisons</strong></summary>
                <ul>
                  <li>Compared to [category 1], [category 2] shows…</li>
                  <li>In contrast to…</li>
                  <li>While [A] experienced a rise, [B] saw a decline.</li>
                  <li>[Category] dominates with the largest sector representing…</li>
                  <li>The sectors for [A] are larger/smaller than those for [B].</li>
                  <li>The data for [category] is significantly larger/smaller than…</li>
                  <li>A notable difference can be observed between…</li>
                </ul>
              </details>

              <details>
                <summary><strong>4. Drawing Conclusions</strong></summary>
                <ul>
                  <li>In conclusion, the data suggests that…</li>
                  <li>Overall, the chart highlights…</li>
                  <li>Based on the data, it can be inferred that…</li>
                  <li>The figures indicate a clear trend of…</li>
                  <li>This leads to the conclusion that…</li>
                  <li>It is evident from the data that…</li>
                  <li>The analysis points to the fact that…</li>
                  <li>As a result, it is clear that…</li>
                  <li>The overall pattern reveals that…</li>
                </ul>
              </details>

              <details>
                <summary><strong>5. Explaining Data Significance</strong></summary>
                <ul>
                  <li>This data highlights the importance of…</li>
                  <li>The figures reflect the impact of…</li>
                  <li>The results emphasize the need for…</li>
                  <li>This pattern suggests that there are underlying factors such as…</li>
                  <li>The significance of this data lies in…</li>
                  <li>These numbers show the potential for…</li>
                  <li>This trend might be attributed to…</li>
                  <li>This could be explained by factors such as…</li>
                  <li>One possible explanation for this is…</li>
                </ul>
              </details>

              <details>
                <summary><strong>6. Addressing Unusual Data or Outliers</strong></summary>
                <ul>
                  <li>A noticeable outlier is…</li>
                  <li>One anomaly in the data is…</li>
                  <li>This unusual figure can be attributed to…</li>
                  <li>The spike/drop in [year] is likely due to…</li>
                  <li>A possible explanation for this outlier could be…</li>
                  <li>This data point deviates significantly from the overall trend.</li>
                </ul>
              </details>

            </>
          ],
          de: [
            <>
              <h3>Vorbereitung</h3>
              <p>Beim Analysieren von Kreisdiagrammen kannst Du diesen Schritten folgen:</p>
              <ol>
                <li><strong>Thema des Diagramms identifizieren:</strong> Bestimme, worum es in dem Kreisdiagramm geht. Es könnte Anteile von Kategorien darstellen, wie z. B. Marktanteile oder Umfrageergebnisse.</li>
                <li><strong>Sektoren betrachten:</strong> Untersuche die Größe der Sektoren. Größere Sektoren stellen einen größeren Anteil des Ganzen dar.</li>
                <li><strong>Beschriftungen prüfen:</strong> Überprüfe die Beschriftungen oder Legenden, um zu sehen, was jeder Sektor darstellt. Identifiziere die Kategorien und die entsprechenden Werte.</li>
                <li><strong>Sektoren vergleichen:</strong> Vergleiche die Größe der verschiedenen Sektoren, um zu sehen, welche Kategorie dominiert und welche den kleinsten Anteil hat.</li>
                <li><strong>Prozente beachten:</strong> Viele Kreisdiagramme zeigen den Prozentsatz, den jeder Sektor darstellt. Überprüfe, ob diese Prozente 100 % ergeben.</li>
                <li><strong>Mehrere Diagramme prüfen:</strong> Wenn es mehrere Kreisdiagramme gibt, vergleiche die Anteile zwischen ihnen, um Trends oder Veränderungen im Laufe der Zeit zu analysieren.</li>
              </ol>
              <p>Fasse diese Beobachtungen in einem zusammenhängenden ersten Absatz zusammen.</p>

              <h3>Die Analyse</h3>
              <p>Nachdem das Kreisdiagramm gründlich geprüft wurde, kannst Du Deine Analyse wie folgt strukturieren:</p>
              <ol>
                <li><strong>Wichtige Erkenntnisse zusammenfassen:</strong>
                  <ul>
                    <li>Gib eine prägnante Zusammenfassung der größten und kleinsten Sektoren. Hebe hervor, welche Kategorien den größten Anteil haben.</li>
                    <li>Identifiziere größere Trends, z. B. eine Kategorie, die die anderen deutlich dominiert.</li>
                  </ul>
                </li>
                <li><strong>Daten im Kontext erklären:</strong>
                  <ul>
                    <li>Setze die Daten in den Kontext. Erkläre, warum bestimmte Kategorien größere oder kleinere Anteile haben könnten.</li>
                  </ul>
                </li>
                <li><strong>Daten vergleichen:</strong>
                  <ul>
                    <li>Vergleiche die Anteile zwischen verschiedenen Kategorien oder Diagrammen, wenn zutreffend. Welche Veränderungen oder Muster sind sichtbar?</li>
                  </ul>
                </li>
                <li><strong>Muster interpretieren:</strong>
                  <ul>
                    <li>Diskutiere Muster, wie z. B. eine wiederkehrende Dominanz einer Kategorie oder proportionale Verschiebungen im Laufe der Zeit.</li>
                    <li>Nutze die Daten, um Schlussfolgerungen oder Vorhersagen zu treffen, wo relevant.</li>
                  </ul>
                </li>
                <li><strong>Schlussfolgerungen ziehen:</strong>
                  <ul>
                    <li>Fasse mit einer Gesamtinterpretation des Kreisdiagramms zusammen. Was deuten die Anteile über die analysierten Kategorien an?</li>
                    <li>Erwähne eventuelle Einschränkungen, wie fehlende Daten oder das Fehlen klarer Beschriftungen, die die Interpretation beeinflussen könnten.</li>
                  </ul>
                </li>
              </ol>

              <p>Denke daran, Deine Analyse auf den visuellen Daten zu stützen und externe Einflüsse zu berücksichtigen, die die beobachteten Anteile erklären könnten.</p>

              <h3>Glossar der wichtigsten Begriffe</h3>
              <p>Das Verständnis der in Kreisdiagrammen verwendeten Terminologie ist entscheidend für eine genaue Analyse. Hier sind einige wichtige Begriffe:</p>
              <ul>
                <li><strong>Sector | <em>Sektor</em>:</strong> A segment of the pie chart representing a specific category or value.</li>
                <li><strong>Legend | <em>Legende</em>:</strong> A key that explains what each sector represents.</li>
                <li><strong>Proportion | <em>Anteil</em>:</strong> The percentage or fraction of the whole that each sector represents.</li>
                <li><strong>Slice | <em>Abschnitt</em>:</strong> Another term for a sector, representing a "slice" of the pie chart.</li>
                <li><strong>Exploded Pie Chart | <em>Explodiertes Kreisdiagramm</em>:</strong> A pie chart with one or more sectors separated from the rest to highlight certain data.</li>
              </ul>

              <p>Das Vertrautmachen mit diesen Begriffen wird Dir helfen, Kreisdiagramme effektiver zu interpretieren und zu analysieren.</p>

              <h3>Nützliche Formulierungen</h3>
              <details>
                <summary><strong>1. Einführung und Überblick</strong></summary>
                <ul>
                  <li>The chart shows/illustrates/presents…</li>
                  <li>This chart provides an overview of…</li>
                  <li>According to the data…</li>
                  <li>The sectors represent…</li>
                  <li>The chart covers data on…</li>
                  <li>The data is presented in percentages/absolute numbers…</li>
                  <li>This pie chart compares/contrasts…</li>
                  <li>At first glance, the chart suggests that…</li>
                </ul>
              </details>

              <details>
                <summary><strong>2. Beschreibung von Trends und Mustern</strong></summary>
                <ul>
                  <li>There is a noticeable increase/decrease in…</li>
                  <li>The data shows a steady rise/fall in…</li>
                  <li>Over the period of [years], the trend indicates…</li>
                  <li>A significant change can be observed in…</li>
                  <li>The sectors have remained stable/constant over the years.</li>
                  <li>The trend fluctuates throughout the given period…</li>
                  <li>There is a large/small sector representing…</li>
                  <li>The chart peaks at…</li>
                  <li>A gradual upward/downward trend is visible…</li>
                </ul>
              </details>

              <details>
                <summary><strong>3. Vergleiche anstellen</strong></summary>
                <ul>
                  <li>Compared to [category 1], [category 2] shows…</li>
                  <li>In contrast to…</li>
                  <li>While [A] experienced a rise, [B] saw a decline.</li>
                  <li>[Category] dominates with the largest sector representing…</li>
                  <li>The sectors for [A] are larger/smaller than those for [B].</li>
                  <li>The data for [category] is significantly larger/smaller than…</li>
                  <li>A notable difference can be observed between…</li>
                </ul>
              </details>

              <details>
                <summary><strong>4. Schlussfolgerungen ziehen</strong></summary>
                <ul>
                  <li>In conclusion, the data suggests that…</li>
                  <li>Overall, the chart highlights…</li>
                  <li>Based on the data, it can be inferred that…</li>
                  <li>The figures indicate a clear trend of…</li>
                  <li>This leads to the conclusion that…</li>
                  <li>It is evident from the data that…</li>
                  <li>The analysis points to the fact that…</li>
                  <li>As a result, it is clear that…</li>
                  <li>The overall pattern reveals that…</li>
                </ul>
              </details>

              <details>
                <summary><strong>5. Bedeutung der Daten erklären</strong></summary>
                <ul>
                  <li>This data highlights the importance of…</li>
                  <li>The figures reflect the impact of…</li>
                  <li>The results emphasize the need for…</li>
                  <li>This pattern suggests that there are underlying factors such as…</li>
                  <li>The significance of this data lies in…</li>
                  <li>These numbers show the potential for…</li>
                  <li>This trend might be attributed to…</li>
                  <li>This could be explained by factors such as…</li>
                  <li>One possible explanation for this is…</li>
                </ul>
              </details>

              <details>
                <summary><strong>6. Ungewöhnliche Daten oder Ausreißer ansprechen</strong></summary>
                <ul>
                  <li>A noticeable outlier is…</li>
                  <li>One anomaly in the data is…</li>
                  <li>This unusual figure can be attributed to…</li>
                  <li>The spike/drop in [year] is likely due to…</li>
                  <li>A possible explanation for this outlier could be…</li>
                  <li>This data point deviates significantly from the overall trend.</li>
                </ul>
              </details>
            </>
          ]
        }
      },
      {
        title: {
          en: 'Infographics',
          de: 'Infografiken',
        },
        description: {
          en: 'Different data types enhanced with design elements.',
          de: 'Verschiedene Datentypen ergänzt mit Designelementen.',
        },
        details: {
          en: [
            <>
              <h3>Preparation</h3>
              <p>When analyzing infographics, you can follow these steps:</p>
              <ol>
                <li><strong>Identify the Topic:</strong> Determine the overall message or subject of the infographic. It might summarize key information from multiple data sources or combine different types of data (e.g., market trends, survey results, or statistics).</li>
                <li><strong>Understand the Visualizations:</strong> Recognize the different types of visual elements used. These could include pie charts, bar charts, line graphs, or other representations. Identify each type and its purpose within the infographic.</li>
                <li><strong>Interpret Icons and Symbols:</strong> Infographics often include symbols or icons. Identify what they represent and how they relate to the data or text around them.</li>
                <li><strong>Check Labels and Legends:</strong> Review the labels, legends, and captions to understand the meaning of each section of the infographic. These will provide essential context for interpreting the data.</li>
                <li><strong>Note Relationships Between Visuals:</strong> Infographics often show connections between different data points. Look for lines, arrows, or other elements that illustrate relationships, comparisons, or processes.</li>
                <li><strong>Consider the Overall Design:</strong> Assess how the infographic’s design elements, like color schemes and layout, enhance or affect the presentation of information.</li>
              </ol>
              <p>Summarize these observations in a coherent first paragraph, highlighting the types of data presented and how the visuals work together.</p>

              <h3>The Analysis</h3>
              <p>Once you have reviewed the infographic, structure your analysis as follows:</p>
              <ol>
                <li><strong>Summarize Key Insights:</strong>
                  <ul>
                    <li>Briefly summarize the most important data points or visual elements. Focus on trends, comparisons, and the overall message of the infographic.</li>
                    <li>Highlight which sections or data types (charts, graphs, etc.) present the most significant information.</li>
                  </ul>
                </li>
                <li><strong>Explain the Data in Context:</strong>
                  <ul>
                    <li>Explain the data within its context, including why the information is relevant or how different data points are connected.</li>
                    <li>Use the visual elements to support your explanation, especially when discussing trends or comparisons.</li>
                  </ul>
                </li>
                <li><strong>Interpret Patterns and Comparisons:</strong>
                  <ul>
                    <li>Identify any patterns or comparisons in the data, especially across different types of visualizations. How do the visuals complement or contrast each other?</li>
                  </ul>
                </li>
                <li><strong>Draw Conclusions:</strong>
                  <ul>
                    <li>Summarize the main takeaways from the infographic. What is the overall message, and how do the different visual elements support it?</li>
                    <li>Mention any limitations or potential biases in the infographic, such as omitted data or selective visualization choices.</li>
                  </ul>
                </li>
              </ol>

              <p>Remember to base your analysis on both the data and the design elements used, considering how the combination affects the message conveyed.</p>

              <h3>Glossary of Common Terms</h3>
              <p>Here are some common terms related to infographics:</p>
              <ul>
                <li><strong>Visualization | <em>Visualisierung</em>:</strong> A graphic representation of data, such as a pie chart, bar graph, or line graph.</li>
                <li><strong>Icon | <em>Symbol</em>:</strong> A small graphic used to represent a concept, category, or piece of information within the infographic.</li>
                <li><strong>Legend | <em>Legende</em>:</strong> A key that explains the colors, symbols, or patterns used in the infographic.</li>
                <li><strong>Data Point | <em>Datenpunkt</em>:</strong> A specific value or piece of information shown within the infographic.</li>
                <li><strong>Hierarchy | <em>Hierarchie</em>:</strong> The arrangement of elements in the infographic, which often prioritizes information from most to least important.</li>
              </ul>

              <p>Understanding these terms will help you interpret and analyze infographics more effectively.</p>

              <h3>Helpful phrases</h3>
              <details>
                <summary><strong>1. Introduction and Overview</strong></summary>
                <ul>
                  <li>The infographic shows/illustrates/presents…</li>
                  <li>This infographic provides an overview of…</li>
                  <li>According to the data…</li>
                  <li>The charts/graphs/icons represent…</li>
                  <li>The infographic covers data on…</li>
                  <li>This infographic compares/contrasts…</li>
                  <li>At first glance, the infographic suggests that…</li>
                </ul>
              </details>

              <details>
                <summary><strong>2. Describing Trends and Patterns</strong></summary>
                <ul>
                  <li>There is a noticeable increase/decrease in…</li>
                  <li>The data shows a steady rise/fall in…</li>
                  <li>Over the period of [years], the trend indicates…</li>
                  <li>A significant change can be observed in…</li>
                  <li>The data has remained stable/constant over the years.</li>
                  <li>The trend fluctuates throughout the given period…</li>
                  <li>The largest/smallest segment represents…</li>
                  <li>A gradual upward/downward trend is visible…</li>
                </ul>
              </details>

              <details>
                <summary><strong>3. Making Comparisons</strong></summary>
                <ul>
                  <li>Compared to [category 1], [category 2] shows…</li>
                  <li>In contrast to…</li>
                  <li>While [A] experienced a rise, [B] saw a decline.</li>
                  <li>The largest category in the infographic represents…</li>
                  <li>The data for [A] is higher/lower than for [B].</li>
                  <li>The infographic highlights the differences between…</li>
                  <li>A notable comparison can be made between…</li>
                </ul>
              </details>

              <details>
                <summary><strong>4. Drawing Conclusions</strong></summary>
                <ul>
                  <li>In conclusion, the data suggests that…</li>
                  <li>Overall, the infographic highlights…</li>
                  <li>Based on the data, it can be inferred that…</li>
                  <li>The figures indicate a clear trend of…</li>
                  <li>This leads to the conclusion that…</li>
                  <li>It is evident from the data that…</li>
                  <li>The analysis points to the fact that…</li>
                  <li>As a result, it is clear that…</li>
                </ul>
              </details>

              <details>
                <summary><strong>5. Explaining Data Significance</strong></summary>
                <ul>
                  <li>This data highlights the importance of…</li>
                  <li>The figures reflect the impact of…</li>
                  <li>The results emphasize the need for…</li>
                  <li>This pattern suggests that there are underlying factors such as…</li>
                  <li>The significance of this data lies in…</li>
                  <li>These numbers show the potential for…</li>
                  <li>This trend might be attributed to…</li>
                  <li>This could be explained by factors such as…</li>
                  <li>One possible explanation for this is…</li>
                </ul>
              </details>

              <details>
                <summary><strong>6. Addressing Unusual Data or Outliers</strong></summary>
                <ul>
                  <li>A noticeable outlier is…</li>
                  <li>One anomaly in the data is…</li>
                  <li>This unusual figure can be attributed to…</li>
                  <li>The spike/drop in [year] is likely due to…</li>
                  <li>A possible explanation for this outlier could be…</li>
                  <li>This data point deviates significantly from the overall trend.</li>
                </ul>
              </details>
            </>
          ],
          de: [
            <>
              <h3>Vorbereitung</h3>
              <p>Beim Analysieren von Infografiken kannst Du diesen Schritten folgen:</p>
              <ol>
                <li><strong>Thema identifizieren:</strong> Bestimme das übergeordnete Thema oder die Botschaft der Infografik. Sie fasst möglicherweise wichtige Informationen aus mehreren Datenquellen zusammen oder kombiniert verschiedene Datentypen (z. B. Markttrends, Umfrageergebnisse oder Statistiken).</li>
                <li><strong>Visuelle Elemente verstehen:</strong> Erkenne die verschiedenen Arten von visuellen Elementen, die verwendet werden. Dazu können Kreisdiagramme, Balkendiagramme, Liniendiagramme oder andere Darstellungen gehören. Identifiziere jede Art und ihren Zweck innerhalb der Infografik.</li>
                <li><strong>Symbole und Icons interpretieren:</strong> Infografiken enthalten oft Symbole oder Icons. Identifiziere, was sie darstellen und wie sie sich auf die Daten oder den umgebenden Text beziehen.</li>
                <li><strong>Beschriftungen und Legenden überprüfen:</strong> Überprüfe die Beschriftungen, Legenden und Bildunterschriften, um die Bedeutung jedes Abschnitts der Infografik zu verstehen. Diese liefern wesentliche Kontextinformationen für die Interpretation der Daten.</li>
                <li><strong>Beziehungen zwischen den Visualisierungen beachten:</strong> Infografiken zeigen oft Verbindungen zwischen verschiedenen Datenpunkten. Achte auf Linien, Pfeile oder andere Elemente, die Beziehungen, Vergleiche oder Prozesse darstellen.</li>
                <li><strong>Gesamtgestaltung berücksichtigen:</strong> Bewerte, wie die Designelemente der Infografik, wie Farbschemata und Layouts, die Präsentation der Informationen verbessern oder beeinflussen.</li>
              </ol>
              <p>Fasse diese Beobachtungen in einem zusammenhängenden ersten Absatz zusammen und hebe hervor, welche Datentypen präsentiert werden und wie die visuellen Elemente zusammenwirken.</p>

              <h3>Die Analyse</h3>
              <p>Nachdem Du die Infografik überprüft hast, kannst Du Deine Analyse wie folgt strukturieren:</p>
              <ol>
                <li><strong>Wichtige Erkenntnisse zusammenfassen:</strong>
                  <ul>
                    <li>Gib eine kurze Zusammenfassung der wichtigsten Datenpunkte oder visuellen Elemente. Konzentriere Dich auf Trends, Vergleiche und die Gesamtbotschaft der Infografik.</li>
                    <li>Hebe hervor, welche Abschnitte oder Datentypen (Diagramme, Grafiken usw.) die wichtigsten Informationen präsentieren.</li>
                  </ul>
                </li>
                <li><strong>Daten im Kontext erklären:</strong>
                  <ul>
                    <li>Erkläre die Daten im entsprechenden Kontext, einschließlich der Relevanz der Informationen oder der Verbindung zwischen den verschiedenen Datenpunkten.</li>
                    <li>Nimm Bezug auf die visuellen Elemente, um Deine Erklärungen zu unterstützen, insbesondere bei der Diskussion von Trends oder Vergleichen.</li>
                  </ul>
                </li>
                <li><strong>Muster und Vergleiche interpretieren:</strong>
                  <ul>
                    <li>Identifiziere Muster oder Vergleiche in den Daten, insbesondere zwischen verschiedenen Arten von Visualisierungen. Wie ergänzen oder kontrastieren sich die visuellen Elemente?</li>
                  </ul>
                </li>
                <li><strong>Schlussfolgerungen ziehen:</strong>
                  <ul>
                    <li>Fasse die wichtigsten Erkenntnisse der Infografik zusammen. Was ist die Gesamtbotschaft, und wie unterstützen die verschiedenen visuellen Elemente diese?</li>
                    <li>Erwähne eventuelle Einschränkungen oder mögliche Verzerrungen in der Infografik, wie ausgelassene Daten oder selektive Visualisierungen.</li>
                  </ul>
                </li>
              </ol>

              <p>Denke daran, Deine Analyse sowohl auf den Daten als auch auf den Designelementen zu stützen und zu bewerten, wie die Kombination die vermittelte Botschaft beeinflusst.</p>

              <h3>Glossar der wichtigsten Begriffe</h3>
              <p>Hier sind einige wichtige Begriffe im Zusammenhang mit Infografiken:</p>
              <ul>
                <li><strong>Visualization | <em>Visualisierung</em>:</strong> Eine grafische Darstellung von Daten, wie z. B. ein Kreisdiagramm, Balkendiagramm oder Liniendiagramm.</li>
                <li><strong>Icon | <em>Symbol</em>:</strong> Ein kleines grafisches Symbol, das in der Infografik ein Konzept, eine Kategorie oder eine Information darstellt.</li>
                <li><strong>Legend | <em>Legende</em>:</strong> Eine Erklärung der verwendeten Farben, Symbole oder Muster in der Infografik.</li>
                <li><strong>Data Point | <em>Datenpunkt</em>:</strong> Ein spezifischer Wert oder ein Stück Information, das in der Infografik dargestellt wird.</li>
                <li><strong>Hierarchy | <em>Hierarchie</em>:</strong> Die Anordnung der Elemente in der Infografik, die oft Informationen von den wichtigsten zu den weniger wichtigen priorisiert.</li>
              </ul>

              <p>Das Verständnis dieser Begriffe wird Dir helfen, Infografiken effektiver zu interpretieren und zu analysieren.</p>

              <h3>Nützliche Formulierungen</h3>
              <details>
                <summary><strong>1. Introduction and Overview</strong></summary>
                <ul>
                  <li>The infographic shows/illustrates/presents…</li>
                  <li>This infographic provides an overview of…</li>
                  <li>According to the data…</li>
                  <li>The charts/graphs/icons represent…</li>
                  <li>The infographic covers data on…</li>
                  <li>This infographic compares/contrasts…</li>
                  <li>At first glance, the infographic suggests that…</li>
                </ul>
              </details>

              <details>
                <summary><strong>2. Beschreibung von Trends und Mustern</strong></summary>
                <ul>
                  <li>There is a noticeable increase/decrease in…</li>
                  <li>The data shows a steady rise/fall in…</li>
                  <li>Over the period of [years], the trend indicates…</li>
                  <li>A significant change can be observed in…</li>
                  <li>The data has remained stable/constant over the years.</li>
                  <li>The trend fluctuates throughout the given period…</li>
                  <li>The largest/smallest segment represents…</li>
                  <li>A gradual upward/downward trend is visible…</li>
                </ul>
              </details>

              <details>
                <summary><strong>3. Vergleiche anstellen</strong></summary>
                <ul>
                  <li>Compared to [category 1], [category 2] shows…</li>
                  <li>In contrast to…</li>
                  <li>While [A] experienced a rise, [B] saw a decline.</li>
                  <li>The largest category in the infographic represents…</li>
                  <li>The data for [A] is higher/lower than for [B].</li>
                  <li>The infographic highlights the differences between…</li>
                  <li>A notable comparison can be made between…</li>
                </ul>
              </details>

              <details>
                <summary><strong>4. Schlussfolgerungen ziehen</strong></summary>
                <ul>
                  <li>In conclusion, the data suggests that…</li>
                  <li>Overall, the infographic highlights…</li>
                  <li>Based on the data, it can be inferred that…</li>
                  <li>The figures indicate a clear trend of…</li>
                  <li>This leads to the conclusion that…</li>
                  <li>It is evident from the data that…</li>
                  <li>The analysis points to the fact that…</li>
                  <li>As a result, it is clear that…</li>
                </ul>
              </details>

              <details>
                <summary><strong>5. Bedeutung der Daten erklären</strong></summary>
                <ul>
                  <li>This data highlights the importance of…</li>
                  <li>The figures reflect the impact of…</li>
                  <li>The results emphasize the need for…</li>
                  <li>This pattern suggests that there are underlying factors such as…</li>
                  <li>The significance of this data lies in…</li>
                  <li>These numbers show the potential for…</li>
                  <li>This trend might be attributed to…</li>
                  <li>This could be explained by factors such as…</li>
                  <li>One possible explanation for this is…</li>
                </ul>
              </details>

              <details>
                <summary><strong>6. Ungewöhnliche Daten oder Ausreißer ansprechen</strong></summary>
                <ul>
                  <li>A noticeable outlier is…</li>
                  <li>One anomaly in the data is…</li>
                  <li>This unusual figure can be attributed to…</li>
                  <li>The spike/drop in [year] is likely due to…</li>
                  <li>A possible explanation for this outlier could be…</li>
                  <li>This data point deviates significantly from the overall trend.</li>
                </ul>
              </details>
            </>
          ]
        }
      }




    ],
    checklist: {
      en: [
        {
          question: 'Did I identify the topic?',
          explanation: 'Make sure you’ve clearly understood what the chart, graph, table, or infographic is about and the overall message or purpose it conveys.',
          messages: [
            "Needs significant improvement in identifying the topic.", // 😢
            "Topic identification is below expectations.",             // 🙁
            "Topic identified satisfactorily.",                        // 😐
            "Good job identifying the topic!",                         // 🙂
            "Excellent identification of the topic!"                   // 😃
          ]
        },
        {
          question: 'Did I examine and interpret the axes, sectors, or data points?',
          explanation: 'For charts and graphs, check the labels, legends, and axes (x-axis, y-axis) for important details. In tables, verify that you’ve understood the rows and columns. For infographics, pay attention to icons, symbols, and other visual cues.',
          messages: [
            "Needs significant improvement in interpreting visual elements.", // 😢
            "Interpretation of axes and data points is below expectations.",  // 🙁
            "Visual elements examined satisfactorily.",                       // 😐
            "Good job interpreting the axes and data points!",                // 🙂
            "Excellent examination and interpretation of visual elements!"    // 😃
          ]
        },
        {
          question: 'Did I mention the key insights and summarize the most important trends or patterns?',
          explanation: 'Highlight significant data points, trends (e.g., increase, decrease, consistency), and outliers (anomalies in the data) across all types of data visualizations.',
          messages: [
            "Needs significant improvement in summarizing key insights.", // 😢
            "Summary of trends and patterns is below expectations.",       // 🙁
            "Key insights mentioned satisfactorily.",                      // 😐
            "Good job highlighting important trends!",                     // 🙂
            "Excellent summary of key insights and trends!"                // 😃
          ]
        },
        {
          question: 'Did I compare data points, categories, or groups effectively?',
          explanation: 'Ensure you’ve compared relevant data points or categories, noting contrasts or similarities, whether it’s a bar chart, pie chart, table, or infographic.',
          messages: [
            "Needs significant improvement in comparing data effectively.", // 😢
            "Comparisons of data points are below expectations.",           // 🙁
            "Data compared satisfactorily.",                                // 😐
            "Good job comparing the data points!",                          // 🙂
            "Excellent and effective comparisons made!"                     // 😃
          ]
        },
        {
          question: 'Did I explain the data in its context?',
          explanation: 'Connect the data to the bigger picture. Explain why certain values are higher or lower, and mention any external factors (e.g., economic or social influences) that might have impacted the data.',
          messages: [
            "Needs significant improvement in explaining data context.", // 😢
            "Contextual explanation is below expectations.",             // 🙁
            "Data explained in context satisfactorily.",                 // 😐
            "Good job connecting data to its context!",                  // 🙂
            "Excellent explanation of data within its context!"          // 😃
          ]
        },
        {
          question: 'Did I evaluate the source and credibility of the data?',
          explanation: 'Ensure the data is from a reliable source and mention this in your analysis. Be critical of any potential biases or data limitations.',
          messages: [
            "Needs significant improvement in evaluating data sources.", // 😢
            "Evaluation of data credibility is below expectations.",     // 🙁
            "Data source evaluated satisfactorily.",                     // 😐
            "Good job assessing the data's credibility!",                // 🙂
            "Excellent evaluation of data sources and credibility!"      // 😃
          ]
        },
        {
          question: 'Did I consider the proportions, percentages, or absolute values?',
          explanation: 'Especially in pie charts and tables, ensure you’ve interpreted whether data is shown as percentages or absolute numbers, and checked if they add up logically.',
          messages: [
            "Needs significant improvement in considering data values.", // 😢
            "Consideration of proportions and percentages is below expectations.", // 🙁
            "Data values considered satisfactorily.",                    // 😐
            "Good job analyzing proportions and percentages!",           // 🙂
            "Excellent consideration of all data values!"                // 😃
          ]
        },
        {
          question: 'Did I interpret any patterns or trends?',
          explanation: 'Identify long-term patterns, seasonal trends, or fluctuations in the data and discuss their significance. For infographics, check how multiple data points work together to reveal broader insights.',
          messages: [
            "Needs significant improvement in interpreting patterns.", // 😢
            "Interpretation of trends is below expectations.",         // 🙁
            "Patterns and trends interpreted satisfactorily.",         // 😐
            "Good job identifying patterns in the data!",              // 🙂
            "Excellent interpretation of patterns and trends!"         // 😃
          ]
        },
        {
          question: 'Did I draw meaningful conclusions?',
          explanation: 'Conclude your analysis with a clear summary of what the data tells you. State the overall importance or significance of the findings.',
          messages: [
            "Needs significant improvement in drawing conclusions.", // 😢
            "Conclusions drawn are below expectations.",             // 🙁
            "Conclusions are satisfactory.",                         // 😐
            "Good job concluding your analysis!",                    // 🙂
            "Excellent and meaningful conclusions drawn!"            // 😃
          ]
        },
        {
          question: 'Did I mention any limitations or potential biases in the data or presentation?',
          explanation: 'Reflect on any potential issues with the data, such as missing information, unclear labels, or limited timeframe, and how these might affect your analysis.',
          messages: [
            "Needs significant improvement in addressing limitations.", // 😢
            "Mention of data limitations is below expectations.",        // 🙁
            "Limitations addressed satisfactorily.",                    // 😐
            "Good job discussing potential biases!",                    // 🙂
            "Excellent acknowledgment of data limitations and biases!"  // 😃
          ]
        }
      ],
      de: [
        {
          question: 'Habe ich das Thema identifiziert?',
          explanation: 'Stelle sicher, dass Du genau verstanden hast, worum es in dem Diagramm, der Grafik, der Tabelle oder der Infografik geht und welche Botschaft oder welches Ziel sie vermittelt.',
          messages: [
            "Thema wurde kaum erkannt, Verbesserung nötig.",         // 😢
            "Themaerkennung unter den Erwartungen.",                 // 🙁
            "Thema zufriedenstellend identifiziert.",                // 😐
            "Gute Arbeit bei der Identifikation des Themas!",        // 🙂
            "Ausgezeichnete Identifikation des Themas!"              // 😃
          ]
        },
        {
          question: 'Habe ich die Achsen, Sektoren oder Datenpunkte geprüft und interpretiert?',
          explanation: 'Überprüfe bei Diagrammen und Grafiken die Beschriftungen, Legenden und Achsen (x-Achse, y-Achse) auf wichtige Details. Bei Tabellen stelle sicher, dass Du die Zeilen und Spalten verstanden hast. Achte bei Infografiken auf Symbole und andere visuelle Hinweise.',
          messages: [
            "Verbesserung bei der Interpretation visueller Elemente nötig.", // 😢
            "Prüfung der Achsen und Datenpunkte unter den Erwartungen.",      // 🙁
            "Visuelle Elemente zufriedenstellend interpretiert.",            // 😐
            "Gute Arbeit bei der Interpretation der Achsen und Datenpunkte!", // 🙂
            "Ausgezeichnete Prüfung und Interpretation visueller Elemente!"  // 😃
          ]
        },
        {
          question: 'Habe ich die wichtigsten Erkenntnisse und die bedeutendsten Trends oder Muster genannt?',
          explanation: 'Hebe wichtige Datenpunkte, Trends (z. B. Anstieg, Rückgang, Beständigkeit) und Ausreißer (Anomalien in den Daten) hervor, unabhängig vom verwendeten Datentyp.',
          messages: [
            "Verbesserung bei der Zusammenfassung wichtiger Erkenntnisse nötig.", // 😢
            "Nennung von Trends und Mustern unter den Erwartungen.",              // 🙁
            "Wichtige Erkenntnisse zufriedenstellend genannt.",                   // 😐
            "Gute Arbeit beim Hervorheben wichtiger Trends!",                     // 🙂
            "Ausgezeichnete Zusammenfassung der wichtigsten Erkenntnisse!"        // 😃
          ]
        },
        {
          question: 'Habe ich Datenpunkte, Kategorien oder Gruppen effektiv verglichen?',
          explanation: 'Stelle sicher, dass Du relevante Datenpunkte oder Kategorien verglichen hast, und hebe Unterschiede oder Gemeinsamkeiten hervor, egal ob es sich um ein Balkendiagramm, ein Kreisdiagramm, eine Tabelle oder eine Infografik handelt.',
          messages: [
            "Verbesserung beim effektiven Vergleichen von Daten nötig.", // 😢
            "Vergleiche von Datenpunkten unter den Erwartungen.",         // 🙁
            "Daten zufriedenstellend verglichen.",                       // 😐
            "Gute Arbeit beim Vergleichen der Datenpunkte!",             // 🙂
            "Ausgezeichnete und effektive Vergleiche vorgenommen!"       // 😃
          ]
        },
        {
          question: 'Habe ich die Daten im Kontext erklärt?',
          explanation: 'Setze die Daten in den größeren Zusammenhang. Erkläre, warum bestimmte Werte höher oder niedriger sind, und erwähne externe Faktoren (z. B. wirtschaftliche oder soziale Einflüsse), die die Daten beeinflusst haben könnten.',
          messages: [
            "Verbesserung bei der Kontextualisierung der Daten nötig.", // 😢
            "Erklärung des Datenkontexts unter den Erwartungen.",       // 🙁
            "Daten zufriedenstellend im Kontext erklärt.",             // 😐
            "Gute Arbeit beim Erklären der Daten im Kontext!",         // 🙂
            "Ausgezeichnete Erklärung der Daten innerhalb des Kontexts!" // 😃
          ]
        },
        {
          question: 'Habe ich die Quelle und Glaubwürdigkeit der Daten bewertet?',
          explanation: 'Stelle sicher, dass die Daten aus einer zuverlässigen Quelle stammen, und erwähne dies in Deiner Analyse. Sei kritisch gegenüber möglichen Verzerrungen oder Datenbeschränkungen.',
          messages: [
            "Verbesserung bei der Bewertung der Datenquellen nötig.", // 😢
            "Bewertung der Datenvertrauenswürdigkeit unter den Erwartungen.", // 🙁
            "Datenquelle zufriedenstellend bewertet.",                // 😐
            "Gute Arbeit bei der Einschätzung der Datenvertrauenswürdigkeit!", // 🙂
            "Ausgezeichnete Bewertung der Datenquellen und deren Glaubwürdigkeit!" // 😃
          ]
        },
        {
          question: 'Habe ich die Anteile, Prozente oder absoluten Werte berücksichtigt?',
          explanation: 'Insbesondere bei Kreisdiagrammen und Tabellen solltest Du prüfen, ob die Daten als Prozentsätze oder absolute Zahlen dargestellt werden, und überprüfen, ob diese logisch aufgehen.',
          messages: [
            "Verbesserung bei der Berücksichtigung von Datenwerten nötig.", // 😢
            "Berücksichtigung von Anteilen und Prozenten unter den Erwartungen.", // 🙁
            "Datenwerte zufriedenstellend berücksichtigt.",                // 😐
            "Gute Arbeit bei der Analyse von Anteilen und Prozenten!",     // 🙂
            "Ausgezeichnete Berücksichtigung aller Datenwerte!"            // 😃
          ]
        },
        {
          question: 'Habe ich Muster oder Trends interpretiert?',
          explanation: 'Identifiziere langfristige Muster, saisonale Trends oder Schwankungen in den Daten und diskutiere deren Bedeutung. Bei Infografiken prüfe, wie verschiedene Datenpunkte zusammen ein breiteres Bild ergeben.',
          messages: [
            "Verbesserung bei der Interpretation von Mustern nötig.", // 😢
            "Interpretation von Trends unter den Erwartungen.",       // 🙁
            "Muster und Trends zufriedenstellend interpretiert.",     // 😐
            "Gute Arbeit bei der Identifizierung von Mustern in den Daten!", // 🙂
            "Ausgezeichnete Interpretation von Mustern und Trends!"   // 😃
          ]
        },
        {
          question: 'Habe ich sinnvolle Schlussfolgerungen gezogen?',
          explanation: 'Schließe Deine Analyse mit einer klaren Zusammenfassung dessen ab, was die Daten Dir mitteilen. Betone die Bedeutung oder Relevanz der Erkenntnisse.',
          messages: [
            "Verbesserung beim Ziehen von Schlussfolgerungen nötig.", // 😢
            "Gezogene Schlussfolgerungen unter den Erwartungen.",     // 🙁
            "Schlussfolgerungen zufriedenstellend gezogen.",          // 😐
            "Gute Arbeit beim Abschluss Deiner Analyse!",             // 🙂
            "Ausgezeichnete und sinnvolle Schlussfolgerungen gezogen!" // 😃
          ]
        },
        {
          question: 'Habe ich mögliche Einschränkungen oder Verzerrungen in den Daten oder der Präsentation erwähnt?',
          explanation: 'Überlege, ob es Probleme mit den Daten gibt (z. B. fehlende Informationen, unklare Beschriftungen oder einen begrenzten Zeitraum), und wie diese Deine Analyse beeinflussen könnten.',
          messages: [
            "Verbesserung beim Ansprechen von Einschränkungen nötig.",  // 😢
            "Erwähnung von Datenbeschränkungen unter den Erwartungen.", // 🙁
            "Einschränkungen zufriedenstellend erwähnt.",               // 😐
            "Gute Arbeit beim Diskutieren potenzieller Verzerrungen!",  // 🙂
            "Ausgezeichnete Anerkennung von Datenbeschränkungen und Verzerrungen!" // 😃
          ]
        }
      ]
    },
    sliderCategories: [
      {
        id: 'content',
        categoryTitle: {
          en: 'Content',
          de: 'Inhalt',
        },
        sliders: [
          {
            id: 'content-task-fulfillment',
            label: {
              en: 'Task Fulfillment',
              de: 'Aufgabenerfüllung',
            },
            messages: {
              en: (value) => {
                if (value >= 0 && value < 20) return "Insufficient selection of aspects, lack of task relevance.";
                if (value >= 20 && value < 40) return "Hardly considering relevant aspects, hardly appropriate.";
                if (value >= 40 && value < 55) return "Considering less relevant aspects, however, correct to some extent.";
                if (value >= 55 && value < 70) return "Taking into account several relevant aspects, essentially correct.";
                if (value >= 70 && value < 85) return "Considering relevant aspects to a large extent, largely correct.";
                if (value >= 85 && value <= 100) return "Comprehensive and correct.";
              },
              de: (value) => {
                if (value >= 0 && value < 20) return "Unzureichende Auswahl von Aspekten, Aufgabenbezug fehlend.";
                if (value >= 20 && value < 40) return "Kaum relevante Aspekte berücksichtigend, kaum angemessen.";
                if (value >= 40 && value < 55) return "Weniger relevante Aspekte berücksichtigend, ansatzweise korrekt.";
                if (value >= 55 && value < 70) return "Mehrere relevante Aspekte berücksichtigend, im Wesentlichen korrekt.";
                if (value >= 70 && value < 85) return "Relevante Aspekte weitgehend berücksichtigend, weitgehend korrekt.";
                if (value >= 85 && value <= 100) return "Umfassend und korrekt.";
              },
            },
          },
          {
            id: 'content-text-interpretation',
            label: {
              en: 'Interpretation of the text, taking into account its stylistic devices',
              de: 'Deutung des Textes unter Berücksichtigung seiner Gestaltungsmittel',
            },
            messages: {
            en: (value) => {
                if (value >= 0 && value < 20) return "Missing or no longer comprehensible.";
                if (value >= 20 && value < 40) return "Barely comprehensible or no longer logical.";
                if (value >= 40 && value < 55) return "Still comprehensible and logical.";
                if (value >= 55 && value < 70) return "Comprehensible and essentially logical.";
                if (value >= 70 && value < 85) return "Largely logical.";
                if (value >= 85 && value <= 100) return "Consistently logical.";
              },
              de: (value) => {
                if (value >= 0 && value < 20) return "Fehlend oder nicht mehr nachvollziehbar.";
                if (value >= 20 && value < 40) return "Kaum nachvollziehbar bzw. nicht mehr folgerichtig.";
                if (value >= 40 && value < 55) return "Noch nachvollziehbar und folgerichtig.";
                if (value >= 55 && value < 70) return "Nachvollziehbar und im Wesentlichen folgerichtig.";
                if (value >= 70 && value < 85) return "Weitgehend folgerichtig.";
                if (value >= 85 && value <= 100) return "Durchgängig folgerichtig.";
              },
            },
          },
          {
            id: 'content-text-reference-precision-differentiation',
            label: {
              en: 'Textual reference (Precision and differentiation)',
              de: 'Bezugnahme auf den Text (Präzision und Differenziertheit)',
            },
            messages: {
              en: (value) => {
if (value >= 0 && value < 20) return "Incorrect or missing.";
if (value >= 20 && value < 40) return "Barely present or mostly inaccurate.";
if (value >= 40 && value < 55) return "Somewhat accurate.";
if (value >= 55 && value < 70) return "Largely accurate and nuanced.";
if (value >= 70 && value < 85) return "Mostly precise and nuanced.";
if (value >= 85 && value <= 100) return "Consistently precise and nuanced.";
              },
              de: (value) => {
                if (value >= 0 && value < 20) return "Falsch bzw. fehlend.";
                if (value >= 20 && value < 40) return "Kaum vorhanden bzw. wenig treffend.";
                if (value >= 40 && value < 55) return "Ansatzweise treffend.";
                if (value >= 55 && value < 70) return "Im Wesentlichen treffend und differenziert.";
                if (value >= 70 && value < 85) return "Weitgehend präzise und differenziert.";
                if (value >= 85 && value <= 100) return "durchgängig präzise und differenziert.";
              },
            },
          },
          {
            id: 'content-text-reference-textual-evidence-quotations',
            label: {
              en: 'Textual reference (Textual evidence and quotations)',
              de: 'Bezugnahme auf den Text (Textbelege und Zitate)',
            },
            messages: {
              en: (value) => {
                if (value >= 0 && value < 20) return "Inappropriate textual evidence and quotations.";
                if (value >= 20 && value < 40) return "Mostly inappropriate textual evidence and quotations.";
                if (value >= 40 && value < 55) return "Still appropriate and functional textual evidence and quotations.";
                if (value >= 55 && value < 70) return "Largely appropriate and functional textual evidence and quotations.";
                if (value >= 70 && value < 85) return "Mostly appropriate and functional textual evidence and quotations.";
                if (value >= 85 && value <= 100) return "Consistently appropriate and functional textual evidence and quotations.";
              },

              de: (value) => {
                if (value >= 0 && value < 20) return "Nicht sachgemäße Textbelege und Zitate.";
                if (value >= 20 && value < 40) return "Wenig sachgemäße Textbelege und Zitate.";
                if (value >= 40 && value < 55) return "Noch sachgemäße und funktionale Textbelege und Zitate.";
                if (value >= 55 && value < 70) return "Im Wesentlichen sachgemäße und funktionale Textbelege und Zitate.";
                if (value >= 70 && value < 85) return "Weitgehend sachgemäße und funktionale Textbelege und Zitate.";
                if (value >= 85 && value <= 100) return "Durchgängig sachgemäße und funktionale Textbelege und Zitate.";
              },
            },
          },
          {
            id: 'content-content-organisation-consistency-coherence',
            label: {
              en: 'Content Structure: Organization, Consistency, Coherence',
              de: 'Inhaltlicher Aufbau: Struktur, Stringenz, Kohärenz',
            },
            messages: {
              en: (value) => {
                if (value >= 0 && value < 20) return "Unstructured, redundant, incomprehensible, incoherent.";
                if (value >= 20 && value < 40) return "Barely structured, often redundant, hardly comprehensible or coherent.";
                if (value >= 40 && value < 55) return "Somewhat structured, repeatedly redundant, somewhat logical, still coherent.";
                if (value >= 55 && value < 70) return "Largely structured and free of redundancy, largely logical and coherent.";
                if (value >= 70 && value < 85) return "Structured, mostly free of redundancy, mostly logical and coherent.";
                if (value >= 85 && value <= 100) return "Clearly structured, free of redundancy, highly logical, consistently coherent.";
              },

              de: (value) => {
                if (value >= 0 && value < 20) return "Unstrukturiert, redundant, nicht nachvollziehbar, inkohärent.";
                if (value >= 20 && value < 40) return "Kaum strukturiert, oft redundant, kaum nachvollziehbar bzw. kaum noch kohärent.";
                if (value >= 40 && value < 55) return "Ansatzweise strukturiert, wiederholt redundant, ansatzweise schlüssig, noch kohärent.";
                if (value >= 55 && value < 70) return "Im Wesentlichen strukturiert und redundanzfrei, im Wesentlichen schlüssig und kohärent.";
                if (value >= 70 && value < 85) return "Strukturiert, weitgehend redundanzfrei, weitgehend schlüssig und kohärent.";
                if (value >= 85 && value <= 100) return "Klar strukturiert, redundanzfrei, in hohem Maße schlüssig, durchgängig kohärent.";
              },
            },
          },
        ],
      },
      {
        id: 'language',
        categoryTitle: {
          en: 'Language',
          de: 'Sprache',
        },
        sliders: [
          {
            id: 'language-lexical-range',
            label: {
              en: 'Lexical Range',
              de: 'Lexikalische Bandbreite',
            },
            messages: {
              en: (value) => {
                if (value >= 0 && value < 20) return "Not an appropriate choice of words.";
                if (value >= 20 && value < 40) return "Clearly limited choice of words.";
                if (value >= 40 && value < 55) return "Restricted, still appropriate choice of words.";
                if (value >= 55 && value < 70) return "Essentially precise and variable choice of words.";
                if (value >= 70 && value < 85) return "Largely precise, variable, with some target-language expressions.";
                if (value >= 85 && value <= 100) return "Highly precise and variable choice of words.";
              },
              de: (value) => {
                if (value >= 0 && value < 20) return "Keine angemessene Wortwahl.";
                if (value >= 20 && value < 40) return "Deutlich eingeschränkte Wortwahl.";
                if (value >= 40 && value < 55) return "Eingeschränkte, noch angemessene Wortwahl.";
                if (value >= 55 && value < 70) return "Im Wesentlichen präzise und variable Wortwahl.";
                if (value >= 70 && value < 85) return "Weitgehend präzise und variable Wortwahl.";
                if (value >= 85 && value <= 100) return "In hohem Maße präzise und variable Wortwahl.";
              },
            },
          },
          {
            id: 'language-lexical-accuracy',
            label: {
              en: 'Lexical Accuracy',
              de: 'Lexikalische Korrektheit',
            },
            messages: {
              en: (value) => {
                if (value >= 0 && value < 20) return "Lexically incorrect; no longer comprehensible.";
                if (value >= 20 && value < 40) return "Often lexically incorrect; comprehensibility severely impaired.";
                if (value >= 40 && value < 55) return "Partially lexically incorrect; comprehensibility impaired.";
                if (value >= 55 && value < 70) return "Essentially lexically correct; slightly limited comprehensibility.";
                if (value >= 70 && value < 85) return "Largely lexically correct; comprehensibility not impaired by individual inaccuracies.";
                if (value >= 85 && value <= 100) return "Highly lexically correct; comprehensible throughout.";
              },
              de: (value) => {
                if (value >= 0 && value < 20) return "Lexikalisch unkorrekt; Verständlichkeit nicht gegeben.";
                if (value >= 20 && value < 40) return "Oft lexikalisch unkorrekt; Verständlichkeit stark beeinträchtigt.";
                if (value >= 40 && value < 55) return "Teilweise lexikalisch unkorrekt; Verständlichkeit eingeschränkt.";
                if (value >= 55 && value < 70) return "Im Wesentlichen lexikalisch korrekt; Verständlichkeit geringfügig beeinträchtigt.";
                if (value >= 70 && value < 85) return "Weitgehend lexikalisch korrekt; Verständlichkeit nicht beeinträchtigt.";
                if (value >= 85 && value <= 100) return "In hohem Maße lexikalisch korrekt; durchgängig verständlich.";
              },
            },
          },
          {
            id: 'language-grammatical-range',
            label: {
              en: 'Grammatical Range',
              de: 'Grammatische Bandbreite',
            },
            messages: {
              en: (value) => {
                if (value >= 0 && value < 20) return "Sentence structure and forms inadequate.";
                if (value >= 20 && value < 40) return "Sentence structure and forms barely appropriate.";
                if (value >= 40 && value < 55) return "Limited variety in sentence structure; still appropriate forms.";
                if (value >= 55 && value < 70) return "Largely varied sentence structure; largely functional use of chosen forms.";
                if (value >= 70 && value < 85) return "Mostly varied sentence structure; mostly functional use of chosen forms.";
                if (value >= 85 && value <= 100) return "Highly varied sentence structure; consistently functional use of chosen forms.";
              },
              de: (value) => {
                if (value >= 0 && value < 20) return "Satzbau und Strukturen unzulänglich.";
                if (value >= 20 && value < 40) return "Satzbau und Strukturen kaum angemessen.";
                if (value >= 40 && value < 55) return "Wenig variabler Satzbau; noch angemessene Strukturen.";
                if (value >= 55 && value < 70) return "Im Wesentlichen variabler Satzbau; im Wesentlichen funktionaler Einsatz der gewählten Strukturen.";
                if (value >= 70 && value < 85) return "Weitgehend variabler Satzbau; weitgehend funktionaler Einsatz der gewählten Strukturen.";
                if (value >= 85 && value <= 100) return "In hohem Maße variabler Satzbau; durchgängig funktionaler Einsatz der gewählten Strukturen.";
              },
            },
          },
                    {
            id: 'language-grammatical-accuracy',
            label: {
              en: 'Grammatical Accuracy',
              de: 'Grammatische Korrektheit',
            },
            messages: {
              en: (value) => {
                if (value >= 0 && value < 20) return "Sentence structure and structures inadequate.";
                if (value >= 20 && value < 40) return "Sentence structure and structures barely adequate.";
                if (value >= 40 && value < 55) return "Less variable sentence structure; still appropriate structures.";
                if (value >= 55 && value < 70) return "Essentially variable sentence structure.";
                if (value >= 70 && value < 85) return "Largely variable sentence structure.";
                if (value >= 85 && value <= 100) return "Highly variable sentence structure.";
              },
              de: (value) => {
                if (value >= 0 && value < 20) return "Grammatikalisch unkorrekt; Verständlichkeit nicht mehr gegeben.";
                if (value >= 20 && value < 40) return "Häufig grammatikalisch unkorrekt; Verständlichkeit stark beeinträchtigt.";
                if (value >= 40 && value < 55) return "Teilweise grammatikalisch unkorrekt; Verständlichkeit stellenweise beeinträchtigt.";
                if (value >= 55 && value < 70) return "Im Wesentlichen grammatikalisch korrekt; Verständlichkeit geringfügig eingeschränkt.";
                if (value >= 70 && value < 85) return "Weitgehend grammatikalisch korrekt; einzelne Ungenauigkeiten beeinträchtigen die Verständlichkeit nicht.";
                if (value >= 85 && value <= 100) return "In hohem Maße grammatikalisch korrekt; durchgängig verständlich.";
              },
            },
          },
                    {
            id: 'language-text-composition-text-structure',
            label: {
              en: 'Text composition: Text structure',
              de: 'Textgestaltung: Textaufbau',
            },
            messages: {
          en: (value) => {
                if (value >= 0 && value < 20) return "No purposeful text, no use of text-structuring devices.";
                if (value >= 20 && value < 40) return "Barely purposeful text, minimal use of text-structuring devices (cohesion).";
                if (value >= 40 && value < 55) return "Somewhat purposeful text with limited functional use of text-structuring devices (cohesion).";
                if (value >= 55 && value < 70) return "Largely purposeful text with mostly functional use of text-structuring devices (cohesion).";
                if (value >= 70 && value < 85) return "Mostly purposeful text with functional use of text-structuring devices (cohesion).";
                if (value >= 85 && value <= 100) return "Highly purposeful text with consistently functional use of text-structuring devices (cohesion).";
              },
              de: (value) => {
                if (value >= 0 && value < 20) return "Kein zielgerichteter Text, keine textstrukturierenden Mittel.";
                if (value >= 20 && value < 40) return "Kaum zielgerichteter Text, kaum textstrukturierende Mittel (Kohäsion).";
                if (value >= 40 && value < 55) return "Ansatzweise zielgerichteter Text mit eingeschränkt funktionalem Einsatz textstrukturierender Mittel (Kohäsion).";
                if (value >= 55 && value < 70) return "Im Wesentlichen zielgerichteter Text mit überwiegend funktionalem Einsatz textstrukturierender Mittel (Kohäsion).";
                if (value >= 70 && value < 85) return "weitgehend zielgerichteter Text mit funktionalem Einsatz textstrukturierender Mittel (Kohäsion).";
                if (value >= 85 && value <= 100) return "In hohem Maße zielgerichteter Text mit durchgängig funktionalem Einsatz textstrukturierender Mittel (Kohäsion).";
              },
            },
          },
                    {
            id: 'language-text-composition-appropriateness',
            label: {
              en: 'Text composition: Appropriateness',
              de: 'Textgestaltung: Angemessenheit',
            },
            messages: {
              en: (value) => {
                if (value >= 0 && value < 20) return "No implementation of the required linguistic genre characteristics; no appropriate language use for the context and audience; no independent language style.";
                if (value >= 20 && value < 40) return "Minimal implementation of the required linguistic genre characteristics; minimal appropriate language use for the context and audience; limited independent language style.";
                if (value >= 40 && value < 55) return "Partial implementation of the required linguistic genre characteristics; somewhat appropriate language use for the context and audience; somewhat independent language style.";
                if (value >= 55 && value < 70) return "Largely implements the required linguistic genre characteristics; largely appropriate language use for the context and audience; largely independent language style.";
                if (value >= 70 && value < 85) return "Broad implementation of the required linguistic genre characteristics; largely appropriate language use for the context and audience; largely independent language style.";
                if (value >= 85 && value <= 100) return "Extensive implementation of the required linguistic genre characteristics; highly appropriate language use for the context and audience; highly independent language style.";
              },
              de: (value) => {
                if (value >= 0 && value < 20) return "Fehlende Umsetzung der geforderten sprachlichen Textsortenmerkmale; keine situations- und adressatengerechte Sprachverwendung; keine eigenständige sprachliche Gestaltung.";
                if (value >= 20 && value < 40) return "Kaum Umsetzung der geforderten sprachlichen Textsortenmerkmale; kaum situations- und adressatengerechte Sprachverwendung; wenig eigenständige sprachliche Gestaltung.";
                if (value >= 40 && value < 55) return "Ansatzweise Umsetzung der geforderten sprachlichen Textsortenmerkmale; ansatzweise situations- und adressatengerechte Sprachverwendung; ansatzweise eigenständige sprachliche Gestaltung.";
                if (value >= 55 && value < 70) return "Im Wesentlichen Umsetzung der geforderten sprachlichen Textsortenmerkmale; im Wesentlichen situations- und adressatengerechte Sprachverwendung; im Wesentlichen eigenständige sprachliche Gestaltung.";
                if (value >= 70 && value < 85) return "Weitgehende Umsetzung der geforderten sprachlichen Textsortenmerkmale; weitgehend situations- und adressatengerechte Sprachverwendung; weitgehend eigenständige sprachliche Gestaltung.";
                if (value >= 85 && value <= 100) return "In hohem Maße Umsetzung der geforderten sprachlichen Textsortenmerkmale; in hohem Maße situations- und adressatengerechte Sprachverwendung; in hohem Maße eigenständige sprachliche Gestaltung.";
              },
            },
          },
        ],
      },
    ],
  },
    {
    id: 3,
    title: {
      en: 'Analysing fiction',
      de: 'Fiktionale Texte analysieren',
    },
    shorttitle: {
      en: 'Fiction analysis',
      de: 'Analyse fiktionaler Texte',
    },
    content: {
      en: 'Analysis',
      de: 'Analyse',
    },
    categories: [
      {
        title: {
          en: 'Basics',
          de: 'Grundlagen',
        },
        description: {
          en: 'Fundamental information on how to start.',
          de: 'Grundlegende Informationen zum Einstieg.',
        },
        details: {
en: [
  <>
    <p>
      Fiction is a narrative art form that explores themes, emotions, and ideas through crafted characters, plots, and settings. The term "fiction" stems from the Latin word "fictio," meaning "a shaping, a counterfeiting."
    </p>
    <p>
      Fictional analysis involves examining several core elements to understand deeper meanings and artistic choices. Here, we will focus on four key components:
    </p>
    <div className="flex-row">
      <img src={CharacterIllustration} className="illustrateSVG" alt="Character Illustration" />
            <div className="flex-column">
        <strong>Characters</strong>
        <p>
          Characters are the individuals who drive the narrative forward, each with unique motivations, flaws, and personalities. In fiction, analyzing characters involves looking at their relationships, development, and conflicts, as well as how they reflect larger themes.
        </p>
                </div>
    </div>
    <div className="flex-row">
          <img src={PlotIllustration} className="illustrateSVG" alt="Plot Illustration" />
                <div className="flex-column">
        <strong>Plot</strong>
        <p>
          The plot is the sequence of events that shape the story, involving a series of conflicts, resolutions, and turning points. Examining the plot reveals how events build tension, lead to climax, and resolve, giving insight into the story’s core messages.
        </p>
                </div>
    </div>
    <div className="flex-row">
              <img src={SettingIllustration} className="illustrateSVG" alt="Setting Illustration" />
                    <div className="flex-column">
        <strong>Setting</strong>
        <p>
          The setting includes the time, place, and environment in which the story unfolds. Understanding the setting helps in recognizing how context influences characters’ behaviors and the overall mood, as well as grounding the story within a particular cultural or historical framework.
        </p>
                </div>
    </div>
    <div className="flex-row">
                  <img src={ThemeIllustration} className="illustrateSVG" alt="Theme Illustration" />
                        <div className="flex-column">
        <strong>Themes and Motifs</strong>
        <p>
          Themes are the overarching ideas or messages the author wishes to convey, often supported by recurring motifs or symbols. Analyzing themes and motifs provides a lens for understanding the author’s insights on human nature, society, and other philosophical ideas.
        </p>
                </div>
    </div>
    <p>
      Analyzing fiction means delving into these elements, exploring character motivations, narrative structures, and the author's underlying messages within the cultural and historical context.
    </p>
  </>
],
de: [
  <>
    <p>
      Fiktion ist eine erzählende Kunstform, die durch gestaltete Figuren, Handlungen und Schauplätze Themen, Emotionen und Ideen erkundet. Der Begriff "Fiktion" stammt vom lateinischen Wort "fictio", was so viel wie "Gestaltung" oder "Erdichtung" bedeutet.
    </p>
    <p>
      Die Analyse von Fiktion beinhaltet die Untersuchung verschiedener Kernelemente, um tiefere Bedeutungen und künstlerische Entscheidungen zu verstehen. Hier konzentrieren wir uns auf vier wesentliche Komponenten:
    </p>
    <div className="flex-row">
      <img src={CharacterIllustration} className="illustrateSVG" alt="Figuren-Illustration" />
      <div className="flex-column">
        <strong>Figuren</strong>
        <p>
          Figuren sind die Individuen, die die Handlung vorantreiben und jede mit einzigartigen Motiven, Schwächen und Persönlichkeiten ausgestattet sind. Die Analyse von Figuren beinhaltet das Verständnis ihrer Beziehungen, Entwicklungen und Konflikte und wie sie größere Themen widerspiegeln.
        </p>
        </div>
    </div>
    <div className="flex-row">
      <img src={PlotIllustration} className="illustrateSVG" alt="Handlungs-Illustration" />
      <div className="flex-column">
        <strong>Handlung</strong>
        <p>
          Die Handlung ist die Abfolge von Ereignissen, die die Geschichte formen und aus einer Reihe von Konflikten, Wendepunkten und Lösungen bestehen. Eine Analyse der Handlung zeigt, wie Ereignisse Spannung aufbauen, zum Höhepunkt führen und sich auflösen, wodurch die Kernaussagen der Geschichte verdeutlicht werden.
        </p>
        </div>
    </div>
    <div className="flex-row">
      <img src={SettingIllustration} className="illustrateSVG" alt="Schauplatz-Illustration" />
            <div className="flex-column">
        <strong>Schauplatz</strong>
        <p>
          Der Schauplatz umfasst die Zeit, den Ort und das Umfeld, in dem die Geschichte spielt. Ein Verständnis des Schauplatzes hilft dabei zu erkennen, wie der Kontext das Verhalten der Figuren und die gesamte Stimmung beeinflusst und die Geschichte in einen kulturellen oder historischen Rahmen einbettet.
        </p>
        </div>
    </div>
    <div className="flex-row">
      <img src={ThemeIllustration} className="illustrateSVG" alt="Themen-Illustration" />
        <div className="flex-column">
        <strong>Themen und Motive</strong>
        <p>
          Themen sind die übergeordneten Ideen oder Botschaften, die der Autor vermitteln möchte, oft durch wiederkehrende Motive oder Symbole unterstützt. Die Analyse von Themen und Motiven bietet eine Möglichkeit, die Einsichten des Autors in die menschliche Natur, Gesellschaft und andere philosophische Ideen zu verstehen.
        </p>
        </div>
    </div>
    <p>
      Fiktion zu analysieren bedeutet, sich mit diesen Elementen auseinanderzusetzen, die Motivationen der Figuren, die Struktur der Erzählung und die zugrunde liegenden Botschaften des Autors im kulturellen und historischen Kontext zu erforschen.
    </p>
  </>
]
        }
      },  
{
  title: {
    en: 'Characters',
    de: 'Charaktere',
  },
  description: {
    en: 'Understanding personalities and motivations.',
    de: 'Persönlichkeiten und Motivationen verstehen.',
  },
  details: {
    en: [
      <>
        <h4>Why is this category important?</h4>
        <p>Characters are the core of any fictional narrative; they carry the plot forward, embody the themes, and create emotional resonance. By understanding characters, readers gain insights into motivations, conflicts, and relationships, making the story more engaging and meaningful.</p>
        
        <h4>What do we need to focus on?</h4>
        <p>When analyzing characters, focus on their <strong>motivations</strong>, <strong>personality traits</strong>, <strong>relationships with others</strong>, <strong>development throughout the story</strong>, and <strong>symbolic or thematic roles</strong>. Look for specific actions, dialogue, and descriptions that reveal who the characters are and what drives them.</p>
        
        <h4>How do we analyze this category?</h4>
        <ol>
          <li><strong>Identify character traits:</strong> What qualities define the character? Are they brave, hesitant, ambitious?</li>
          <li><strong>Examine motivations:</strong> Why does the character make certain choices? Look for underlying needs or desires.</li>
          <li><strong>Consider relationships:</strong> How do they interact with other characters? How do these relationships evolve?</li>
          <li><strong>Track development:</strong> Note any changes in the character over time. Are they transformed by their experiences?</li>
          <li><strong>Connect to themes:</strong> How does the character’s journey reflect broader themes in the story?</li>
        </ol>
        
        <h4>Which phrases can we use?</h4>
        <ul>
          <li>"The character is portrayed as..."</li>
          <li>"Throughout the story, we see [character]..."</li>
          <li>"Their relationship with [other character] reveals..."</li>
          <li>"This behavior suggests that [character] is..."</li>
          <li>"[Character] symbolizes the theme of..."</li>
        </ul>
        
        <h4>Model Paragraph</h4>
        <p>In "To Kill a Mockingbird," Atticus Finch is portrayed as a moral compass for his children and the community. His calm demeanor, wisdom, and unwavering commitment to justice highlight his role as a moral leader. Throughout the story, we see how Atticus guides Scout and Jem to understand empathy and integrity, most notably through his famous advice, “You never really understand a person until you consider things from his point of view.” His relationship with his children reveals his compassionate nature and belief in equality, embodying the novel’s central theme of racial justice.</p>
      </>
    ],
    de: [
      <>
        <h4>Warum ist diese Kategorie wichtig?</h4>
        <p>Charaktere sind das Herzstück jeder erzählerischen Handlung; sie treiben die Geschichte voran, verkörpern die Themen und erzeugen emotionale Resonanz. Durch das Verstehen der Charaktere erhalten Leser Einblicke in deren Motivationen, Konflikte und Beziehungen, was die Geschichte ansprechender und bedeutsamer macht.</p>
        
        <h4>Worauf müssen wir achten?</h4>
        <p>Beim Analysieren von Charakteren konzentriere dich auf ihre <strong>Motivationen</strong>, <strong>Persönlichkeitsmerkmale</strong>, <strong>Beziehungen zu anderen</strong>, <strong>Entwicklung im Verlauf der Geschichte</strong> und <strong>symbolische oder thematische Rollen</strong>. Achte auf spezifische Handlungen, Dialoge und Beschreibungen, die zeigen, wer die Charaktere sind und was sie antreibt.</p>
        
        <h4>Wie analysieren wir diese Kategorie?</h4>
        <ol>
          <li><strong>Charaktereigenschaften identifizieren:</strong> Welche Qualitäten definieren den Charakter? Sind sie mutig, zögerlich, ehrgeizig?</li>
          <li><strong>Motivationen untersuchen:</strong> Warum trifft der Charakter bestimmte Entscheidungen? Achte auf zugrunde liegende Bedürfnisse oder Wünsche.</li>
          <li><strong>Beziehungen berücksichtigen:</strong> Wie interagieren sie mit anderen Charakteren? Wie entwickeln sich diese Beziehungen?</li>
          <li><strong>Entwicklung verfolgen:</strong> Beachte alle Veränderungen im Charakter im Laufe der Zeit. Werden sie durch ihre Erfahrungen verändert?</li>
          <li><strong>Verbindung zu Themen herstellen:</strong> Wie spiegelt die Reise des Charakters breitere Themen in der Geschichte wider?</li>
        </ol>
        
        <h4>Welche Formulierungen können wir verwenden?</h4>
        <ul>
          <li>"Der Charakter wird dargestellt als..."</li>
          <li>"Im Verlauf der Geschichte sehen wir [Charakter]..."</li>
          <li>"Ihre Beziehung zu [anderem Charakter] zeigt..."</li>
          <li>"Dieses Verhalten deutet darauf hin, dass [Charakter]..."</li>
          <li>"[Charakter] symbolisiert das Thema von..."</li>
        </ul>
        
        <h4>Beispielabsatz</h4>
        <p>In "Wer die Nachtigall stört" wird Atticus Finch als moralischer Kompass für seine Kinder und die Gemeinschaft dargestellt. Sein ruhiges Auftreten, seine Weisheit und sein unerschütterliches Engagement für Gerechtigkeit unterstreichen seine Rolle als moralische Führungspersönlichkeit. Im Verlauf der Geschichte sehen wir, wie Atticus Scout und Jem lehrt, Empathie und Integrität zu verstehen, besonders durch seinen berühmten Rat: „Man versteht einen Menschen nie wirklich, bis man die Dinge aus seiner Perspektive betrachtet.“ Seine Beziehung zu seinen Kindern zeigt seine mitfühlende Natur und seinen Glauben an Gleichheit und verkörpert damit das zentrale Thema der Rassengerechtigkeit im Roman.</p>
      </>
    ]
  }
},
{
  title: {
    en: 'Plot',
    de: 'Handlung',
  },
  description: {
    en: 'Structure of events that forms the storyline.',
    de: 'Struktur der Ereignisse, die die Handlung bildet.',
  },
  details: {
    en: [
      <>
        <h4>Why is this category important?</h4>
        <p>The plot is the structure of events that forms the storyline. Analyzing the plot helps readers understand how tension builds, how conflicts are resolved, and how the story's themes are conveyed through actions and outcomes.</p>
        
        <h4>What do we need to focus on?</h4>
        <p>Focus on the <strong>sequence of events</strong>, <strong>conflicts</strong>, <strong>climaxes</strong>, <strong>turning points</strong>, and <strong>resolution</strong>. Pay attention to how each event connects to the next and how the progression of the plot highlights the story’s themes.</p>
        
        <h4>How do we analyze this category?</h4>
        <ol>
          <li><strong>Outline key events:</strong> Identify the main actions or turning points that drive the story forward.</li>
          <li><strong>Identify conflicts:</strong> Look for struggles the protagonist faces, whether internal or external.</li>
          <li><strong>Highlight the climax:</strong> Find the peak moment of tension where everything comes to a head.</li>
          <li><strong>Analyze the resolution:</strong> Observe how conflicts are resolved and what message this conveys.</li>
          <li><strong>Connect to themes:</strong> Consider how the structure and flow of events underline the story's message.</li>
        </ol>
        
        <h4>Which phrases can we use?</h4>
        <ul>
          <li>"The plot follows a structure where..."</li>
          <li>"The climax occurs when..."</li>
          <li>"The conflict between [character] and [character] shows..."</li>
          <li>"In the resolution, we see that..."</li>
          <li>"The sequence of events suggests that..."</li>
        </ul>
        
        <h4>Model Paragraph</h4>
        <p>In “The Great Gatsby,” the plot revolves around Jay Gatsby’s attempts to reclaim his lost love, Daisy Buchanan. The story builds through Gatsby’s lavish parties and mysterious background, culminating in a tragic climax where his dream is shattered as Daisy returns to her wealthy but morally indifferent husband, Tom. The resolution reveals the emptiness of Gatsby's quest, highlighting themes of the American Dream and social class divisions.</p>
      </>
    ],
    de: [
      <>
        <h4>Warum ist diese Kategorie wichtig?</h4>
        <p>Die Handlung ist die Struktur der Ereignisse, die die Geschichte bildet. Durch die Analyse der Handlung verstehen Leser, wie sich Spannung aufbaut, Konflikte gelöst werden und wie die Themen der Geschichte durch Aktionen und Ergebnisse vermittelt werden.</p>
        
        <h4>Worauf müssen wir achten?</h4>
        <p>Konzentriere dich auf die <strong>Abfolge der Ereignisse</strong>, <strong>Konflikte</strong>, <strong>Höhepunkte</strong>, <strong>Wendepunkte</strong> und <strong>Lösung</strong>. Achte darauf, wie jedes Ereignis mit dem nächsten verbunden ist und wie der Verlauf der Handlung die Themen der Geschichte hervorhebt.</p>
        
        <h4>Wie analysieren wir diese Kategorie?</h4>
        <ol>
          <li><strong>Schlüsselmomente skizzieren:</strong> Identifiziere die Hauptaktionen oder Wendepunkte, die die Geschichte vorantreiben.</li>
          <li><strong>Konflikte erkennen:</strong> Suche nach Herausforderungen, denen der Protagonist gegenübersteht, seien sie intern oder extern.</li>
          <li><strong>Höhepunkt hervorheben:</strong> Finde den Spannungsmoment, in dem alles auf einen Punkt gebracht wird.</li>
          <li><strong>Analyse der Auflösung:</strong> Beachte, wie Konflikte gelöst werden und welche Botschaft dies vermittelt.</li>
          <li><strong>Verbindung zu Themen herstellen:</strong> Überlege, wie die Struktur und der Ablauf der Ereignisse die Botschaft der Geschichte unterstreichen.</li>
        </ol>
        
        <h4>Welche Formulierungen können wir verwenden?</h4>
        <ul>
          <li>"Die Handlung folgt einer Struktur, in der..."</li>
          <li>"Der Höhepunkt tritt auf, wenn..."</li>
          <li>"Der Konflikt zwischen [Charakter] und [Charakter] zeigt..."</li>
          <li>"In der Auflösung sehen wir, dass..."</li>
          <li>"Die Ereignisfolge deutet darauf hin, dass..."</li>
        </ul>
        
        <h4>Beispielabsatz</h4>
        <p>In “Der große Gatsby” dreht sich die Handlung um Jay Gatsbys Versuch, seine verlorene Liebe, Daisy Buchanan, zurückzugewinnen. Die Geschichte entwickelt sich durch Gatsbys aufwendige Partys und seine mysteriöse Vergangenheit, die in einem tragischen Höhepunkt gipfelt, als sein Traum zerplatzt, während Daisy zu ihrem reichen, aber moralisch gleichgültigen Ehemann Tom zurückkehrt. Die Auflösung zeigt die Leere von Gatsbys Streben und unterstreicht Themen wie den amerikanischen Traum und soziale Klassenspaltungen.</p>
      </>
    ]
  }
},
{
  title: {
    en: 'Setting',
    de: 'Schauplatz',
  },
  description: {
    en: 'Backdrop for time, place, and environment of the story.',
    de: 'Hintergrund für Zeit, Ort und Umgebung der Geschichte.',
  },
  details: {
    en: [
      <>
        <h3>Setting</h3>
        <h4>Why is this category important?</h4>
        <p>The setting provides the backdrop for the story, establishing the <strong>time</strong>, <strong>place</strong>, and <strong>environment</strong> in which the characters interact. It shapes the mood, influences the characters, and often acts as a reflection of themes or conflicts.</p>
        
        <h4>What do we need to focus on?</h4>
        <p>Focus on the <strong>time period</strong>, <strong>location</strong>, <strong>atmosphere</strong>, and <strong>cultural or social context</strong>. Consider how the setting influences the characters' behaviors, attitudes, and choices, and how it relates to the story's themes.</p>
        
        <h4>How do we analyze this category?</h4>
        <ol>
          <li><strong>Describe the setting:</strong> Note where and when the story takes place and any sensory details that stand out.</li>
          <li><strong>Consider the impact on characters:</strong> How does the setting affect the characters' lives or decisions?</li>
          <li><strong>Analyze the mood:</strong> What feeling does the setting evoke? Is it dark, lively, oppressive?</li>
          <li><strong>Connect to themes:</strong> Look for ways the setting reinforces the themes or provides insight into the characters.</li>
        </ol>
        
        <h4>Which phrases can we use?</h4>
        <ul>
          <li>"The setting of [place] creates a mood of..."</li>
          <li>"In this environment, [character] feels..."</li>
          <li>"The setting reflects the theme of..."</li>
          <li>"Through the setting, we see the challenges of..."</li>
          <li>"The time period affects the characters by..."</li>
        </ul>
        
        <h4>Model Paragraph</h4>
        <p>The setting of “1984” takes place in a dystopian future where the totalitarian regime of Oceania controls every aspect of life. The bleak, oppressive environment of constant surveillance and propaganda creates a sense of fear and hopelessness. This atmosphere deeply affects Winston Smith, the protagonist, who feels isolated and trapped within the constraints of the society. The setting reflects the novel’s theme of oppressive power and loss of individuality, illustrating the extent of control a government can exert on its citizens.</p>
      </>
    ],
    de: [
      <>
        <h3>Schauplatz</h3>
        <h4>Warum ist diese Kategorie wichtig?</h4>
        <p>Der Schauplatz bietet den Hintergrund der Geschichte und schafft die <strong>Zeit</strong>, den <strong>Ort</strong> und die <strong>Umgebung</strong>, in der die Charaktere interagieren. Er prägt die Stimmung, beeinflusst die Charaktere und dient oft als Spiegel für Themen oder Konflikte.</p>
        
        <h4>Worauf müssen wir achten?</h4>
        <p>Konzentriere dich auf den <strong>Zeitraum</strong>, den <strong>Ort</strong>, die <strong>Atmosphäre</strong> und den <strong>kulturellen oder sozialen Kontext</strong>. Überlege, wie der Schauplatz das Verhalten, die Einstellungen und die Entscheidungen der Charaktere beeinflusst und wie er sich auf die Themen der Geschichte bezieht.</p>
        
        <h4>Wie analysieren wir diese Kategorie?</h4>
        <ol>
          <li><strong>Beschreibe den Schauplatz:</strong> Notiere, wo und wann die Geschichte spielt und alle hervorstechenden sinnlichen Details.</li>
          <li><strong>Einfluss auf die Charaktere berücksichtigen:</strong> Wie beeinflusst der Schauplatz das Leben oder die Entscheidungen der Charaktere?</li>
          <li><strong>Stimmung analysieren:</strong> Welche Stimmung vermittelt der Schauplatz? Ist sie düster, lebendig, bedrückend?</li>
          <li><strong>Verbindung zu Themen herstellen:</strong> Überlege, wie der Schauplatz die Themen verstärkt oder Einblicke in die Charaktere bietet.</li>
        </ol>
        
        <h4>Welche Formulierungen können wir verwenden?</h4>
        <ul>
          <li>"Der Schauplatz in [Ort] schafft eine Stimmung von..."</li>
          <li>"In dieser Umgebung fühlt sich [Charakter]..."</li>
          <li>"Der Schauplatz spiegelt das Thema von..."</li>
          <li>"Durch den Schauplatz sehen wir die Herausforderungen von..."</li>
          <li>"Der Zeitraum beeinflusst die Charaktere durch..."</li>
        </ul>
        
        <h4>Beispielabsatz</h4>
        <p>Der Schauplatz in „1984“ befindet sich in einer dystopischen Zukunft, in der das totalitäre Regime Ozeaniens jeden Aspekt des Lebens kontrolliert. Die düstere, bedrückende Umgebung der ständigen Überwachung und Propaganda erzeugt ein Gefühl von Angst und Hoffnungslosigkeit. Diese Atmosphäre wirkt sich tief auf den Protagonisten Winston Smith aus, der sich isoliert und gefangen in den Zwängen der Gesellschaft fühlt. Der Schauplatz spiegelt das Thema des Romans von unterdrückender Macht und Verlust der Individualität wider und veranschaulicht, wie weitreichend die Kontrolle einer Regierung über ihre Bürger sein kann.</p>
      </>
    ]
  }
},
{
  title: {
    en: 'Themes and Motifs',
    de: 'Themen und Motive',
  },
  description: {
    en: 'Underlying messages and repeated symbols.',
    de: 'Grundlegende Botschaften und wiederkehrende Symbole.',
  },
  details: {
    en: [
      <>
        <h4>Why is this category important?</h4>
        <p>Themes and motifs are the underlying messages and repeated symbols that give depth to the story. They often reflect universal ideas and values, making the narrative relevant to broader human experiences.</p>
        
        <h4>What do we need to focus on?</h4>
        <p>Identify recurring <strong>symbols</strong>, <strong>ideas</strong>, or <strong>messages</strong> and consider how they are reinforced by characters, settings, and plot events. Look for connections between the theme and the character’s journey or how the story’s outcome highlights these ideas.</p>
        
        <h4>How do we analyze this category?</h4>
        <ol>
          <li><strong>Identify the theme:</strong> Look for the central message or insight about life that the story communicates.</li>
          <li><strong>Note recurring motifs:</strong> Find symbols, objects, or events that keep appearing and support the theme.</li>
          <li><strong>Analyze character actions:</strong> Observe how characters embody or challenge the theme.</li>
          <li><strong>Connect to plot and setting:</strong> See how the events and locations reinforce the theme.</li>
          <li><strong>Interpret the meaning:</strong> Reflect on how the theme relates to human nature, society, or other philosophical ideas.</li>
        </ol>
        
        <h4>Which phrases can we use?</h4>
        <ul>
          <li>"The central theme of the story is..."</li>
          <li>"This motif of [object/symbol] represents..."</li>
          <li>"Through [character's] actions, we understand that..."</li>
          <li>"The author uses [symbol] to highlight..."</li>
          <li>"This theme suggests that..."</li>
        </ul>
        
        <h4>Model Paragraph</h4>
        <p>In “The Catcher in the Rye,” the recurring theme of innocence is central to Holden Caulfield’s character. Throughout the novel, Holden is deeply affected by his desire to protect the innocence of children, particularly his sister Phoebe. This theme is embodied in his fantasy of being the “catcher in the rye,” where he imagines saving children from falling into the harshness of adulthood. Through this motif, the novel explores the difficulties of growing up and the loss of innocence.</p>
      </>
    ],
    de: [
      <>
        <h4>Warum ist diese Kategorie wichtig?</h4>
        <p>Themen und Motive sind die grundlegenden Botschaften und wiederkehrenden Symbole, die der Geschichte Tiefe verleihen. Sie spiegeln oft universelle Ideen und Werte wider, wodurch die Erzählung für breitere menschliche Erfahrungen relevant wird.</p>
        
        <h4>Worauf müssen wir achten?</h4>
        <p>Identifiziere wiederkehrende <strong>Symbole</strong>, <strong>Ideen</strong> oder <strong>Botschaften</strong> und überlege, wie sie durch Charaktere, Schauplätze und Ereignisse der Handlung verstärkt werden. Suche nach Verbindungen zwischen dem Thema und der Reise des Charakters oder wie das Ergebnis der Geschichte diese Ideen hervorhebt.</p>
        
        <h4>Wie analysieren wir diese Kategorie?</h4>
        <ol>
          <li><strong>Identifiziere das Thema:</strong> Suche nach der zentralen Botschaft oder Einsicht über das Leben, die die Geschichte vermittelt.</li>
          <li><strong>Wiederkehrende Motive beachten:</strong> Finde Symbole, Objekte oder Ereignisse, die immer wieder auftauchen und das Thema unterstützen.</li>
          <li><strong>Handlungen der Charaktere analysieren:</strong> Beachte, wie Charaktere das Thema verkörpern oder herausfordern.</li>
          <li><strong>Verbindung zu Handlung und Schauplatz herstellen:</strong> Schau, wie die Ereignisse und Orte das Thema verstärken.</li>
          <li><strong>Bedeutung interpretieren:</strong> Reflektiere, wie das Thema sich auf die menschliche Natur, die Gesellschaft oder andere philosophische Ideen bezieht.</li>
        </ol>
        
        <h4>Welche Formulierungen können wir verwenden?</h4>
        <ul>
          <li>"Das zentrale Thema der Geschichte ist..."</li>
          <li>"Dieses Motiv von [Objekt/Symbol] repräsentiert..."</li>
          <li>"Durch die Handlungen von [Charakter] verstehen wir, dass..."</li>
          <li>"Der Autor nutzt [Symbol], um hervorzuheben..."</li>
          <li>"Dieses Thema deutet darauf hin, dass..."</li>
        </ul>
        
        <h4>Beispielabsatz</h4>
        <p>In „Der Fänger im Roggen“ ist das wiederkehrende Thema der Unschuld zentral für Holden Caulfields Charakter. Im Verlauf des Romans wird Holden tief von seinem Wunsch beeinflusst, die Unschuld der Kinder, insbesondere seiner Schwester Phoebe, zu bewahren. Dieses Thema wird in seiner Fantasie verkörpert, der „Fänger im Roggen“ zu sein, in der er sich vorstellt, Kinder davor zu bewahren, in die Härte des Erwachsenseins zu fallen. Durch dieses Motiv untersucht der Roman die Schwierigkeiten des Erwachsenwerdens und den Verlust der Unschuld.</p>
      </>
    ]
  }
}
    ],
checklist: {
  en: [
    {
      question: 'Did I understand the main theme of the story?',
      explanation: 'Identify the central idea or message the story conveys, such as a moral, social commentary, or insight into human nature.',
      messages: [
        "Needs significant improvement in understanding the theme.", // 😢
        "Theme understanding is below expectations.",               // 🙁
        "Theme identified satisfactorily.",                         // 😐
        "Good job identifying the theme!",                          // 🙂
        "Excellent understanding of the theme!"                     // 😃
      ]
    },
    {
      question: 'Did I describe the main setting and its influence?',
      explanation: 'Analyze the time, place, and atmosphere of the story. Explain how the setting shapes the characters and the mood.',
      messages: [
        "Needs significant improvement in describing the setting.", // 😢
        "Setting description is below expectations.",               // 🙁
        "Setting described satisfactorily.",                        // 😐
        "Good job describing the setting and its influence!",       // 🙂
        "Excellent description of setting and its impact!"          // 😃
      ]
    },
    {
      question: 'Did I analyze the main character’s motivations and development?',
      explanation: 'Consider the protagonist’s goals, emotions, and challenges, and explain how they evolve throughout the story.',
      messages: [
        "Needs significant improvement in analyzing character development.", // 😢
        "Character analysis is below expectations.",                        // 🙁
        "Character motivations analyzed satisfactorily.",                   // 😐
        "Good job analyzing character development!",                        // 🙂
        "Excellent analysis of character growth and motivations!"           // 😃
      ]
    },
    {
      question: 'Did I explore the plot structure and key events?',
      explanation: 'Identify the major events, including the climax and resolution, and explain how they contribute to the overall message.',
      messages: [
        "Needs significant improvement in explaining the plot structure.", // 😢
        "Plot analysis is below expectations.",                           // 🙁
        "Plot structure explained satisfactorily.",                       // 😐
        "Good job exploring key plot events!",                            // 🙂
        "Excellent analysis of plot structure and events!"                // 😃
      ]
    },
    {
      question: 'Did I discuss the conflicts and how they drive the story?',
      explanation: 'Identify internal and external conflicts and analyze how they impact the characters and contribute to the story’s themes.',
      messages: [
        "Needs significant improvement in identifying conflicts.", // 😢
        "Conflict analysis is below expectations.",                // 🙁
        "Conflicts discussed satisfactorily.",                     // 😐
        "Good job analyzing conflicts and their impact!",          // 🙂
        "Excellent discussion of conflicts and their role!"        // 😃
      ]
    },
    {
      question: 'Did I consider any recurring symbols or motifs?',
      explanation: 'Identify symbols or motifs that appear throughout the story and explain their significance and how they reinforce themes.',
      messages: [
        "Needs significant improvement in identifying symbols.", // 😢
        "Symbol identification is below expectations.",          // 🙁
        "Symbols identified satisfactorily.",                    // 😐
        "Good job analyzing symbols and motifs!",                // 🙂
        "Excellent interpretation of symbols and motifs!"        // 😃
      ]
    },
    {
      question: 'Did I analyze how the story’s tone contributes to its meaning?',
      explanation: 'Examine the author’s tone—such as humorous, serious, or ironic—and discuss how it affects the reader’s interpretation of the themes.',
      messages: [
        "Needs significant improvement in analyzing tone.", // 😢
        "Tone analysis is below expectations.",             // 🙁
        "Tone analyzed satisfactorily.",                    // 😐
        "Good job analyzing the story's tone!",             // 🙂
        "Excellent interpretation of tone and its effect!"  // 😃
      ]
    },
    {
      question: 'Did I explain the resolution and its connection to the theme?',
      explanation: 'Summarize how the story concludes and discuss how the ending reinforces the story’s theme or leaves an impact on the reader.',
      messages: [
        "Needs significant improvement in explaining the resolution.", // 😢
        "Resolution analysis is below expectations.",                  // 🙁
        "Resolution explained satisfactorily.",                        // 😐
        "Good job connecting the resolution to the theme!",            // 🙂
        "Excellent interpretation of the resolution and its meaning!"  // 😃
      ]
    },
    {
      question: 'Did I consider possible interpretations or messages the author intended?',
      explanation: 'Reflect on any deeper meanings or messages the author may want the reader to consider, such as social or moral insights.',
      messages: [
        "Needs significant improvement in interpreting the author's message.", // 😢
        "Author's message interpretation is below expectations.",             // 🙁
        "Author's intentions considered satisfactorily.",                     // 😐
        "Good job interpreting the author's message!",                        // 🙂
        "Excellent reflection on the author's message!"                       // 😃
      ]
    },
    {
      question: 'Did I draw meaningful conclusions from the analysis?',
      explanation: 'Summarize your findings and explain what the story ultimately reveals about the theme, characters, or broader human experience.',
      messages: [
        "Needs significant improvement in drawing conclusions.", // 😢
        "Conclusions drawn are below expectations.",             // 🙁
        "Conclusions are satisfactory.",                         // 😐
        "Good job concluding your analysis!",                    // 🙂
        "Excellent and meaningful conclusions drawn!"            // 😃
      ]
    }
  ],
  de: [
    {
      question: 'Habe ich das Hauptthema der Geschichte verstanden?',
      explanation: 'Identifiziere die zentrale Idee oder Botschaft der Geschichte, wie eine Moral, gesellschaftliche Aussage oder Einsicht in die menschliche Natur.',
      messages: [
        "Thema kaum erkannt, Verbesserung nötig.", // 😢
        "Themenverständnis unter den Erwartungen.", // 🙁
        "Thema zufriedenstellend identifiziert.", // 😐
        "Gute Arbeit beim Erkennen des Themas!", // 🙂
        "Ausgezeichnetes Verständnis des Themas!" // 😃
      ]
    },
    {
      question: 'Habe ich den Hauptschauplatz und seinen Einfluss beschrieben?',
      explanation: 'Analysiere die Zeit, den Ort und die Atmosphäre der Geschichte. Erkläre, wie der Schauplatz die Charaktere und die Stimmung prägt.',
      messages: [
        "Schauplatz kaum beschrieben, Verbesserung nötig.", // 😢
        "Schauplatzbeschreibung unter den Erwartungen.", // 🙁
        "Schauplatz zufriedenstellend beschrieben.", // 😐
        "Gute Arbeit bei der Beschreibung des Schauplatzes!", // 🙂
        "Ausgezeichnete Beschreibung des Schauplatzes und seines Einflusses!" // 😃
      ]
    },
    {
      question: 'Habe ich die Motivation und Entwicklung des Hauptcharakters analysiert?',
      explanation: 'Betrachte die Ziele, Emotionen und Herausforderungen des Protagonisten und erkläre, wie er sich im Laufe der Geschichte entwickelt.',
      messages: [
        "Verbesserung bei der Charakterentwicklung nötig.", // 😢
        "Charakteranalyse unter den Erwartungen.", // 🙁
        "Charakter zufriedenstellend analysiert.", // 😐
        "Gute Arbeit bei der Analyse der Charakterentwicklung!", // 🙂
        "Ausgezeichnete Analyse der Charakterentwicklung!" // 😃
      ]
    },
    {
      question: 'Habe ich die Handlung und wichtige Ereignisse analysiert?',
      explanation: 'Identifiziere die Hauptereignisse, einschließlich Höhepunkt und Auflösung, und erkläre, wie sie zur Gesamtbotschaft beitragen.',
      messages: [
        "Handlung kaum erklärt, Verbesserung nötig.", // 😢
        "Handlungsanalyse unter den Erwartungen.", // 🙁
        "Handlung zufriedenstellend erklärt.", // 😐
        "Gute Arbeit bei der Analyse wichtiger Handlungselemente!", // 🙂
        "Ausgezeichnete Analyse der Handlung!" // 😃
      ]
    },
    {
      question: 'Habe ich die Konflikte identifiziert und deren Einfluss beschrieben?',
      explanation: 'Identifiziere interne und externe Konflikte und analysiere, wie sie die Charaktere beeinflussen und zur Thematik beitragen.',
      messages: [
        "Konflikte kaum erkannt, Verbesserung nötig.", // 😢
        "Konfliktanalyse unter den Erwartungen.", // 🙁
        "Konflikte zufriedenstellend analysiert.", // 😐
        "Gute Arbeit bei der Analyse der Konflikte!", // 🙂
        "Ausgezeichnete Analyse der Konflikte und ihres Einflusses!" // 😃
      ]
    },
    {
      question: 'Habe ich wiederkehrende Symbole oder Motive berücksichtigt?',
      explanation: 'Identifiziere Symbole oder Motive, die in der Geschichte wiederholt auftreten und erkläre deren Bedeutung und wie sie die Themen verstärken.',
      messages: [
        "Symbole kaum erkannt, Verbesserung nötig.", // 😢
        "Symbolanalyse unter den Erwartungen.", // 🙁
        "Symbole zufriedenstellend analysiert.", // 😐
        "Gute Arbeit bei der Analyse von Symbolen!", // 🙂
        "Ausgezeichnete Interpretation von Symbolen und Motiven!" // 😃
      ]
    },
    {
      question: 'Habe ich die Bedeutung des Tons der Geschichte analysiert?',
      explanation: 'Betrachte den Ton des Autors – ob humorvoll, ernst oder ironisch – und wie er die Interpretation der Themen beeinflusst.',
      messages: [
        "Ton kaum erkannt, Verbesserung nötig.", // 😢
        "Tonanalyse unter den Erwartungen.", // 🙁
        "Ton zufriedenstellend analysiert.", // 😐
        "Gute Arbeit bei der Analyse des Tons!", // 🙂
        "Ausgezeichnete Interpretation des Tons und seiner Wirkung!" // 😃
      ]
    },
    {
      question: 'Habe ich die Auflösung und ihre Verbindung zum Thema erklärt?',
      explanation: 'Fasse zusammen, wie die Geschichte endet und diskutiere, wie das Ende das Thema verstärkt oder beim Leser einen Eindruck hinterlässt.',
      messages: [
        "Auflösung kaum erklärt, Verbesserung nötig.", // 😢
        "Analyse der Auflösung unter den Erwartungen.", // 🙁
        "Auflösung zufriedenstellend erklärt.", // 😐
        "Gute Arbeit bei der Analyse der Auflösung!", // 🙂
        "Ausgezeichnete Interpretation der Auflösung und ihrer Bedeutung!" // 😃
      ]
    },
    {
      question: 'Habe ich mögliche Interpretationen oder Botschaften des Autors berücksichtigt?',
      explanation: 'Reflektiere tiefere Bedeutungen oder Botschaften, die der Autor den Lesern möglicherweise vermitteln möchte, z. B. gesellschaftliche oder moralische Erkenntnisse.',
      messages: [
        "Verbesserung bei der Interpretation der Botschaft nötig.", // 😢
        "Botschaftsinterpretation unter den Erwartungen.", // 🙁
        "Botschaft zufriedenstellend interpretiert.", // 😐
        "Gute Arbeit bei der Interpretation der Botschaft!", // 🙂
        "Ausgezeichnete Reflexion über die Botschaft des Autors!" // 😃
      ]
    },
    {
      question: 'Habe ich sinnvolle Schlussfolgerungen aus der Analyse gezogen?',
      explanation: 'Fasse Deine Erkenntnisse zusammen und erkläre, was die Geschichte letztlich über das Thema, die Charaktere oder die menschliche Erfahrung aussagt.',
      messages: [
        "Schlussfolgerungen kaum gezogen, Verbesserung nötig.", // 😢
        "Gezogene Schlussfolgerungen unter den Erwartungen.", // 🙁
        "Schlussfolgerungen zufriedenstellend gezogen.", // 😐
        "Gute Arbeit bei der Schlussfolgerung Deiner Analyse!", // 🙂
        "Ausgezeichnete und bedeutungsvolle Schlussfolgerungen gezogen!" // 😃
      ]
    }
  ]
},
    sliderCategories: [
      {
        id: 'content',
        categoryTitle: {
          en: 'Content',
          de: 'Inhalt',
        },
        sliders: [
          {
            id: 'content-task-fulfillment',
            label: {
              en: 'Task Fulfillment',
              de: 'Aufgabenerfüllung',
            },
            messages: {
              en: (value) => {
                if (value >= 0 && value < 20) return "Insufficient selection of aspects, lack of task relevance.";
                if (value >= 20 && value < 40) return "Hardly considering relevant aspects, hardly appropriate.";
                if (value >= 40 && value < 55) return "Considering less relevant aspects, however, correct to some extent.";
                if (value >= 55 && value < 70) return "Taking into account several relevant aspects, essentially correct.";
                if (value >= 70 && value < 85) return "Considering relevant aspects to a large extent, largely correct.";
                if (value >= 85 && value <= 100) return "Comprehensive and correct.";
              },
              de: (value) => {
                if (value >= 0 && value < 20) return "Unzureichende Auswahl von Aspekten, Aufgabenbezug fehlend.";
                if (value >= 20 && value < 40) return "Kaum relevante Aspekte berücksichtigend, kaum angemessen.";
                if (value >= 40 && value < 55) return "Weniger relevante Aspekte berücksichtigend, ansatzweise korrekt.";
                if (value >= 55 && value < 70) return "Mehrere relevante Aspekte berücksichtigend, im Wesentlichen korrekt.";
                if (value >= 70 && value < 85) return "Relevante Aspekte weitgehend berücksichtigend, weitgehend korrekt.";
                if (value >= 85 && value <= 100) return "Umfassend und korrekt.";
              },
            },
          },
          {
            id: 'content-text-interpretation',
            label: {
              en: 'Interpretation of the text, taking into account its stylistic devices',
              de: 'Deutung des Textes unter Berücksichtigung seiner Gestaltungsmittel',
            },
            messages: {
            en: (value) => {
                if (value >= 0 && value < 20) return "Missing or no longer comprehensible.";
                if (value >= 20 && value < 40) return "Barely comprehensible or no longer logical.";
                if (value >= 40 && value < 55) return "Still comprehensible and logical.";
                if (value >= 55 && value < 70) return "Comprehensible and essentially logical.";
                if (value >= 70 && value < 85) return "Largely logical.";
                if (value >= 85 && value <= 100) return "Consistently logical.";
              },
              de: (value) => {
                if (value >= 0 && value < 20) return "Fehlend oder nicht mehr nachvollziehbar.";
                if (value >= 20 && value < 40) return "Kaum nachvollziehbar bzw. nicht mehr folgerichtig.";
                if (value >= 40 && value < 55) return "Noch nachvollziehbar und folgerichtig.";
                if (value >= 55 && value < 70) return "Nachvollziehbar und im Wesentlichen folgerichtig.";
                if (value >= 70 && value < 85) return "Weitgehend folgerichtig.";
                if (value >= 85 && value <= 100) return "Durchgängig folgerichtig.";
              },
            },
          },
          {
            id: 'content-text-reference-precision-differentiation',
            label: {
              en: 'Textual reference (Precision and differentiation)',
              de: 'Bezugnahme auf den Text (Präzision und Differenziertheit)',
            },
            messages: {
              en: (value) => {
if (value >= 0 && value < 20) return "Incorrect or missing.";
if (value >= 20 && value < 40) return "Barely present or mostly inaccurate.";
if (value >= 40 && value < 55) return "Somewhat accurate.";
if (value >= 55 && value < 70) return "Largely accurate and nuanced.";
if (value >= 70 && value < 85) return "Mostly precise and nuanced.";
if (value >= 85 && value <= 100) return "Consistently precise and nuanced.";
              },
              de: (value) => {
                if (value >= 0 && value < 20) return "Falsch bzw. fehlend.";
                if (value >= 20 && value < 40) return "Kaum vorhanden bzw. wenig treffend.";
                if (value >= 40 && value < 55) return "Ansatzweise treffend.";
                if (value >= 55 && value < 70) return "Im Wesentlichen treffend und differenziert.";
                if (value >= 70 && value < 85) return "Weitgehend präzise und differenziert.";
                if (value >= 85 && value <= 100) return "durchgängig präzise und differenziert.";
              },
            },
          },
          {
            id: 'content-text-reference-textual-evidence-quotations',
            label: {
              en: 'Textual reference (Textual evidence and quotations)',
              de: 'Bezugnahme auf den Text (Textbelege und Zitate)',
            },
            messages: {
              en: (value) => {
                if (value >= 0 && value < 20) return "Inappropriate textual evidence and quotations.";
                if (value >= 20 && value < 40) return "Mostly inappropriate textual evidence and quotations.";
                if (value >= 40 && value < 55) return "Still appropriate and functional textual evidence and quotations.";
                if (value >= 55 && value < 70) return "Largely appropriate and functional textual evidence and quotations.";
                if (value >= 70 && value < 85) return "Mostly appropriate and functional textual evidence and quotations.";
                if (value >= 85 && value <= 100) return "Consistently appropriate and functional textual evidence and quotations.";
              },

              de: (value) => {
                if (value >= 0 && value < 20) return "Nicht sachgemäße Textbelege und Zitate.";
                if (value >= 20 && value < 40) return "Wenig sachgemäße Textbelege und Zitate.";
                if (value >= 40 && value < 55) return "Noch sachgemäße und funktionale Textbelege und Zitate.";
                if (value >= 55 && value < 70) return "Im Wesentlichen sachgemäße und funktionale Textbelege und Zitate.";
                if (value >= 70 && value < 85) return "Weitgehend sachgemäße und funktionale Textbelege und Zitate.";
                if (value >= 85 && value <= 100) return "Durchgängig sachgemäße und funktionale Textbelege und Zitate.";
              },
            },
          },
          {
            id: 'content-content-organisation-consistency-coherence',
            label: {
              en: 'Content Structure: Organization, Consistency, Coherence',
              de: 'Inhaltlicher Aufbau: Struktur, Stringenz, Kohärenz',
            },
            messages: {
              en: (value) => {
                if (value >= 0 && value < 20) return "Unstructured, redundant, incomprehensible, incoherent.";
                if (value >= 20 && value < 40) return "Barely structured, often redundant, hardly comprehensible or coherent.";
                if (value >= 40 && value < 55) return "Somewhat structured, repeatedly redundant, somewhat logical, still coherent.";
                if (value >= 55 && value < 70) return "Largely structured and free of redundancy, largely logical and coherent.";
                if (value >= 70 && value < 85) return "Structured, mostly free of redundancy, mostly logical and coherent.";
                if (value >= 85 && value <= 100) return "Clearly structured, free of redundancy, highly logical, consistently coherent.";
              },

              de: (value) => {
                if (value >= 0 && value < 20) return "Unstrukturiert, redundant, nicht nachvollziehbar, inkohärent.";
                if (value >= 20 && value < 40) return "Kaum strukturiert, oft redundant, kaum nachvollziehbar bzw. kaum noch kohärent.";
                if (value >= 40 && value < 55) return "Ansatzweise strukturiert, wiederholt redundant, ansatzweise schlüssig, noch kohärent.";
                if (value >= 55 && value < 70) return "Im Wesentlichen strukturiert und redundanzfrei, im Wesentlichen schlüssig und kohärent.";
                if (value >= 70 && value < 85) return "Strukturiert, weitgehend redundanzfrei, weitgehend schlüssig und kohärent.";
                if (value >= 85 && value <= 100) return "Klar strukturiert, redundanzfrei, in hohem Maße schlüssig, durchgängig kohärent.";
              },
            },
          },
        ],
      },
      {
        id: 'language',
        categoryTitle: {
          en: 'Language',
          de: 'Sprache',
        },
        sliders: [
          {
            id: 'language-lexical-range',
            label: {
              en: 'Lexical Range',
              de: 'Lexikalische Bandbreite',
            },
            messages: {
              en: (value) => {
                if (value >= 0 && value < 20) return "Not an appropriate choice of words.";
                if (value >= 20 && value < 40) return "Clearly limited choice of words.";
                if (value >= 40 && value < 55) return "Restricted, still appropriate choice of words.";
                if (value >= 55 && value < 70) return "Essentially precise and variable choice of words.";
                if (value >= 70 && value < 85) return "Largely precise, variable, with some target-language expressions.";
                if (value >= 85 && value <= 100) return "Highly precise and variable choice of words.";
              },
              de: (value) => {
                if (value >= 0 && value < 20) return "Keine angemessene Wortwahl.";
                if (value >= 20 && value < 40) return "Deutlich eingeschränkte Wortwahl.";
                if (value >= 40 && value < 55) return "Eingeschränkte, noch angemessene Wortwahl.";
                if (value >= 55 && value < 70) return "Im Wesentlichen präzise und variable Wortwahl.";
                if (value >= 70 && value < 85) return "Weitgehend präzise und variable Wortwahl.";
                if (value >= 85 && value <= 100) return "In hohem Maße präzise und variable Wortwahl.";
              },
            },
          },
          {
            id: 'language-lexical-accuracy',
            label: {
              en: 'Lexical Accuracy',
              de: 'Lexikalische Korrektheit',
            },
            messages: {
              en: (value) => {
                if (value >= 0 && value < 20) return "Lexically incorrect; no longer comprehensible.";
                if (value >= 20 && value < 40) return "Often lexically incorrect; comprehensibility severely impaired.";
                if (value >= 40 && value < 55) return "Partially lexically incorrect; comprehensibility impaired.";
                if (value >= 55 && value < 70) return "Essentially lexically correct; slightly limited comprehensibility.";
                if (value >= 70 && value < 85) return "Largely lexically correct; comprehensibility not impaired by individual inaccuracies.";
                if (value >= 85 && value <= 100) return "Highly lexically correct; comprehensible throughout.";
              },
              de: (value) => {
                if (value >= 0 && value < 20) return "Lexikalisch unkorrekt; Verständlichkeit nicht gegeben.";
                if (value >= 20 && value < 40) return "Oft lexikalisch unkorrekt; Verständlichkeit stark beeinträchtigt.";
                if (value >= 40 && value < 55) return "Teilweise lexikalisch unkorrekt; Verständlichkeit eingeschränkt.";
                if (value >= 55 && value < 70) return "Im Wesentlichen lexikalisch korrekt; Verständlichkeit geringfügig beeinträchtigt.";
                if (value >= 70 && value < 85) return "Weitgehend lexikalisch korrekt; Verständlichkeit nicht beeinträchtigt.";
                if (value >= 85 && value <= 100) return "In hohem Maße lexikalisch korrekt; durchgängig verständlich.";
              },
            },
          },
          {
            id: 'language-grammatical-range',
            label: {
              en: 'Grammatical Range',
              de: 'Grammatische Bandbreite',
            },
            messages: {
              en: (value) => {
                if (value >= 0 && value < 20) return "Sentence structure and forms inadequate.";
                if (value >= 20 && value < 40) return "Sentence structure and forms barely appropriate.";
                if (value >= 40 && value < 55) return "Limited variety in sentence structure; still appropriate forms.";
                if (value >= 55 && value < 70) return "Largely varied sentence structure; largely functional use of chosen forms.";
                if (value >= 70 && value < 85) return "Mostly varied sentence structure; mostly functional use of chosen forms.";
                if (value >= 85 && value <= 100) return "Highly varied sentence structure; consistently functional use of chosen forms.";
              },
              de: (value) => {
                if (value >= 0 && value < 20) return "Satzbau und Strukturen unzulänglich.";
                if (value >= 20 && value < 40) return "Satzbau und Strukturen kaum angemessen.";
                if (value >= 40 && value < 55) return "Wenig variabler Satzbau; noch angemessene Strukturen.";
                if (value >= 55 && value < 70) return "Im Wesentlichen variabler Satzbau; im Wesentlichen funktionaler Einsatz der gewählten Strukturen.";
                if (value >= 70 && value < 85) return "Weitgehend variabler Satzbau; weitgehend funktionaler Einsatz der gewählten Strukturen.";
                if (value >= 85 && value <= 100) return "In hohem Maße variabler Satzbau; durchgängig funktionaler Einsatz der gewählten Strukturen.";
              },
            },
          },
                    {
            id: 'language-grammatical-accuracy',
            label: {
              en: 'Grammatical Accuracy',
              de: 'Grammatische Korrektheit',
            },
            messages: {
              en: (value) => {
                if (value >= 0 && value < 20) return "Sentence structure and structures inadequate.";
                if (value >= 20 && value < 40) return "Sentence structure and structures barely adequate.";
                if (value >= 40 && value < 55) return "Less variable sentence structure; still appropriate structures.";
                if (value >= 55 && value < 70) return "Essentially variable sentence structure.";
                if (value >= 70 && value < 85) return "Largely variable sentence structure.";
                if (value >= 85 && value <= 100) return "Highly variable sentence structure.";
              },
              de: (value) => {
                if (value >= 0 && value < 20) return "Grammatikalisch unkorrekt; Verständlichkeit nicht mehr gegeben.";
                if (value >= 20 && value < 40) return "Häufig grammatikalisch unkorrekt; Verständlichkeit stark beeinträchtigt.";
                if (value >= 40 && value < 55) return "Teilweise grammatikalisch unkorrekt; Verständlichkeit stellenweise beeinträchtigt.";
                if (value >= 55 && value < 70) return "Im Wesentlichen grammatikalisch korrekt; Verständlichkeit geringfügig eingeschränkt.";
                if (value >= 70 && value < 85) return "Weitgehend grammatikalisch korrekt; einzelne Ungenauigkeiten beeinträchtigen die Verständlichkeit nicht.";
                if (value >= 85 && value <= 100) return "In hohem Maße grammatikalisch korrekt; durchgängig verständlich.";
              },
            },
          },
                    {
            id: 'language-text-composition-text-structure',
            label: {
              en: 'Text composition: Text structure',
              de: 'Textgestaltung: Textaufbau',
            },
            messages: {
          en: (value) => {
                if (value >= 0 && value < 20) return "No purposeful text, no use of text-structuring devices.";
                if (value >= 20 && value < 40) return "Barely purposeful text, minimal use of text-structuring devices (cohesion).";
                if (value >= 40 && value < 55) return "Somewhat purposeful text with limited functional use of text-structuring devices (cohesion).";
                if (value >= 55 && value < 70) return "Largely purposeful text with mostly functional use of text-structuring devices (cohesion).";
                if (value >= 70 && value < 85) return "Mostly purposeful text with functional use of text-structuring devices (cohesion).";
                if (value >= 85 && value <= 100) return "Highly purposeful text with consistently functional use of text-structuring devices (cohesion).";
              },
              de: (value) => {
                if (value >= 0 && value < 20) return "Kein zielgerichteter Text, keine textstrukturierenden Mittel.";
                if (value >= 20 && value < 40) return "Kaum zielgerichteter Text, kaum textstrukturierende Mittel (Kohäsion).";
                if (value >= 40 && value < 55) return "Ansatzweise zielgerichteter Text mit eingeschränkt funktionalem Einsatz textstrukturierender Mittel (Kohäsion).";
                if (value >= 55 && value < 70) return "Im Wesentlichen zielgerichteter Text mit überwiegend funktionalem Einsatz textstrukturierender Mittel (Kohäsion).";
                if (value >= 70 && value < 85) return "weitgehend zielgerichteter Text mit funktionalem Einsatz textstrukturierender Mittel (Kohäsion).";
                if (value >= 85 && value <= 100) return "In hohem Maße zielgerichteter Text mit durchgängig funktionalem Einsatz textstrukturierender Mittel (Kohäsion).";
              },
            },
          },
                    {
            id: 'language-text-composition-appropriateness',
            label: {
              en: 'Text composition: Appropriateness',
              de: 'Textgestaltung: Angemessenheit',
            },
            messages: {
              en: (value) => {
                if (value >= 0 && value < 20) return "No implementation of the required linguistic genre characteristics; no appropriate language use for the context and audience; no independent language style.";
                if (value >= 20 && value < 40) return "Minimal implementation of the required linguistic genre characteristics; minimal appropriate language use for the context and audience; limited independent language style.";
                if (value >= 40 && value < 55) return "Partial implementation of the required linguistic genre characteristics; somewhat appropriate language use for the context and audience; somewhat independent language style.";
                if (value >= 55 && value < 70) return "Largely implements the required linguistic genre characteristics; largely appropriate language use for the context and audience; largely independent language style.";
                if (value >= 70 && value < 85) return "Broad implementation of the required linguistic genre characteristics; largely appropriate language use for the context and audience; largely independent language style.";
                if (value >= 85 && value <= 100) return "Extensive implementation of the required linguistic genre characteristics; highly appropriate language use for the context and audience; highly independent language style.";
              },
              de: (value) => {
                if (value >= 0 && value < 20) return "Fehlende Umsetzung der geforderten sprachlichen Textsortenmerkmale; keine situations- und adressatengerechte Sprachverwendung; keine eigenständige sprachliche Gestaltung.";
                if (value >= 20 && value < 40) return "Kaum Umsetzung der geforderten sprachlichen Textsortenmerkmale; kaum situations- und adressatengerechte Sprachverwendung; wenig eigenständige sprachliche Gestaltung.";
                if (value >= 40 && value < 55) return "Ansatzweise Umsetzung der geforderten sprachlichen Textsortenmerkmale; ansatzweise situations- und adressatengerechte Sprachverwendung; ansatzweise eigenständige sprachliche Gestaltung.";
                if (value >= 55 && value < 70) return "Im Wesentlichen Umsetzung der geforderten sprachlichen Textsortenmerkmale; im Wesentlichen situations- und adressatengerechte Sprachverwendung; im Wesentlichen eigenständige sprachliche Gestaltung.";
                if (value >= 70 && value < 85) return "Weitgehende Umsetzung der geforderten sprachlichen Textsortenmerkmale; weitgehend situations- und adressatengerechte Sprachverwendung; weitgehend eigenständige sprachliche Gestaltung.";
                if (value >= 85 && value <= 100) return "In hohem Maße Umsetzung der geforderten sprachlichen Textsortenmerkmale; in hohem Maße situations- und adressatengerechte Sprachverwendung; in hohem Maße eigenständige sprachliche Gestaltung.";
              },
            },
          },
        ],
      },
    ],
  },
];

export default cardsData;
